<template>
  <div
    class="modal fade createNewModal"
    id="AddCategorieFaqModal"
    tabindex="-1"
    ref="addCategorieFaqModalRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="categorieFaqForm" @submit="addCategorieFaq" :validation-schema="categorieFaqSchema">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Code <span class="text-danger">*</span>
                  </label>
                  <Field name="code" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le code"/>
                  <ErrorMessage name="code" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Nom <span class="text-danger">*</span>
                  </label>
                  <Field name="nom" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nom"/>
                  <ErrorMessage name="nom" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Description <span class="text-danger">*</span>
                  </label>
                  <Field name="description" as="textarea"  cols="30"
                    rows="12" placeholder="Entrer la description" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                  <textarea
                  class="form-control shadow-none rounded-0 text-black"
                    v-model="field.value"
                  ></textarea>
                </Field>
                  <ErrorMessage name="description" class="text-danger"/>
                </div>
              </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
            {{ btntext }}
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import ApiService from '../../services/ApiService';
import * as Yup from 'yup';
import { hideModal } from '../../utils/utils';
import { CategorieFaq } from '../../models/CategorieFaq';
import { error , success } from '../../utils/utils';

export default defineComponent({
    name: "AddCategorieFaqModal",
    components: {
    Form,
    Field,
    ErrorMessage
  },
  props: {
    item: {
      type: Number,
      default: 0,
    }
  },
  setup(props, { emit }){
    const categorieFaqSchema = Yup.object().shape({
      description: Yup.string().required("La description est obligatoire"),
      code: Yup.string().required('Le code est obligatoire'),
      nom: Yup.string().required('Le nom est obligatoire'),
    });

    const categorieFaqForm = ref<CategorieFaq | null>(null);
    const addCategorieFaqModalRef = ref<null | HTMLElement>(null);
    // const item = ref({ ...props.item });
    const localItem = ref(props.item);
    const isUPDATE = ref(false);
    const title = ref("Ajouter une catégorie de faq");
    const btntext = ref('Ajouter');

    watch(() => props.item, (newValue) => {
      getCategorieFaq(newValue);
      isUPDATE.value = true;
      btnTitle();
    });

    const btnTitle = async () => {
      if (isUPDATE.value) {
         title.value = "Modifier la catégorie de faq";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter une catégorie de faq";
         btntext.value = "Ajouter";
      }
    }

    const getCategorieFaq = async (id: number) => {
      return ApiService.get("/categorieFaqs/"+id)
      .then(({ data }) => {
        // map data in form
        const donnees = data.data;
        for (const key in donnees) {
          categorieFaqForm.value?.setFieldValue(key, 
          (typeof donnees[key] === 'object' && donnees[key] !== null)? donnees[key].id :donnees[key]
          );
        }
        emit('openmodal', addCategorieFaqModalRef.value);

      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const addCategorieFaq = async (values: any, categorieFaqForm) => {
      values = values as CategorieFaq;
      if(isUPDATE.value){
        ApiService.put("/categorieFaqs/"+values.id,values)
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              categorieFaqForm.resetForm();
              hideModal(addCategorieFaqModalRef.value);
              isUPDATE.value=false;
              btnTitle();
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }else{
        console.log('values',values)
        ApiService.post("/categorieFaqs",values)
        .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              categorieFaqForm.resetForm();
              hideModal(addCategorieFaqModalRef.value);
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }
    };

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isUPDATE.value=false;
      formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    return { categorieFaqSchema,
      addCategorieFaqModalRef,
      addCategorieFaq,
      categorieFaqForm,
      title,btntext,resetValue
    };
  },
});
</script>@/models/CategorieInfo