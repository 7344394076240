export const countries = [
    { value: 'Afghanistan', label: 'Afghanistan' },
    { value: 'Albanie', label: 'Albanie' },
    { value: 'Algérie', label: 'Algérie' },
    { value: 'Andorre', label: 'Andorre' },
    { value: 'Angola', label: 'Angola' },
    { value: 'Antigua-et-Barbuda', label: 'Antigua-et-Barbuda' },
    { value: 'Argentine', label: 'Argentine' },
    { value: 'Arménie', label: 'Arménie' },
    { value: 'Australie', label: 'Australie' },
    { value: 'Autriche', label: 'Autriche' },
    { value: 'Azerbaïdjan', label: 'Azerbaïdjan' },
    { value: 'Bahamas', label: 'Bahamas' },
    { value: 'Bahreïn', label: 'Bahreïn' },
    { value: 'Bangladesh', label: 'Bangladesh' },
    { value: 'Barbade', label: 'Barbade' },
    { value: 'Biélorussie', label: 'Biélorussie' },
    { value: 'Belgique', label: 'Belgique' },
    { value: 'Belize', label: 'Belize' },
    { value: 'Bénin', label: 'Bénin' },
    { value: 'Bhoutan', label: 'Bhoutan' },
    { value: 'Bolivie', label: 'Bolivie' },
    { value: 'Bosnie-Herzégovine', label: 'Bosnie-Herzégovine' },
    { value: 'Botswana', label: 'Botswana' },
    { value: 'Brésil', label: 'Brésil' },
    { value: 'Brunéi', label: 'Brunéi' },
    { value: 'Bulgarie', label: 'Bulgarie' },
    { value: 'Burkina Faso', label: 'Burkina Faso' },
    { value: 'Burundi', label: 'Burundi' },
    { value: 'Cap-Vert', label: 'Cap-Vert' },
    { value: 'Cambodge', label: 'Cambodge' },
    { value: 'Cameroun', label: 'Cameroun' },
    { value: 'Canada', label: 'Canada' },
    { value: 'République centrafricaine', label: 'République centrafricaine' },
    { value: 'Tchad', label: 'Tchad' },
    { value: 'Chili', label: 'Chili' },
    { value: 'Chine', label: 'Chine' },
    { value: 'Colombie', label: 'Colombie' },
    { value: 'Comores', label: 'Comores' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: 'Croatie', label: 'Croatie' },
    { value: 'Cuba', label: 'Cuba' },
    { value: 'Chypre', label: 'Chypre' },
    { value: 'Tchéquie', label: 'Tchéquie' },
    { value: 'Côte d\'Ivoire', label: 'Côte d\'Ivoire' },
    { value: 'Danemark', label: 'Danemark' },
    { value: 'Djibouti', label: 'Djibouti' },
    { value: 'Dominique', label: 'Dominique' },
    { value: 'République dominicaine', label: 'République dominicaine' },
    { value: 'Équateur', label: 'Équateur' },
    { value: 'Égypte', label: 'Égypte' },
    { value: 'Salvador', label: 'Salvador' },
    { value: 'Guinée équatoriale', label: 'Guinée équatoriale' },
    { value: 'Érythrée', label: 'Érythrée' },
    { value: 'Estonie', label: 'Estonie' },
    { value: 'Eswatini', label: 'Eswatini' },
    { value: 'Éthiopie', label: 'Éthiopie' },
    { value: 'Fidji', label: 'Fidji' },
    { value: 'Finlande', label: 'Finlande' },
    { value: 'France', label: 'France' },
    { value: 'Gabon', label: 'Gabon' },
    { value: 'Gambie', label: 'Gambie' },
    { value: 'Géorgie', label: 'Géorgie' },
    { value: 'Allemagne', label: 'Allemagne' },
    { value: 'Ghana', label: 'Ghana' },
    { value: 'Grèce', label: 'Grèce' },
    { value: 'Grenade', label: 'Grenade' },
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Guinée', label: 'Guinée' },
    { value: 'Guinée-Bissau', label: 'Guinée-Bissau' },
    { value: 'Guyana', label: 'Guyana' },
    { value: 'Haïti', label: 'Haïti' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Hongrie', label: 'Hongrie' },
    { value: 'Islande', label: 'Islande' },
    { value: 'Inde', label: 'Inde' },
    { value: 'Indonésie', label: 'Indonésie' },
    { value: 'Iran', label: 'Iran' },
    { value: 'Irak', label: 'Irak' },
    { value: 'Irlande', label: 'Irlande' },
    { value: 'Israël', label: 'Israël' },
    { value: 'Italie', label: 'Italie' },
    { value: 'Jamaïque', label: 'Jamaïque' },
    { value: 'Japon', label: 'Japon' },
    { value: 'Jordanie', label: 'Jordanie' },
    { value: 'Kazakhstan', label: 'Kazakhstan' },
    { value: 'Kenya', label: 'Kenya' },
    { value: 'Kiribati', label: 'Kiribati' },
    { value: 'Koweït', label: 'Koweït' },
    { value: 'Kirghizistan', label: 'Kirghizistan' },
    { value: 'Laos', label: 'Laos' },
    { value: 'Lettonie', label: 'Lettonie' },
    { value: 'Liban', label: 'Liban' },
    { value: 'Lesotho', label: 'Lesotho' },
    { value: 'Libéria', label: 'Libéria' },
    { value: 'Libye', label: 'Libye' },
    { value: 'Liechtenstein', label: 'Liechtenstein' },
    { value: 'Lituanie', label: 'Lituanie' },
    { value: 'Luxembourg', label: 'Luxembourg' },
    { value: 'Macédoine du Nord', label: 'Macédoine du Nord' },
    { value: 'Madagascar', label: 'Madagascar' },
    { value: 'Malawi', label: 'Malawi' },
    { value: 'Malaisie', label: 'Malaisie' },
    { value: 'Maldives', label: 'Maldives' },
    { value: 'Mali', label: 'Mali' },
    { value: 'Malte', label: 'Malte' },
    { value: 'Îles Marshall', label: 'Îles Marshall' },
    { value: 'Mauritanie', label: 'Mauritanie' },
    { value: 'Maurice', label: 'Maurice' },
    { value: 'Mexique', label: 'Mexique' },
    { value: 'Micronésie', label: 'Micronésie' },
    { value: 'Moldavie', label: 'Moldavie' },
    { value: 'Monaco', label: 'Monaco' },
    { value: 'Mongolie', label: 'Mongolie' },
    { value: 'Monténégro', label: 'Monténégro' },
    { value: 'Maroc', label: 'Maroc' },
    { value: 'Mozambique', label: 'Mozambique' },
    { value: 'Myanmar', label: 'Myanmar' },
    { value: 'Namibie', label: 'Namibie' },
    { value: 'Nauru', label: 'Nauru' },
    { value: 'Népal', label: 'Népal' },
    { value: 'Pays-Bas', label: 'Pays-Bas' },
    { value: 'Nouvelle-Zélande', label: 'Nouvelle-Zélande' },
    { value: 'Nicaragua', label: 'Nicaragua' },
    { value: 'Niger', label: 'Niger' },
    { value: 'Nigeria', label: 'Nigeria' },
    { value: 'Corée du Nord', label: 'Corée du Nord' },
    { value: 'Norvège', label: 'Norvège' },
    { value: 'Oman', label: 'Oman' },
    { value: 'Pakistan', label: 'Pakistan' },
    { value: 'Palaos', label: 'Palaos' },
    { value: 'Palestine', label: 'Palestine' },
    { value: 'Panama', label: 'Panama' },
    { value: 'Papouasie-Nouvelle-Guinée', label: 'Papouasie-Nouvelle-Guinée' },
    { value: 'Paraguay', label: 'Paraguay' },
    { value: 'Pérou', label: 'Pérou' },
    { value: 'Philippines', label: 'Philippines' },
    { value: 'Pologne', label: 'Pologne' },
    { value: 'Portugal', label: 'Portugal' },
    { value: 'Qatar', label: 'Qatar' },
    { value: 'Roumanie', label: 'Roumanie' },
    { value: 'Russie', label: 'Russie' },
    { value: 'Rwanda', label: 'Rwanda' },
    { value: 'Saint-Christophe-et-Niévès', label: 'Saint-Christophe-et-Niévès' },
    { value: 'Sainte-Lucie', label: 'Sainte-Lucie' },
    { value: 'Saint-Vincent-et-les-Grenadines', label: 'Saint-Vincent-et-les-Grenadines' },
    { value: 'Samoa', label: 'Samoa' },
    { value: 'Saint-Marin', label: 'Saint-Marin' },
    { value: 'Sao Tomé-et-Principe', label: 'Sao Tomé-et-Principe' },
    { value: 'Arabie saoudite', label: 'Arabie saoudite' },
    { value: 'Sénégal', label: 'Sénégal' },
    { value: 'Serbie', label: 'Serbie' },
    { value: 'Seychelles', label: 'Seychelles' },
    { value: 'Sierra Leone', label: 'Sierra Leone' },
    { value: 'Singapour', label: 'Singapour' },
    { value: 'Slovaquie', label: 'Slovaquie' },
    { value: 'Slovénie', label: 'Slovénie' },
    { value: 'Îles Salomon', label: 'Îles Salomon' },
    { value: 'Somalie', label: 'Somalie' },
    { value: 'Afrique du Sud', label: 'Afrique du Sud' },
    { value: 'Corée du Sud', label: 'Corée du Sud' },
    { value: 'Soudan du Sud', label: 'Soudan du Sud' },
    { value: 'Espagne', label: 'Espagne' },
    { value: 'Sri Lanka', label: 'Sri Lanka' },
    { value: 'Soudan', label: 'Soudan' },
    { value: 'Suriname', label: 'Suriname' },
    { value: 'Suède', label: 'Suède' },
    { value: 'Suisse', label: 'Suisse' },
    { value: 'Syrie', label: 'Syrie' },
    { value: 'Taïwan', label: 'Taïwan' },
    { value: 'Tadjikistan', label: 'Tadjikistan' },
    { value: 'Tanzanie', label: 'Tanzanie' },
    { value: 'Thaïlande', label: 'Thaïlande' },
    { value: 'tl', label: 'Timor oriental' },
    { value: 'tg', label: 'Togo' },
    { value: 'to', label: 'Tonga' },
    { value: 'tt', label: 'Trinité-et-Tobago' },
    { value: 'tn', label: 'Tunisie' },
    { value: 'tr', label: 'Turquie' },
    { value: 'tm', label: 'Turkménistan' },
    { value: 'tv', label: 'Tuvalu' },
    { value: 'ug', label: 'Ouganda' },
    { value: 'ua', label: 'Ukraine' },
    { value: 'ae', label: 'Émirats arabes unis' },
    { value: 'gb', label: 'Royaume-Uni' },
    { value: 'us', label: 'États-Unis d\'Amérique' },
    { value: 'uy', label: 'Uruguay' },
    { value: 'uz', label: 'Ouzbékistan' },
    { value: 'vu', label: 'Vanuatu' },
    { value: 'va', label: 'Cité du Vatican' },
    { value: 've', label: 'Venezuela' },
    { value: 'vn', label: 'Vietnam' },
    { value: 'ye', label: 'Yémen' },
    { value: 'zm', label: 'Zambie' },
    { value: 'zw', label: 'Zimbabwe' },
    { value: 'Timor oriental', label: 'Timor oriental' },
    { value: 'Togo', label: 'Togo' },
    { value: 'Tonga', label: 'Tonga' },
    { value: 'Trinité-et-Tobago', label: 'Trinité-et-Tobago' },
    { value: 'Tunisie', label: 'Tunisie' },
    { value: 'Turquie', label: 'Turquie' },
    { value: 'Turkménistan', label: 'Turkménistan' },
    { value: 'Tuvalu', label: 'Tuvalu' },
    { value: 'Ouganda', label: 'Ouganda' },
    { value: 'Ukraine', label: 'Ukraine' },
    { value: 'Émirats arabes unis', label: 'Émirats arabes unis' },
    { value: 'Royaume-Uni', label: 'Royaume-Uni' },
    { value: 'États-Unis d\'Amérique', label: 'États-Unis d\'Amérique' },
    { value: 'Uruguay', label: 'Uruguay' },
    { value: 'Ouzbékistan', label: 'Ouzbékistan' },
    { value: 'Vanuatu', label: 'Vanuatu' },
    { value: 'Cité du Vatican', label: 'Cité du Vatican' },
    { value: 'Venezuela', label: 'Venezuela' },
    { value: 'Vietnam', label: 'Vietnam' },
    { value: 'Yémen', label: 'Yémen' },
    { value: 'Zambie', label: 'Zambie' },
    { value: 'Zimbabwe', label: 'Zimbabwe' }
  ];
  