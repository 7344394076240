<template>
  <div class="row">
      <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <router-link to="/liste-articles" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium" >
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
              
            </div>
            <table class="table">
              <tbody>
                <tr v-if="article?.urlImage">
                  <td class="bl">Image:</td>
                  <td><img :src="getUrlApiForFiles(article.urlImage)" alt="Image" width="200" height="200"></td>
                </tr><tr v-if="article?.icone">
                  <td class="bl">Icon:</td>
                  <td><img :src="getUrlApiForFiles(article.icone)" alt="Image" width="200" height="200"></td>
                </tr>
                <tr v-if="article?.titre">
                  <td class="bl">Titre:</td>
                  <td>{{ article?.titre }}</td>
                </tr>
                <tr v-if="article?.sousTitre">
                  <td class="bl">Sous titre:</td>
                  <td>{{ article?.sousTitre }}</td>
                </tr>
                <tr v-if="article?.categorieArticle">
                  <td class="bl">Categorie article:</td>
                  <td>{{ article?.categorieArticle.nom }}</td>
                </tr>
                <tr v-if="article?.alias">
                  <td class="bl">Alias:</td>
                  <td v-html="article.alias"></td>
                </tr>
                <tr v-if="article?.contenu">
                  <td class="bl">Contenu:</td>
                  <td v-html="article?.contenu"></td>
                </tr>
                <tr v-if="article?.metaTitle">
                  <td class="bl">Meta title:</td>
                  <td>{{ article?.metaTitle }}</td>
                </tr>
                <tr v-if="article?.metaKeyword">
                  <td class="bl">MetaKeyWord:</td>
                  <td>{{ article?.metaKeyword }}</td>
                </tr>
                <tr v-if="article?.metaDescription">
                  <td class="bl">MetaDescription:</td>
                  <td v-html="article?.metaDescription"></td>
                </tr>
                <tr v-if="article?.datePublication">
                  <td class="bl">Date publication:</td>
                  <td>{{ article?.datePublication }}</td>
                </tr>
                <tr v-if="article?.source">
                  <td class="bl">Source:</td>
                  <td>{{ article?.source }}</td>
                </tr>
                <!-- <tr v-if="article?.auteur">
                  <td>Auteur</td>
                  <td>{{ article?.auteur }}</td>
                </tr> -->
                <!-- <tr v-if="article?.partageReseauSociaux">
                  <td>Partage réseau sociaux</td>
                  <td>{{ article?.partageReseauSociaux }}</td>
                </tr> -->
                <tr v-if="article?.estPublie">
                  <td class="bl">Est publié:</td>
                  <td>{{ article.estPublie }}</td>
                </tr>
                <tr>
                    <td class="bl">Tag: <p>(Cliquez sur l'icône pour supprimer un tag)</p> </td>
                    <td>
                      <!-- <span class="fw-bold text-success text-badge d-inline-block">{{ articletag.tag.nom }}
                              <button
                            type="button"
                            class="btn-close shadow-none"
                            aria-label="Close"
                            @click="deleteTag(articletag.id)"
                          ></button>
                            <br>  
                            </span> -->
                        <!-- <span v-for="articletag in article?.articletags" :key="articletag.id">
                            <span class="badge text-outline-success">{{ articletag.tag.nom }}
                              <a
                                class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                @click="suppression(articletag.id, article?.articletags,'articletag',`le tag ${articletag.tag.nom}`	)" 
                                >
                                <i class="flaticon-delete lh-1 me-1 position-relative" ></i>
                              </a>
                              </span>
                        </span> -->
                        <div >
                          <span v-for="articletag in article?.articletags" :key="articletag.id" class="badge-container">
                            <span class="badge badge-outline-danger text-outline-primary">
                              {{ articletag.tag.nom }}
                              <a href="javascript:void(0);"
                                class="tag-delete-link text-outline-danger"
                                @click="suppression(articletag.id, article?.articletags, 'articletag', `le tag ${articletag.tag.nom}`)">
                                <i class="flaticon-delete icon-position"></i>
                              </a>
                            </span>
                          </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="bl">Images / Vidéos </td>
                    <td>
                        <div class="row ">
                            <div class="col-xl-4 col-lg-6 col-md-6" v-for="(articlee, index) in articlees" :key="index">
                                <div class="blog-card style1">
                                    <a :href="getUrlApiForFiles(articlee.type)" target="_blank" data-lightbox="gallery">
                                        <div class="blog-img" >
                                            <img :src="getUrlApiForFiles(articlee.type)" alt="Image" >
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> 

                        <div class="youtube-video" v-if="article?.source">
                          <iframe
                            :src="getYoutubeUrl(article?.source)"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from "vue";
import { error, format_date,separateur,format_Date,getUrlApiForFiles,suppression } from "@/utils/utils";
import { useRoute } from "vue-router";
import { Article } from "@/models/Article";
import ApiService from "@/services/ApiService";

export default defineComponent({
  name: "ViewArticle",
  component: {
    // YouTubeVideo,
  },
  // props: {
  //   videoUrl: {
  //     type: String,
  //     required: true,
  //   },
  // },
  setup: (props) => {
    const route = useRoute();
    const article = ref<Article | null>(null);
    const articles = ref<Array<Article>>([]);
    const videoUrl = ref()
    function getArticle(id: string) {
      return ApiService.get("/articles/"+id)
        .then(({ data }) => {
          article.value = data.data;
          videoUrl.value = article?.value?.source 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    const articlees = ref<Array<Article>>([]);   

    function getAllMedia(id:any) {
      return ApiService.get(`/articles/medias/`+id)
        .then(({ data }) => {
          articlees.value = data.data;
          console.log(articles.value);
          return data;
        })
        .catch(({ response }) => {
          error(response.message)
      });
    }

    function getYoutubeUrl(source) {
      if (!source) return ''; 
      const videoId = videoUrl.value; 
      return `https://www.youtube.com/embed/${videoId}`;
    }

    onMounted(() => {
      const id = route.params.id as string;
      getArticle(id);
      const idd= route.params.id
        getAllMedia(idd);
    });

    const deleteTag = (id) => {
      //
    }

    return { article, getArticle, format_date,format_Date, articles,separateur,getUrlApiForFiles,
      deleteTag, suppression, articlees,videoUrl,getYoutubeUrl,
     };
  },
});
</script>

<style>
  .bl{
    font-weight: bold;
  }
  .badge-container {
  margin-right: 8px; 
}

.badge-outline-success {
  color: #28a745; 
  border: 1px solid currentColor; 
  padding: 5px 10px; 
  border-radius: 20px; 
  display: flex;
  align-items: center; 
  white-space: nowrap; 
}

.tag-delete-link {
  margin-left: 10px; 
  color: inherit; 
  text-decoration: none; 
}

.icon-position {
  line-height: 1;
  position: relative;
  top: 1px;
  font-size: 14px;
}

.youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.youtube-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>