<template>
    <BreadCrumb PageTitle="Ajouter les informations" />
    <AddInfoFooter />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddInfoFooter from "../../components/InfoFooter/AddInfoFooter.vue";
  
    export default defineComponent({
      name: "AddInfoFooterPage",
      components: {
        BreadCrumb,
        AddInfoFooter,
      },
    });
  </script>