<template>
    <BreadCrumb PageTitle="Liste des tags" />
    <ListeTag/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeTag from "../../components/Tag/ListeTag.vue";

    export default defineComponent({
      name: "ListeTypMediaPage",
      components: {
        BreadCrumb,
        ListeTag,
      },
    });
  </script>