<template>
  <div class="row">
      <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <router-link to="/liste-categorieArticles" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium" >
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
              
            </div>
            <table class="table">
              <tbody>
                <tr v-if="categorie?.urlImage">
                  <td class="bl">Image :</td>
                  <td><img :src="getUrlApiForFiles(categorie.urlImage)" alt="Image" width="200" height="200"></td>
                </tr>
                <tr v-if="categorie?.nom">
                  <td class="bl">Nom :</td>
                  <td>{{ categorie?.nom }}</td>
                </tr>
                <!-- <tr v-if="categorie?.code">
                  <td class="bl">Code:</td>
                  <td>{{ categorie?.code }}</td>
                </tr> -->
                <td class="bl">Type de Catégorie :</td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <span v-if="categorie?.typeCategorie == '0'" >Service Interne</span>
                <span v-if="categorie?.typeCategorie == '1'" >Service Externe</span>
              </td>
                <tr v-if="categorie?.description">
                  <td class="bl">Description :</td>
                  <td v-html="categorie.description"></td>
                </tr>
                <tr>
                  <td class="bl">Service :</td>
                  <td>
                    <span class="badge text-outline-info">{{categorie?.isService}}</span>
                    <!-- <span v-if="categorie?.isService === true" class="badge text-outline-info">Oui</span>
                    <span v-if="categorie?.isService === false" class="badge text-outline-danger">Non</span> -->
                  </td>
                </tr>
                <tr v-if="categorie?.categorieArticle">
                  <td class="bl">Sous catégorie:</td>
                  <td> {{ categorie?.categorieArticle?.nom}}
                  </td>
                </tr>
                <tr v-if="categorie">
                  <td class="bl">Lien :</td>
                  <td> {{ categorie?.lienExterne}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { error, format_date,separateur,format_Date, getUrlApiForFiles } from "@/utils/utils";
import { useRoute } from "vue-router";
import { Categorie } from "@/models/Categorie";
import ApiService from "@/services/ApiService";

export default defineComponent({
  name: "ViewCategorie",
  setup: () => {
    const route = useRoute();
    const categorie = ref<Categorie | null>(null);
    const categories = ref<Array<Categorie>>([]);
    function getCategorie(id: string) {
      return ApiService.get("/categorieArticles/"+id)
        .then(({ data }) => {
          categorie.value = data.data; 
          console.log('jeoke', categorie.value);
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    onMounted(() => {
      const id = route.params.id as string;
      getCategorie(id);
    });

    return { categorie, getCategorie, format_date,format_Date, categories,separateur,getUrlApiForFiles };
  },
});
</script>

<style>
  .bl{
    font-weight: bold;
  }
</style>