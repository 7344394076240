<template>
    <BreadCrumb PageTitle="Ajouter un contact" />
    <AddContact />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddContact from "../../components/ContactSMS/AddContact.vue";
  
    export default defineComponent({
      name: "AddContactPage",
      components: {
        BreadCrumb,
        AddContact,
      },
    });
  </script>