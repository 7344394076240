<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-faq"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Ajouter un faq
        </router-link>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
       <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher un faq"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr >
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Question
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Réponse
              </th> -->
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Catégorie Faq
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Tag
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text pe-0"
              >ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(faq, index) in faqs" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <div v-html="faq.question.slice(0, 20) + '...'" ></div>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <div v-html="faq.reponse.slice(0, 20) + '...'" ></div>
              </td>
              <!-- <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <div v-html="faq.reponse" ></div>
              </td> -->
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ faq?.categorieFaq?.nom.length > 20 ? faq?.categorieFaq?.nom.slice(0, 20) + ' ...' : faq?.categorieFaq?.nom }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis" >
                <div class="col-12">
                  <span v-for="faqtag in faq.faqtags" :key="faqtag.id" class="badge-container">
                    <div class="col-6">
                      <span class="badge badge-outline-danger text-outline-primary">
                        {{ faqtag.tag.nom }}
                        <a href="javascript:void(0);"
                          class="tag-delete-link text-outline-danger"
                          @click="suppression(faqtag.id, faq?.faqtags, 'faqtag', `le tag ${faqtag.tag.nom}`)">
                          <i class="flaticon-delete icon-position"></i>
                        </a>
                      </span>
                    </div>
                  </span>
                </div>
                <!-- <span v-for="faqtag in faq.faqtags" :key="faqtag">
                <span class="fw-bold text-success text-badge d-inline-block">{{ faqtag.tag.nom }} </span>
                </span> -->
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text pe-0"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li >
                      <router-link :to="{ name: 'EditFaqPage', params: { id: faq.id } }" 
                          class="dropdown-item d-flex align-items-center"><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>Modifier</router-link>
                    </li>
                    <li >
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#AddNewTagModal"
                        @click="openAddNewTagModal(faq)"
                      >
                      <i class="flaticon-plus lh-1 me-8 position-relative top-1"></i>
                        Ajouter de (s) tag (s)
                      </a>
                    </li>
                    <li>
                        <router-link :to="{ name: 'ViewFaqPage', params: { id: faq.id } }" class="dropdown-item d-flex align-items-center">
                            <i class="flaticon-eye lh-1 me-8 position-relative top-1"></i>Détails
                        </router-link>
                    </li>
                    <li >
                      <a
                        class="dropdown-item d-flex align-items-center" href="javascript:void(0);" @click="suppression(faq.id,faqs,'faqs',`la faq ${faq.id}`)">
                        <i class="flaticon-delete lh-1 me-8 position-relative top-1" ></i>
                         Supprimer
                      </a>
                    </li>
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>
<AddNewTagModal :selectedFaq="selectedFaq" :selectedFaqId="selectedFaqId"/>

</template>

<script lang="ts">
import { defineComponent, onMounted, ref} from "vue";
import Swal from "sweetalert2";
import { Faq } from "../../models/Faq";
import ApiService from "../../services/ApiService";
import { suppression, error } from "../../utils/utils";
import PaginationComponent from '../../components/Utilities/Pagination.vue';
import JwtService from "../../services/JwtService";
import AddNewTagModal from "./AddNewTagModal.vue";

export default defineComponent({
  name: "ListeFaq",
  components: {
    PaginationComponent,
    AddNewTagModal
  },
  setup(){
    
    onMounted(() => {
      getAllfaqs();
    });

    const faqs = ref<Array<Faq>>([]);   
    const faq = ref<Faq>();

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllfaqs(page_, limit_);
      } catch (error) {
        //
      }
    };

     function rechercher(){
      getAllfaqs(page.value, limit.value, searchTerm.value );
    }

    // END PAGINATE

    function getAllfaqs(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/faqs?page=${page}&limit=${limi}&mot=${searchTerm}&`)
        .then(({ data }) => {
          faqs.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
          return data.data.data;
        })
        .catch(({ response }) => {
          error(response.data.message)
      });
    }
    
    function moddifier(Editfaq:Faq) {
      faq.value = Editfaq;
    }

    const selectedFaq = ref<Faq | undefined>(undefined);
    const selectedFaqId = ref<number>();

    const openAddNewTagModal = (article: Faq) => {
    selectedFaq.value = { ...article };
    selectedFaqId.value = article.id;
  };

    const deletefaq = (id: number) => {
      ApiService.delete(`/faqs/${id}`)
      .then(({ data }) => {
        Swal.fire({
          text: data.message,
          toast: true,
          icon: 'success',
          title: 'General Title',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          heightAuto: false
        });
      })
      .catch(({ response }) => {
        Swal.fire({
          text: response.data.message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Réssayer à nouveau!",
          heightAuto: false,
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
      });

      for(let i = 0; i < faqs.value.length; i++) {
        if (faqs.value[i].id === id) {
          faqs.value.splice(i, 1);
        }
      }
    };

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    }

    return { faqs,
      checkPermission,
     getAllfaqs,
     deletefaq,
     moddifier ,
     suppression,
     page, 
    totalPages,
    limit,
    totalElements,
    handlePaginate,
    rechercher,
    searchTerm,
    openAddNewTagModal,
    selectedFaq,
    selectedFaqId,
  };
  },
});
</script>

<style>
.truncate {
width: 250px; 
white-space: wrap;
overflow: hidden; 
text-overflow: ellipsis;
}</style>