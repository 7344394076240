<template>
  <div class="row">
    <div class="col-xxl-7 col-xxxl-6">
      <WhatHappening />
      <ExpectedEarnings />
    </div>
    <div class="col-xxl-5 col-xxxl-6">
      <StatsBoxes />
      <WebsiteVisitors />
    </div>
    <div class="col-xxl-6">
      <ProductsOrders />
    </div>
    <div class="col-xxl-6">
      <StockReport />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import WhatHappening from "../../components/Dashboard/Ecommerce/WhatHappening.vue";
import ExpectedEarnings from "../../components/Dashboard/Ecommerce/ExpectedEarnings.vue";
import StatsBoxes from "../../components/Dashboard/Ecommerce/StatsBoxes/index.vue";
import WebsiteVisitors from "../../components/Dashboard/Ecommerce/WebsiteVisitors.vue";
import ProductsOrders from "../../components/Dashboard/Ecommerce/ProductsOrders/index.vue";
import StockReport from "../../components/Dashboard/Ecommerce/StockReport/index.vue";
export default defineComponent({
  name: "EcommercePage",
  components: {
    WhatHappening,
    ExpectedEarnings,
    StatsBoxes,
    WebsiteVisitors,
    ProductsOrders,
    StockReport,
  },
});
</script>