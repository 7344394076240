<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="alaUneForm" @submit="editAlaUne" :validation-schema="alaUneSchema" :initial-values="alaUneForm">
        <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                 Image principale<span class="text-danger">*</span>
                 <a style="float:right;"
                        class="badge badge-outline-danger text-outline-primary"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#AddImageModal">
                        Voir l'image
                        <i class="flaticon-eye lh-1 me-8 position-relative top-1"></i>
                      </a>
                </label>
                <Field name="urlImage" type="file" 
                      accept="image/jpeg, image/png application/pdf"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Choisissez une image"/>
              </div>
              <ErrorMessage name="urlImage" class="text-danger"/>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Sous titre <span class="text-danger">*</span>
                </label>
                <Field name="subtitle" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le sous titre"/>
                <ErrorMessage name="subtitle" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Titre <span class="text-danger">*</span>
                </label>
                <Field name="titre" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le titre"/>
                <ErrorMessage name="titre" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Lien <span class="text-danger">*</span>
                </label>
                <Field name="link" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le lien"/>
              <ErrorMessage name="link" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Description <span class="text-danger">*</span>
              </label>
              <div class="mb-0">
                <QuillEditor
                  ref="descriptionEditor"
                  theme="snow"
                  placeholder="Écrivez le contenu"
                  toolbar="full"
                  name="description"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
              <!-- <p class="text-danger" v-if="!showA.value">Ce champ est obligatoire</p> -->
            </div>
          </div>
            <div class="col-md-12">
              <div class="d-flex align-items-center ">
                <button
                  class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                  type="submit" @click="onSubmit()"
                >
                    Modifier
                </button>
                <router-link to="/liste-alaUnes" 
                    class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                    <span class="position-relative"></span>Annuler</router-link>
              </div>
        </div>
      </div>
    </Form>
    </div>
  </div>

  
<div
      class="modal fade createNewModal"
      id="AddImageModal"
      tabindex="-1"
      ref="addAddImageModalRef"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-15 p-md-40">
          <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25 ">
            <h5 class="modal-title fw-bold text-black"> Image actuelle</h5>
          </div>
          <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25 text-center">
          <img :src="getUrlApiForFiles(alaUne?.urlImage)" alt="Image" width="300" height="300" v-if="alaUne?.urlImage">
            <h3 v-else>Aucune image</h3>
          </div>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { error, success, getUrlApiForFiles,  } from "../../utils/utils";
import { useRoute, useRouter } from "vue-router";
import ApiService from "../../services/ApiService";
import { AlaUne } from "../../models/AlaUne";
import * as Yup from "yup";
import axios from "axios";

export default defineComponent({
  name: "EditAlaUne",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup: () => {
    const alaUneSchema = Yup.object().shape({
      // titre: Yup.string().required("Le titre est obligatoire"),
      // subtitle: Yup.string().required("Le sous titre est obligatoire"),
      // description: Yup.string().notRequired(),
      // urlImage: Yup.string().notRequired(),
      // link: Yup.string().required("Le lien est obligatoire"),
    });

    const alaUneForm = ref<AlaUne | null>(null);
    const router = useRouter();
    const route = useRoute();

    const descriptionEditor = ref();
    const empty = ref("<p><br></p>")

    const showA = ref(false)
    const show = ref("0")
    const onSubmit = () => {
      if(descriptionEditor.value.getHTML() !== empty.value) {
        showA.value = false;
        console.log('hijgrgrkjgmerjm')
      }
      else if(descriptionEditor.value.getHTML() === empty.value) {
        showA.value = true;
        show.value = "1"
        console.log('aaaaaaaaaaa')
      }
    }

    function getAlaUne(id:number) {
      ApiService.get("/alaUnes/"+id.toString())
        .then(({ data }) => {
          descriptionEditor.value.setHTML(data.data.description);
          for (const key in data.data) {
            alaUneForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    } 

    const editAlaUne = async (values, {resetForm}) => {
      values['description'] = descriptionEditor.value.getHTML();
      if(values['description'] !== empty.value) {
        showA.value = false;
      }
      else if(values['description'] === empty.value) {
        showA.value = true;
      }
      if(showA.value === false) {
      axios.put("/alaUnes/"+values.id,values, { headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeAlaUnePage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    }
    };

    const alaUne = ref<AlaUne | null>(null);
    function getCategorie(id: string) {
      return ApiService.get("/alaUnes/"+id.toString())
        .then(({ data }) => {
          alaUne.value = data.data; 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    onMounted(() => {
      if(route.params.id) {
        getAlaUne(parseInt(route.params.id as string));
      }
      if(route.params.id) {
        getCategorie(route.params.id as string);
      }
    });

    return { alaUneSchema, alaUneForm, editAlaUne,
      descriptionEditor, showA, empty, show, onSubmit,
      getUrlApiForFiles,alaUne, };
  },
});
</script>
