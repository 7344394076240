<template>
    <BreadCrumb PageTitle="Ajouter un rôle" />
    <AddRole />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddRole from "../../components/Role/AddRole.vue";
  
    export default defineComponent({
      name: "AddRolePage",
      components: {
        BreadCrumb,
        AddRole,
      },
    });
  </script>