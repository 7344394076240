<template>
    <BreadCrumb PageTitle="Liste des statuts d'une commande" />
    <ListeStatutCommande/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import ListeStatutCommande from "../../../components/Ecommerce/StatutCommande/ListeStatutCommande.vue";

    export default defineComponent({
      name: "ListeStatutCommandePage",
      components: {
        BreadCrumb,
        ListeStatutCommande,
      },
    });
  </script>