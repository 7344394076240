<template>
    <BreadCrumb PageTitle="Modifier une categorie" />
    <EditCategorie />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import EditCategorie from "../../../components/Ecommerce/Categorie/EditCategorie.vue";
  
    export default defineComponent({
      name: "EditCategoriePage",
      components: {
        BreadCrumb,
        EditCategorie,
      },
    });
  </script>