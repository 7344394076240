<template>
    <BreadCrumb PageTitle="Liste des catégories de FAQ" />
    <ListeCategorieFaq/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeCategorieFaq from "../../components/CategorieFaq/ListeCategorieFaq.vue";

    export default defineComponent({
      name: "ListeCategorieFaqPage",
      components: {
        BreadCrumb,
        ListeCategorieFaq,
      },
    });
  </script>