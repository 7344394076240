<template>
    <BreadCrumb PageTitle="Modifier une Media" />
    <EditMedia />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EditMedia from "../../components/Media/EditMedia.vue";
  
    export default defineComponent({
      name: "EditMediaPage",
      components: {
        BreadCrumb,
        EditMedia,
      },
    });
  </script>