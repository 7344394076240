<template>
    <BreadCrumb PageTitle="Ajouter un produit" />
    <AddProduit />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import AddProduit from "../../../components/Ecommerce/Produit/AddProduit.vue";
  
    export default defineComponent({
      name: "AddProduitPage",
      components: {
        BreadCrumb,
        AddProduit,
      },
    });
  </script>