import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-20 d-sm-flex align-items-center justify-content-between letter-spacing" }
const _hoisted_2 = { class: "mb-5 mb-sm-0 fw-bold" }
const _hoisted_3 = { "aria-label": "breadcrumb" }
const _hoisted_4 = { class: "breadcrumb mb-0 list-unstyled ps-0" }
const _hoisted_5 = { class: "breadcrumb-item" }
const _hoisted_6 = {
  class: "breadcrumb-item active",
  "aria-current": "page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.PageTitle), 1),
    _createElementVNode("nav", _hoisted_3, [
      _createElementVNode("ol", _hoisted_4, [
        _createElementVNode("li", _hoisted_5, [
          _createVNode(_component_router_link, {
            to: "/tableau_bord",
            class: "text-decoration-none text-black"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Tableau de bord ")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", _hoisted_6, _toDisplayString(_ctx.PageTitle), 1)
      ])
    ])
  ]))
}