<template>
    <BreadCrumb PageTitle="Ajouter un faq" />
    <AddFaq />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddFaq from "../../components/Faq/AddFaq.vue";
  
    export default defineComponent({
      name: "AddFaqPage",
      components: {
        BreadCrumb,
        AddFaq,
      },
    });
  </script>