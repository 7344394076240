<template>
    <BreadCrumb PageTitle="Liste des commandes suivi" />
    <ListeSuiviCommande/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import ListeSuiviCommande from "../../../components/Ecommerce/SuiviCommande/ListeSuiviCommande.vue";

    export default defineComponent({
      name: "ListeSuiviCommandePage",
      components: {
        BreadCrumb,
        ListeSuiviCommande,
      },
    });
  </script>