<template>
    <BreadCrumb PageTitle="Détails de la commande" />
    <ViewCommande />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import ViewCommande from "../../../components/Ecommerce/Commande/ViewCommande.vue";
  
    export default defineComponent({
      name: "ViewCommandePage",
      components: {
        BreadCrumb,
        ViewCommande,
      },
    });
  </script>