<template>
    <BreadCrumb PageTitle="Liste des faq tags" />
    <ListeFaqTag/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeFaqTag from "../../components/FaqTag/ListeFaqTag.vue";

    export default defineComponent({
      name: "ListeFaqTagPage",
      components: {
        BreadCrumb,
        ListeFaqTag,
      },
    });
  </script>