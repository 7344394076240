<template>
  <div
    class="modal fade createNewModal"
    id="AddMarqueModal"
    tabindex="-1"
    ref="addMarqueModalRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form
            ref="marqueForm"
            @submit="addMarque"
            :validation-schema="marqueSchema"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Nom <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="name"
                    type="text"
                    v-model="name"
                    class="form-control shadow-none fs-md-15 text-black"
                    placeholder="Entrer le nom"
                  />
                  <ErrorMessage name="name" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Slug <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="slug"
                    type="text"
                    v-model="slug"
                    readonly="true"
                    class="form-control shadow-none fs-md-15 text-black"
                  />
                  <ErrorMessage name="slug" class="text-danger" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Description <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="description"
                    as="textarea"
                    cols="30"
                    rows="12"
                    placeholder="Entrer la description"
                    v-slot="{ field }"
                    class="form-control shadow-none rounded-0 text-black"
                  >
                    <textarea
                      class="form-control shadow-none rounded-0 text-black"
                      v-model="field.value"
                    ></textarea>
                  </Field>
                  <ErrorMessage name="description" class="text-danger" />
                </div>
              </div>
              <div class="col-md-12" v-if="btntext != 'Modifier'">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Categorie <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="categories"
                    v-slot="{ field }"
                    v-model="defaultSelectedCategories"
                  >
                    <Multiselect
                      :options="categoriesOptions"
                      :searchable="true"
                      track-by="label"
                      multiple="true"
                      mode="tags"
                      label="label"
                      v-bind="field"
                      placeholder="Sélectionnez la catégorie"
                    />
                  </Field>
                  <ErrorMessage name="categories" class="text-danger" />
                  <span class="text-danger" v-if="produitsError"
                    >La catégorie est obligatoire</span
                  >
                </div>
              </div>
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="submit"
              >
                {{ btntext }}
              </button>
            </div>
          </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "../../../services/ApiService";
import * as Yup from "yup";
import { Marque } from "../../../models/Marque";
import { error, success, hideModal, generateSlug } from "../../../utils/utils";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "AddMarqueModal",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    item: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const marqueForm = ref<Marque | null>(null);
    const addMarqueModalRef = ref<null | HTMLElement>(null);
    const localItem = ref(props.item);
    const isUPDATE = ref(false);
    const categorie = ref("0");
    const title = ref("Ajouter une marque");
    const btntext = ref("Ajouter");
    const categoriesOptions = ref([]);

    const name = ref();
    const slug = ref();

    watch(name, () => {
      console.log("name", name.value);
      console.log("generateSlug", slug.value);
      slug.value = generateSlug(name.value);
      console.log("after", slug.value);
    });

    watch(
      () => props.item,
      (newValue) => {
        getMarque(newValue);
        isUPDATE.value = true;
        categorie.value = "1";
        btnTitle();
      }
    );

    const marqueSchema = isUPDATE.value == true ?  Yup.object().shape({
      name: Yup.string().required("Le nom est obligatoire"),
      slug: Yup.string().required("Le slug est obligatoire"),
      description: Yup.string().required("La description est obligatoire"),
      categories : Yup.array().notRequired()
    })
    :
    Yup.object().shape({
      name: Yup.string().required("Le nom est obligatoire"),
      slug: Yup.string().required("Le slug est obligatoire"),
      description: Yup.string().required("La description est obligatoire"),
      categories : Yup.array().required("Selectionnez des catégorie")
    })
    ;

    const btnTitle = async () => {
      if (isUPDATE.value) {
        title.value = "Modifier la marque";
        btntext.value = "Modifier";
      } else {
        title.value = "Ajouter une marque";
        btntext.value = "Ajouter";
      }
    };

    const defaultSelectedCategories = ref<any[]>([]);
    const produitsError = ref(false);

    watch(defaultSelectedCategories, () => {
      console.log("name", defaultSelectedCategories.value);
      if (defaultSelectedCategories.value.length > 0) {
        produitsError.value = false;
      } else {
        produitsError.value = true;
      }
    });
    const getMarque = async (id: number) => {
      return ApiService.get("/marques/" + id)
        .then(({ data }) => {
          // map data in form
          const donnees = data.data;
          // const categories = donnees.categories;

          // let ids: number[] = [];
          // for (const categorie of categories) {
          //   ids.push(categorie.id);
          // }
          // categories.value= ids;

          // defaultSelectedCategories.value = categories.value
          for (const key in donnees) {
            marqueForm.value?.setFieldValue(
              key,
              typeof donnees[key] === "object" && donnees[key] !== null
                ? donnees[key].id
                : donnees[key]
            );
          }
          emit("openmodal", addMarqueModalRef.value);
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    };

    const cat = ref(true);
    const addMarque = async (values: any, marqueForm) => {
      values = values as Marque;
      console.log("ZZZZ", values);
      console.log("isUPDATE.value", isUPDATE.value);
      if (isUPDATE.value) {
        //cat.value = false;
        values["slug"] = slug.value;
        console.log("ff", values);
        ApiService.put("/marques/" + values.id, values)
          .then(({ data }) => {
            if (data.code == 200) {
              success(data.message);
              // marqueForm.resetForm();
              hideModal(addMarqueModalRef.value);
              isUPDATE.value = false;
              btnTitle();
              emit("close");
            }
          })
          .catch(({ response }) => {
            error(response.data.message);
          });
      } else {
        if (defaultSelectedCategories.value.length > 0) {
          values["slug"] = slug.value;
          console.log("ffe", values);
          //cat.value = true;
          ApiService.post("/marques", values)
            .then(({ data }) => {
              if (data.code == 201) {
                success(data.message);
                // marqueForm.resetForm();
                hideModal(addMarqueModalRef.value);
                emit("close");
              }
            })
            .catch(({ response }) => {
              error(response.data.message);
            });
        } else {
          produitsError.value = true;
        }
      }
    };

    const fetchCatgories = async () => {
      try {
        const response = await ApiService.get("/categories/nopage");
        const categoriesData = response.data.data;
        categoriesOptions.value = categoriesData.map((categories) => ({
          value: categories.id,
          label: `${categories.name}`,
        }));
      } catch (error) {
        //
      }
    };

    onMounted(() => {
      fetchCatgories();
    });

    const resetValue = () => {
      const formFields = document.querySelectorAll<
        HTMLInputElement | HTMLTextAreaElement
      >("input, textarea");
      isUPDATE.value = false;
      categorie.value = "0";
      formFields.forEach((field) => {
        field.value = "";
      });
      btnTitle();
    };

    return {
      marqueSchema,
      addMarqueModalRef,
      addMarque,
      marqueForm,
      title,
      btntext,
      resetValue,
      categoriesOptions,
      defaultSelectedCategories,
      isUPDATE,
      categorie,
      cat,
      name,
      slug,
      produitsError,
    };
  },
});
</script>