<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="secteurActiviteForm" @submit="editSecteurActivite" :validation-schema="secteurActiviteSchema">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Libelle <span class="text-danger">*</span>
                </label>
                <Field name="libelle" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le libelle"/>
                <ErrorMessage name="libelle" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Description 
                  </label>
                  <Field name="description" as="textarea"  cols="30"
                    rows="12" placeholder="Entrer la description" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                  <textarea
                  class="form-control shadow-none rounded-0 text-black"
                    v-model="field.value"
                  ></textarea>
                </Field>
                  <ErrorMessage name="description" class="text-danger"/>
                </div>
              </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Modifier un secteur d'activité
            </button>
            <router-link to="/liste-secteurActivites" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">

import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '../../../services/ApiService';
import { error, success } from '../../../utils/utils';
import { useRoute, useRouter } from 'vue-router';
import { SecteurActivite } from '../../../models/SecteurActivite';

export default defineComponent({
    name: "EditSecteurActivite",
    components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup: () => {
    const secteurActiviteSchema = Yup.object().shape({
      libelle: Yup.string().required('Le libelle est obligatoire'),
      description: Yup.string().required('La description est obligatoire'),
    });

    const secteurActiviteForm = ref<SecteurActivite>();
    const router = useRouter();
    const route = useRoute();

    function getSecteurActivite(id:number) {
      ApiService.get("/secteuractivites/"+id.toString())
        .then(({ data }) => {
          for (const key in data.data) {
            secteurActiviteForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    } 

    const editSecteurActivite = async (values, {resetForm}) => {
      console.log('ffefe', values)
      ApiService.put("/secteuractivites/"+values.id,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeSecteurActivitePage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    };


    onMounted(() => {
      if(route.params.id) {
        getSecteurActivite(parseInt(route.params.id as string));
      }
    });

    return { secteurActiviteSchema, editSecteurActivite, secteurActiviteForm,
    };  },
});
</script>