import { createWebHistory, createRouter, createWebHashHistory } from "vue-router";

import EcommercePage from "../pages/Dashboard/EcommercePage.vue";
import LMSCoursesPage from "../pages/Dashboard/LMSCoursesPage.vue";
import LoginPage from "../pages/Authentication/LoginPage.vue";
import RegisterPage from "../pages/Authentication/RegisterPage.vue";
import ForgotPasswordPage from "../pages/Authentication/ForgotPasswordPage.vue";
import ResetPasswordPage from "../pages/Authentication/ResetPasswordPage.vue";
import EmailConfirmationPage from "../pages/Authentication/EmailConfirmationPage.vue";
import ErrorPage from "../pages/ErrorPage.vue";

import MaintenancePage from "../pages/PagesInner/MaintenancePage.vue";
import ConnectedAccountsPage from "../pages/PagesInner/ConnectedAccountsPage.vue";
import LogoutPage from "../pages/LogoutPage.vue";
import ListePermissionPage from "@/pages/Permission/ListePermissionPage.vue";
import AddRolePage from "@/pages/Role/AddRolePage.vue";
import EditRolePage from "@/pages/Role/EditRolePage.vue";
import ListeRolePage from "@/pages/Role/ListeRolePage.vue";
import ViewRolePage from "@/pages/Role/ViewRolePage.vue";
import AddUserPage from "@/pages/Users/AddUserPage.vue";
import EditUserPage from "@/pages/Users/EditUserPage.vue";
import ListeUserPage from "@/pages/Users/ListeUserPage.vue";
import ProfilePage from "../pages/PagesInner/ProfilePage.vue";
import AddCategorieArticlePage from "@/pages/CategorieArticle/AddCategorieArticlePage.vue";
import EditCategorieArticlePage from "@/pages/CategorieArticle/EditCategorieArticlePage.vue";
import ListeCategorieArticlePage from "@/pages/CategorieArticle/ListeCategorieArticlePage.vue";
import ViewCategorieArticlePage from "@/pages/CategorieArticle/ViewCategorieArticlePage.vue";
import ListeCategorieFaqPage from "@/pages/CategorieFaq/ListeCategorieFaqPage.vue";
import ListeFaqTagPage from "@/pages/FaqTag/ListeFaqTagPage.vue";
import ListeCategorieInfoPage from "@/pages/CategorieInfo/ListeCategorieInfoPage.vue";
import ListeTypeMediaPage from "@/pages/TypeMedia/ListeTypeMediaPage.vue";
import ListeParametrePage from "@/pages/Parametre/ListeParametrePage.vue";
import ListeCollectionPage from "@/pages/Collection/ListeCollectionPage.vue";
import ViewCollectionPage from "@/pages/Collection/ViewCollectionPage.vue";
import ListeIntervallePoidsPage from "@/pages/IntervallePoids/ListeIntervallePoidsPage.vue";
import AddDistancePage from "@/pages/Distance/AddDistancePage.vue";
import EditDistancePage from "@/pages/Distance/EditDistancePage.vue";
import ListeDistancePage from "@/pages/Distance/ListeDistancePage.vue";
import AddMediaPage from "@/pages/Media/AddMediaPage.vue";
import EditMediaPage from "@/pages/Media/EditMediaPage.vue";
import ListeMediaPage from "@/pages/Media/ListeMediaPage.vue";
import AddArticlePage from "@/pages/Article/AddArticlePage.vue";
import EditArticlePage from "@/pages/Article/EditArticlePage.vue";
import ListeArticlePage from "@/pages/Article/ListeArticlePage.vue";
import ViewArticlePage from "@/pages/Article/ViewArticlePage.vue";
import AddMontantPage from "@/pages/Montant/AddMontantPage.vue";
import EditMontantPage from "@/pages/Montant/EditMontantPage.vue";
import ListeMontantPage from "@/pages/Montant/ListeMontantPage.vue";
import AddFaqPage from "@/pages/Faq/AddFaqPage.vue";
import EditFaqPage from "@/pages/Faq/EditFaqPage.vue";
import ListeFaqPage from "@/pages/Faq/ListeFaqPage.vue";
import ViewFaqPage from "@/pages/Faq/ViewFaqPage.vue";
import ListeTagPage from "@/pages/Tag/ListeTagPage.vue";
import AddAproposPage from "@/pages/Apropos/AddAproposPage.vue";
import EditAproposPage from "@/pages/Apropos/EditAproposPage.vue";
import ViewAproposPage from "@/pages/Apropos/ViewAproposPage.vue";
import ListeAproposPage from "@/pages/Apropos/ListeAproposPage.vue";
import AddInfoFooterPage from "@/pages/InfoFooter/AddInfoFooterPage.vue";
import EditInfoFooterPage from "@/pages/InfoFooter/EditInfoFooterPage.vue";
import ViewInfoFooterPage from "@/pages/InfoFooter/ViewInfoFooterPage.vue";
import ListeInfoFooterPage from "@/pages/InfoFooter/ListeInfoFooterPage.vue";
import AddContactPage from "@/pages/Contact/AddContactPage.vue";
import EditContactPage from "@/pages/Contact/EditContactPage.vue";
import ViewContactPage from "@/pages/Contact/ViewContactPage.vue";
import ListeContactPage from "@/pages/Contact/ListeContactPage.vue";

import ListeCouleurPage from "@/pages/Ecommerce/Couleur/ListeCouleurPage.vue";
import ListeMarquePage from "@/pages/Ecommerce/Marque/ListeMarquePage.vue";
import ListeModelePage from "@/pages/Ecommerce/Modele/ListeModelePage.vue";
import ListeTaillePage from "@/pages/Ecommerce/Taille/ListeTaillePage.vue";
import ListePoidsPage from "@/pages/Ecommerce/Poids/ListePoidsPage.vue";
import ListeMoyenPaiementPage from "@/pages/Ecommerce/MoyenPaiement/ListeMoyenPaiementPage.vue";
import ListeModeLivraisonPage from "@/pages/Ecommerce/ModeLivraison/ListeModeLivraisonPage.vue";
import AddProduitPage from "@/pages/Ecommerce/Produit/AddProduitPage.vue";
import EditProduitPage from "@/pages/Ecommerce/Produit/EditProduitPage.vue";
import ListeProduitPage from "@/pages/Ecommerce/Produit/ListeProduitPage.vue";
import ViewProduitPage from "@/pages/Ecommerce/Produit/ViewProduitPage.vue";
import AddCategoriePage from "@/pages/Ecommerce/Categorie/AddCategoriePage.vue";
import EditCategoriePage from "@/pages/Ecommerce/Categorie/EditCategoriePage.vue";
import ListeCategoriePage from "@/pages/Ecommerce/Categorie/ListeCategoriePage.vue";
import AddPromotionPage from "@/pages/Ecommerce/Promotion/AddPromotionPage.vue";
import EditPromotionPage from "@/pages/Ecommerce/Promotion/EditPromotionPage.vue";
import ListePromotionPage from "@/pages/Ecommerce/Promotion/ListePromotionPage.vue";
import AddMarchandPage from "@/pages/Ecommerce/Marchand/AddMarchandPage.vue";
import EditMarchandPage from "@/pages/Ecommerce/Marchand/EditMarchandPage.vue";
import ListeMarchandPage from "@/pages/Ecommerce/Marchand/ListeMarchandPage.vue";
import ViewMarchandPage from "@/pages/Ecommerce/Marchand/ViewMarchandPage.vue";
import ListeCommandePage from "@/pages/Ecommerce/Commande/ListeCommandePage.vue";
import ViewCommandePage from "@/pages/Ecommerce/Commande/ViewCommandePage.vue";
import AddSecteurActivitePage from "@/pages/Ecommerce/SecteurActivite/AddSecteurActivitePage.vue";
import EditSecteurActivitePage from "@/pages/Ecommerce/SecteurActivite/EditSecteurActivitePage.vue";
import ListeSecteurActivitePage from "@/pages/Ecommerce/SecteurActivite/ListeSecteurActivitePage.vue";
import AddClientPage from "@/pages/Ecommerce/Client/AddClientPage.vue";
import EditClientPage from "@/pages/Ecommerce/Client/EditClientPage.vue";
import ListeClientPage from "@/pages/Ecommerce/Client/ListeClientPage.vue";
import ViewClientPage from "@/pages/Ecommerce/Client/ViewClientPage.vue";
import AddCouponPage from "@/pages/Ecommerce/Coupon/AddCouponPage.vue";
import EditCouponPage from "@/pages/Ecommerce/Coupon/EditCouponPage.vue";
import ListeCouponPage from "@/pages/Ecommerce/Coupon/ListeCouponPage.vue";
import AddContratPage from "@/pages/Ecommerce/Contrat/AddContratPage.vue";
import EditContratPage from "@/pages/Ecommerce/Contrat/EditContratPage.vue";
import ListeContratPage from "@/pages/Ecommerce/Contrat/ListeContratPage.vue";
import ListeStatutCommandePage from "@/pages/Ecommerce/StatutCommande/ListeStatutCommandePage.vue";
import ListeSuiviCommandePage from "@/pages/Ecommerce/SuiviCommande/ListeSuiviCommandePage.vue";
import AddAlaUnePage from "@/pages/AlaUne/AddAlaUnePage.vue";
import EditAlaUnePage from "@/pages/AlaUne/EditAlaUnePage.vue";
import ListeAlaUnePage from "@/pages/AlaUne/ListeAlaUnePage.vue";
import ViewAlaUnePage from "@/pages/AlaUne/ViewAlaUnePage.vue";
import ListeCommunePage from "@/pages/Commune/ListeCommunePage.vue";
import ListeLivraisonPage from "@/pages/Livraison/ListeLivraisonPage.vue";
import ListeLivreurPage from "@/pages/Livreur/ListeLivreurPage.vue";
import ListeAvisPage from "@/pages/Avis/ListeAvisPage.vue";
import ListePaysPage from "@/pages/Pays/ListePaysPage.vue";

import { useAuthStore } from "@/services/auth";
import ApiService from "@/services/ApiService";

const routes = [
  
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/layouts/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/tableau_bord",
        name: "tableauBordPage",
        component: EcommercePage,
      },
      {
        path: "/tableau_bord1",
        name: "tableauBordPage1",
        component: LMSCoursesPage,
      },

      //**Début gestion des contenus */
      {
        path: "/ajouter-categorieArticle",
        name: "AddCategorieArticlePage",
        component: AddCategorieArticlePage,
      },
      {
        path: "/modifier-categorieArticle/:id",
        name: "EditCategorieArticlePage",
        component: EditCategorieArticlePage,
      },
      {
        path: "/liste-categorieArticles",
        name: "ListeCategorieArticlePage",
        component: ListeCategorieArticlePage,
      },
      {
        path: "/view-categorieArticle/:id",
        name: "ViewCategorieArticlePage",
        component: ViewCategorieArticlePage,
      },
      {
        path: "/liste-categorieInfos",
        name: "ListeCategorieInfoPage",
        component: ListeCategorieInfoPage,
      },
      {
        path: "/liste-categoriefaqs",
        name: "ListeCategorieFaqPage",
        component: ListeCategorieFaqPage,
      },
      {
        path: "/liste-faqTags",
        name: "ListeFaqTagPage",
        component: ListeFaqTagPage,
      },
      {
        path: "/liste-typeMedias",
        name: "ListeTypeMediaPage",
        component: ListeTypeMediaPage,
      },
      {
        path: "/liste-parametres",
        name: "ListeParametrePage",
        component: ListeParametrePage,
      },
      {
        path: "/liste-pays",
        name: "ListePaysPage",
        component: ListePaysPage,
      },
      {
        path: "/liste-collections",
        name: "ListeCollectionPage",
        component: ListeCollectionPage,
      },
      {
        path: "/view-collection/:id",
        name: "ViewCollectionPage",
        component: ViewCollectionPage,
      },
      {
        path: "/liste-intervallePoids",
        name: "ListeIntervallePoidsPage",
        component: ListeIntervallePoidsPage,
      },
      {
        path: "/liste-tags",
        name: "ListeTagPage",
        component: ListeTagPage,
      },
      {
        path: "/ajouter-distance",
        name: "AddDistancePage",
        component: AddDistancePage,
      },
      {
        path: "/modifier-distance/:id",
        name: "EditDistancePage",
        component: EditDistancePage,
      },
      {
        path: "/liste-distances",
        name: "ListeDistancePage",
        component: ListeDistancePage,
      },
      {
        path: "/ajouter-media",
        name: "AddMediaPage",
        component: AddMediaPage,
      },
      {
        path: "/modifier-media/:id",
        name: "EditMediaPage",
        component: EditMediaPage,
      },
      {
        path: "/liste-medias",
        name: "ListeMediaPage",
        component: ListeMediaPage,
      },
      {
        path: "/ajouter-article",
        name: "AddArticlePage",
        component: AddArticlePage,
      },
      {
        path: "/modifier-article/:id",
        name: "EditArticlePage",
        component: EditArticlePage,
      },
      {
        path: "/liste-articles",
        name: "ListeArticlePage",
        component: ListeArticlePage,
      },
      {
        path: "/view-article/:id",
        name: "ViewArticlePage",
        component: ViewArticlePage,
      },
      {
        path: "/ajouter-faq",
        name: "AddFaqPage",
        component: AddFaqPage,
      },
      {
        path: "/modifier-faq/:id",
        name: "EditFaqPage",
        component: EditFaqPage,
      },
      {
        path: "/liste-faqs",
        name: "ListeFaqPage",
        component: ListeFaqPage,
      },
      {
        path: "/view-faq/:id",
        name: "ViewFaqPage",
        component: ViewFaqPage,
      },
      {
        path: "/ajouter-montant",
        name: "AddMontantPage",
        component: AddMontantPage,
      },
      {
        path: "/modifier-montant/:id",
        name: "EditMontantPage",
        component: EditMontantPage,
      },
      {
        path: "/liste-montants",
        name: "ListeMontantPage",
        component: ListeMontantPage,
      },
      {
        path: "/ajouter-apropos",
        name: "AddAproposPage",
        component: AddAproposPage,
      },
      {
        path: "/modifier-apropos/:id",
        name: "EditAproposPage",
        component: EditAproposPage,
      },
      {
        path: "/view-apropos/:id",
        name: "ViewAproposPage",
        component: ViewAproposPage,
      },
      {
        path: "/liste-apropos",
        name: "ListeAproposPage",
        component: ListeAproposPage,
      },
      
      {
        path: "/ajouter-infoFooter",
        name: "AddInfoFooterPage",
        component: AddInfoFooterPage,
      },
      {
        path: "/modifier-infoFooter/:id",
        name: "EditInfoFooterPage",
        component: EditInfoFooterPage,
      },
      {
        path: "/view-infoFooter/:id",
        name: "ViewInfoFooterPage",
        component: ViewInfoFooterPage,
      },
      {
        path: "/liste-infoFooter",
        name: "ListeInfoFooterPage",
        component: ListeInfoFooterPage,
      },
      {
        path: "/liste-avis",
        name: "ListeAvisPage",
        component: ListeAvisPage,
      },
      {
        path: "/ajouter-alaUne",
        name: "AddAlaUnePage",
        component: AddAlaUnePage,
      },
      {
        path: "/modifier-alaUne/:id",
        name: "EditAlaUnePage",
        component: EditAlaUnePage,
      },
      {
        path: "/liste-alaUnes",
        name: "ListeAlaUnePage",
        component: ListeAlaUnePage,
      },
      {
        path: "/view-alaUne/:id",
        name: "ViewAlaUnePage",
        component: ViewAlaUnePage,
      },
      {
        path: "/ajouter-contact",
        name: "AddContactPage",
        component: AddContactPage,
      },
      {
        path: "/modifier-contact/:id",
        name: "EditContactPage",
        component: EditContactPage,
      },
      {
        path: "/view-contact/:id",
        name: "ViewContactPage",
        component: ViewContactPage,
      },
      {
        path: "/liste-contacts",
        name: "ListeContactPage",
        component: ListeContactPage,
      },

      //** FIn gestion des contenus */


      //**Début ecommerce */

      {
        path: "/liste-couleurs",
        name: "ListeCouleurPage",
        component: ListeCouleurPage,
      },
      {
        path: "/liste-marques",
        name: "ListeMarquePage",
        component: ListeMarquePage,
      },
      {
        path: "/liste-modeles",
        name: "ListeModelePage",
        component: ListeModelePage,
      },
      {
        path: "/liste-tailles",
        name: "ListeTaillePage",
        component: ListeTaillePage,
      },
      {
        path: "/liste-poids",
        name: "ListePoidsPage",
        component: ListePoidsPage,
      },
      {
        path: "/liste-communes",
        name: "ListeCommunePage",
        component: ListeCommunePage,
      },
      {
        path : "/liste-livraisonCommandes",
        name: "ListeLivraisonPage",
        component: ListeLivraisonPage
      },
      {
        path : "/liste-livreurs",
        name: "ListeLivreurPage",
        component: ListeLivreurPage
      },
      {
        path: "/ajouter-produit",
        name: "AddProduitPage",
        component: AddProduitPage,
      },
      {
        path: "/modifier-produit/:id",
        name: "EditProduitPage",
        component: EditProduitPage,
      },
      {
        path: "/liste-produits",
        name: "ListeProduitPage",
        component: ListeProduitPage,
      },
      {
        path: "/view-produit/:id",
        name: "ViewProduitPage",
        component: ViewProduitPage,
      },
      {
        path: "/ajouter-categorie",
        name: "AddCategoriePage",
        component: AddCategoriePage,
      },
      {
        path: "/modifier-categorie/:id",
        name: "EditCategoriePage",
        component: EditCategoriePage,
      },
      {
        path: "/liste-categories",
        name: "ListeCategoriePage",
        component: ListeCategoriePage,
      },
      {
        path: "/ajouter-promotion",
        name: "AddPromotionPage",
        component: AddPromotionPage,
      },
      {
        path: "/modifier-promotion/:id",
        name: "EditPromotionPage",
        component: EditPromotionPage,
      },
      {
        path: "/liste-promotions",
        name: "ListePromotionPage",
        component: ListePromotionPage,
      },

      {
        path: "/ajouter-marchand",
        name: "AddMarchandPage",
        component: AddMarchandPage,
      },
      {
        path: "/modifier-marchand/:id",
        name: "EditMarchandPage",
        component: EditMarchandPage,
      },
      {
        path: "/liste-marchands",
        name: "ListeMarchandPage",
        component: ListeMarchandPage,
      },
      {
        path: "/view-marchand/:id",
        name: "ViewMarchandPage",
        component: ViewMarchandPage,
      },
      {
        path: "/ajouter-contrat",
        name: "AddContratPage",
        component: AddContratPage,
      },
      {
        path: "/modifier-contrat/:id",
        name: "EditContratPage",
        component: EditContratPage,
      },
      {
        path: "/liste-contrats",
        name: "ListeContratPage",
        component: ListeContratPage,
      },
      {
        path: "/liste-commandes",
        name: "ListeCommandePage",
        component: ListeCommandePage,
      },
      {
        path: "/view-commande/:id",
        name: "ViewCommandePage",
        component: ViewCommandePage,
      },
      {
        path: "/liste-statutCommandes",
        name: "ListeStatutCommandePage",
        component: ListeStatutCommandePage,
      },
      {
        path: "/liste-suiviCommandes",
        name: "ListeSuiviCommandePage",
        component: ListeSuiviCommandePage,
      },
      {
        path: "/ajouter-secteurActivite",
        name: "AddSecteurActivitePage",
        component: AddSecteurActivitePage,
      },
      {
        path: "/modifier-secteurActivite/:id",
        name: "EditSecteurActivitePage",
        component: EditSecteurActivitePage,
      },
      {
        path: "/liste-secteurActivites",
        name: "ListeSecteurActivitePage",
        component: ListeSecteurActivitePage,
      },
      
      {
        path: "/ajouter-client",
        name: "AddClientPage",
        component: AddClientPage,
      },
      {
        path: "/modifier-client/:id",
        name: "EditClientPage",
        component: EditClientPage,
      },
      {
        path: "/liste-clients",
        name: "ListeClientPage",
        component: ListeClientPage,
      },
      {
        path: "/view-client/:id",
        name: "ViewClientPage",
        component: ViewClientPage,
      },
      {
        path: "/ajouter-coupon",
        name: "AddCouponPage",
        component: AddCouponPage,
      },
      {
        path: "/modifier-coupon/:id",
        name: "EditCouponPage",
        component: EditCouponPage,
      },
      {
        path: "/liste-coupons",
        name: "ListeCouponPage",
        component: ListeCouponPage,
      },
      {
        path: "/liste-moyenPaiements",
        name: "ListeMoyenPaiementPage",
        component: ListeMoyenPaiementPage,
      },
      
      {
        path: "/liste-modeLivraisons",
        name: "ListeModeLivraisonPage",
        component: ListeModeLivraisonPage,
      },
      // {
      //   path: "/liste-prixPromo",
      //   name: "ListePrixPromoPage",
      //   component: ListePrixPromoPage,
      // },
      //** Fin Ecommerce */

      {
        path: "/liste-privileges",
        name: "ListePermissionPage",
        component: ListePermissionPage,
      },
      { 
        path: "/ajouter-role",
        name: "AddRolePage",
        component: AddRolePage,
      },
      {
        path: "/modifier-role/:id",
        name: "EditRolePage",
        component: EditRolePage,
      },
      {
        path: "/liste-roles",
        name: "ListeRolePage",
        component: ListeRolePage,
      },
      {
        path: "/view-role/:id",
        name: "ViewRolePage",
        component: ViewRolePage,
      },
      {
        path: "/ajouter-user",
        name: "AddUserPage",
        component: AddUserPage,
      },
      {
        path: "/modifier-user/:id",
        name: "EditUserPage",
        component: EditUserPage,
      },
      {
        path: "/liste-users",
        name: "ListeUserPage",
        component: ListeUserPage,
      },
      {
        path: "/profile",
        name: "ProfilePage",
        component: ProfilePage,
      },
    ],
  },
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
      },
      {
        path: "/register",
        name: "RegisterPage",
        component: RegisterPage,
      },
      {
        path: "/forgot-password",
        name: "ForgotPasswordPage",
        component: ForgotPasswordPage,
      },
      {
        path: "/reset-password",
        name: "ResetPasswordPage",
        component: ResetPasswordPage,
      },
      {
        path: "/email-confirmation",
        name: "EmailConfirmationPage",
        component: EmailConfirmationPage,
      },
      {
        path: "/connected-accounts",
        name: "ConnectedAccountsPage",
        component: ConnectedAccountsPage,
      },
      {
        path: "/maintenance",
        name: "MaintenancePage",
        component: MaintenancePage,
      },
      {
        path: "/logout",
        name: "LogoutPage",
        component: LogoutPage,
      },
    ],
  },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
];

const router = createRouter({
 // history: createWebHistory(),
  history: createWebHashHistory(),
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  // verify auth token before each page change
  //authStore.verifyAuth();
  
  // before page access check if page requires authentication
  if(to.meta.middleware == "auth") {
    ApiService.setHeader();
    if (authStore.isAuthenticated) {
      next();
    } else {
      next({ name: "LoginPage" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});
// window.scrollTo({
//   top: 0,
//   left: 0,
//   behavior: "smooth",
// });

export default router;
