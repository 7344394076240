<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="contratForm" @submit="addContrat" :validation-schema="contratSchema">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type de marchand <span class="text-danger">*</span>
                </label>
                <Field  name="typeMarchand" v-model="typeMarchendId" v-slot="{ field }">
                  <Multiselect
                    :options="typeMarchandOpt"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionnez le type de marchand"
                    @select="handleObjetInput"
                  />
                </Field>  
                <ErrorMessage name="typeMarchand" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4" v-if="showAdditionalFields">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Nom <span class="text-danger">*</span>
                </label>
                <Field name="nom" type="text" v-model="nomMarchand"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nom"/>
                <ErrorMessage name="nom" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4" v-if="showAdditionalFields">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Prénom <span class="text-danger">*</span>
                </label>
                <Field name="prenom" type="text" v-model="prenomMarchand"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le prénom"/>
                <ErrorMessage name="prenom" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4" v-if="!showAdditionalFields">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Raison social <span class="text-danger">*</span>
                </label>
                <Field name="raisonSocial" type="text" v-model="raisonSocial"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la raison social"/>
                <ErrorMessage name="raisonSocial" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Téléphone <span class="text-danger">*</span>
                </label>
                <Field name="telephone" v-model="telephone"  v-slot="{ field }">
                  <vue-tel-input
                    placeholder="Entrer le numéro de téléphone"
                    v-model = "field.value"
                    v-bind = "field"
                    defaultCountry="BJ"
                    mode="international"
                    @validate="validate"
                    class ="shadow-none fs-md-15 text-black py-2">
                  </vue-tel-input>
                </Field>
                <div v-if="!validPhone"  class="text-danger">Veuillez entrer un numéro correcte</div>
                <ErrorMessage name="telephone" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Email <span class="text-danger">*</span>
                </label>
                <Field name="email" type="email" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le mail"/>
                <ErrorMessage name="email" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4" v-if="!showAdditionalFields">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  RCCM <span class="text-danger">*</span>
                </label>
                <Field name="rccm" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le rccm"/>
                <ErrorMessage name="rccm" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Ifu <span class="text-danger">*</span>
                </label>
                <Field name="ifu" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'ifu"/>
                <ErrorMessage name="ifu" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Adresse de résidence <span class="text-danger">*</span>
                </label>
                <Field name="adresseResidence" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'adresse"/>
                <ErrorMessage name="adresseResidence" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Adresse de boutique <span class="text-danger">*</span>
                </label>
                <Field name="adresseBoutique" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'adresse"/>
                <ErrorMessage name="adresseBoutique" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Secteur d'activité <span class="text-danger">*</span>
                </label>
                <Field  name="secteurActivite"  v-slot="{ field }">
                  <Multiselect
                    :options="secteurActiviteOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionnez le secteur d'activité"
                  />
                </Field>  
                <ErrorMessage name="secteurActivite" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Code <span class="text-danger">*</span>
                </label>
                <Field name="code" type="text" readonly="true"
                class="form-control fs-md-15 input-readonly" placeholder="Code" v-model="codeMarchand"/>
                <ErrorMessage name="code" class="text-danger"/>
              </div>
            </div>
          <hr>
          <div class="col-md-6" >
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                  Image
                  </label>
                  <Field name="urlImage" type="file" 
                        accept="image/jpeg, image/png application/pdf"
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Choisissez une image"/>
                </div>
                <ErrorMessage name="urlImage" class="text-danger"/>
              </div>
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Categorie du contrat<span class="text-danger">*</span>
              </label>
              <Field name="categories" v-slot="{ field }">
                <Multiselect :options="categoriesOptions" :searchable="true" track-by="label" multiple="true"
                  mode="tags" label="label" v-bind="field" placeholder="Sélectionnez la catégorie" />
              </Field>
              <ErrorMessage name="categories" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Taux <span class="text-danger">*</span>
              </label>
              <Field name="tauxEconomique" type="text"  class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer le taux économique" />
              <ErrorMessage name="tauxEconomique" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                User Max <span class="text-danger">*</span>
              </label>
              <Field name="userMax" type="text"  class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer le user max" />
              <ErrorMessage name="userMax" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Produit Max <span class="text-danger">*</span>
              </label>
              <Field name="produitMax" type="text"  class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer le produit max" />
              <ErrorMessage name="produitMax" class="text-danger" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Information supplémentaires
              </label>
              <div class="mb-0">
                <QuillEditor ref="infosSupplementaireEditor" theme="snow" placeholder="Entrer les information supplémentaires" toolbar="full"
                  name="infosSupplementaire" />
              </div>
              <ErrorMessage name="infosSupplementaire" class="text-danger" />
            </div>
          </div>

          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit" :disabled="!validPhone">
                Ajouter un contrat
              </button>
              <router-link to="/liste-contrats" class=" btn btn-danger transition border-0 lh-1 fw-medium"><i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
        </div>

      </Form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { error, success, generateSlug } from "../../../utils/utils";
import { useRouter } from "vue-router";
import ApiService from "../../../services/ApiService";
import { Contrat } from "../../../models/Contrat";
import * as Yup from "yup";
import Multiselect from '@vueform/multiselect'
import axios from "axios";
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import { countries } from '../../../utils/countries';

export default defineComponent({
  name: "AddContrat",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    VueTelInput
  },
  setup: () => {
    const contratSchema = Yup.object().shape({
      tauxEconomique: Yup.number().typeError("Veuillez entrer des chiffres").required('Le tauxEconomique est obligatoire'),
      infosSupplementaire: Yup.string().notRequired(),
      // marchand: Yup.string().required("Le marchand est obligatoire"),
      //categories: Yup.string().required('La catégorie est obligatoire'),

      raisonSocial: Yup.string().when([], (value, schema) => {
        return !showAdditionalFields.value
          ? schema.required("La raison social est obligatoire")
          : schema.notRequired()
      }),
      rccm: Yup.string().when([], (value, schema) => {
        return !showAdditionalFields.value
          ? schema.required("Le rccm social est obligatoire")
          : schema.notRequired()
      }),
      nom: Yup.string().when([], (value, schema) => {
        return showAdditionalFields.value
          ? schema.required("Le nom est obligatoire")
          : schema.notRequired()
      }),
      prenom: Yup.string().when([], (value, schema) => {
        return showAdditionalFields.value
          ? schema.required("Le prénom est obligatoire")
          : schema.notRequired()
      }),
      telephone : Yup.number().typeError('Veuillez entrer des chiffres').required('Le téléphone est obligatoire'),
      userMax : Yup.number().typeError('Veuillez entrer des chiffres').required('Ce champ est obligatoire'),
      produitMax : Yup.number().typeError('Veuillez entrer des chiffres').required('Ce champ est obligatoire'),
      email: Yup.string().required('L\'email est obligatoire').email("Entrer une adresse email valide"),
      adresseResidence: Yup.string().required('L\'adresse de residence est obligatoire'),
      adresseBoutique: Yup.string().required('L\'adresse boutique est obligatoire'),
      typeMarchand: Yup.string().required('Le type de marchand est obligatoire'),
      ifu: Yup.string()
      // .length(13, 'La saisie doit avoir exactement 13 caractères')
      .matches(/^\d{13}$/, 'La saisie doit avoir exactement 13 chiffres')
      // .transform((value) => value.slice(0, 13))
      .min(13,"La saisie doit avoir exactement 13 chiffres").max(13, "La saisie doit avoir exactement 13 chiffres")
      .required('L\'ifu est obligatoire'),
      secteurActivite: Yup.string().required('Le secteur d\'activité est obligatoire')
    });

    const contratForm = ref<Contrat | null>(null);
    const router = useRouter();
    const categoriesOptions = ref([]);
    const infosSupplementaireEditor = ref();
    const marchandOptions = ref();
    const secteurActiviteOptions= ref([]);
    const codeMarchand = ref('');
    const prenomMarchand = ref('');
    const nomMarchand = ref('');
    const raisonSocial = ref('');

    const typeMarchandOpt = 
      [
      {
          value: "1" ,
          label: "Personne physique"
      },
      {
          value: "2" ,
          label: "Personne morale"
      }
    ];

    //
    const countriesRef = ref(countries)
    const  codepay= ref();
    const  nationalnumlber= ref();
    const  numeroNational = ref();
    const telephone=ref();
    const validPhone=ref<boolean>(false);
    const numberPhone=ref();
    const defaultCountriy = 'Bénin';


    function validate(phoneObject) {
      validPhone.value = phoneObject.valid;
      if (phoneObject.valid == true) {
        telephone.value = phoneObject.number;
        codepay.value= phoneObject.countryCallingCode;
        nationalnumlber.value = phoneObject.nationalNumber;
        numberPhone.value = phoneObject.nationalNumber;
        numeroNational.value = phoneObject.nationalNumber;
      }else{
        telephone.value="";
        codepay.value= "";
        nationalnumlber.value= "";
        numberPhone.value="";
      }
    }

    //

    const typeMarchendId = ref("1");
    const showAdditionalFields = ref(true);

    const handleObjetInput = (selectedValue) => {
      showAdditionalFields.value = selectedValue === "1";
    }

    const fetchSecteursActivites = async () => {
      try {
        const response = await ApiService.get('/secteuractivites?limit=0');
        const secteurActivitesData = response.data.data.data;
        secteurActiviteOptions.value = secteurActivitesData.map((secteurActivite) => ({
          value: secteurActivite.id,
          label: `${secteurActivite.libelle}`,
        }));
      } catch (error) {
        //
      }
    };

    const addContrat = async (values) => {
      values['infosSupplementaire'] = infosSupplementaireEditor.value.getHTML();
      values['codeMarchand'] = codeMarchand.value;
      console.log('offvale',values)
      // ApiService.post("/contrats", values)
      axios.post("/contrats",values, { headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
        .then(({ data }) => {
          if (data.code == 201) {
            success(data.message);
            //resetForm();
            router.push({ name: "ListeContratPage" });
          }
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    };

    const fetchMarchands = async () => {
      try {
        const response = await ApiService.get('/marchands?limit=0&');
        const marchandsData = response.data.data.data;
        marchandOptions.value = marchandsData.map((marchands) => ({
          value: `${marchands.id}`,
          label: `${marchands.nom}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchCatgories = async () => {
      try {
        const response = await ApiService.get('/categories/nopage');
        const marchandsData = response.data.data;
        categoriesOptions.value = marchandsData.map((categories) => ({
          value: categories.id,
          label: `${categories.name}`,
        }));
      } catch (error) {
        //
      }
    };

    function genererCodeMarchand() {
      let charactersArray = 'a-z,A-Z,0-9,#'.split(',');
      let CharacterSet = '';
      let newPassword = '';
      let size = 4;

      if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789';
      }

      if (charactersArray.indexOf('#') >= 0) {
        CharacterSet += '@&#';
      }

      for (let i = 0; i < size; i++) {
        newPassword += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }

      console.log('newPassword', newPassword);
      if (nomMarchand.value && prenomMarchand.value) {
        codeMarchand.value = nomMarchand.value.substr(0, 3) + prenomMarchand.value.substr(0, 3) + newPassword;
      }
      console.log('codeMarchand', codeMarchand.value);
      return codeMarchand;
    }

    function genererCodeMarchandMorale() {
      let charactersArray = 'a-z,A-Z,0-9,#'.split(',');
      let CharacterSet = '';
      let newPassword = '';
      let size = 4;

      if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789';
      }

      if (charactersArray.indexOf('#') >= 0) {
        CharacterSet += '@&#';
      }

      for (let i = 0; i < size; i++) {
        newPassword += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }

      console.log('newPassword', newPassword);
      if (raisonSocial.value) {
        codeMarchand.value = raisonSocial.value.substr(0, 6) + newPassword;
      }
      console.log('codeMarchand', codeMarchand.value);
      return codeMarchand;
    }

    watch([nomMarchand, prenomMarchand], () => {
      console.log('New nomMarchand:', nomMarchand.value);
      console.log('New prenomMarchand:', prenomMarchand.value);
      genererCodeMarchand();
    });

    watch(raisonSocial, () => {
      console.log('New raisonSocial:', raisonSocial.value);
      genererCodeMarchandMorale();
    });


    onMounted(() => {
      fetchCatgories();
      fetchMarchands();
      fetchSecteursActivites();
    });

    return {
      contratSchema,
      addContrat,
      contratForm,
      categoriesOptions,
      infosSupplementaireEditor,
      marchandOptions,typeMarchandOpt,
       secteurActiviteOptions,typeMarchendId,
       handleObjetInput,showAdditionalFields,
       codeMarchand,prenomMarchand,nomMarchand,
       genererCodeMarchand,
       countries: countriesRef, 
        telephone,
        validPhone,
        validate,
        defaultCountriy,
        numeroNational,raisonSocial,
    };
  },
});
</script>
<style scoped>
.dropzone-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: auto;
}
.default-image-container {
  text-align: center;
  margin: 20px 0;
}
.default-image {
  width: 100px;
  height: auto;
}
  .input-readonly {
  background-color: #f0f0f0;
  color: #888888;
  border-color: #cccccc;
}
/* @import 'dropzone-vue/dist/dropzone-vue.common.css'; */
</style>