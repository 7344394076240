<template>
    <BreadCrumb PageTitle="Liste des marchands" />
    <ListeMarchand/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import ListeMarchand from "../../../components/Ecommerce/Marchand/ListeMarchand.vue";

    export default defineComponent({
      name: "ListeMarchandPage",
      components: {
        BreadCrumb,
        ListeMarchand,
      },
    });
  </script>