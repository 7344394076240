<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="contactForm" @submit="editContact" :validation-schema="contactSchema" :initial-values="contactForm">
            <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Email
                </label>
                <Field name="email" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'email"/>
                <ErrorMessage name="email" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Nom
                </label>
                <Field name="nomComplet" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nom complet"/>
                <ErrorMessage name="nomComplet" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Télephone
                </label>
                <Field name="telephone" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le telephone"/>
                <ErrorMessage name="telephone" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Service
                </label>
                <Field  name="service" type="text"  class="form-control shadow-none fs-md-15 text-black" placeholder="Saisir le service du contact"/>
                  <!--<Multiselect
                    :options="serviceOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionnez le service"
                  />-->
                <ErrorMessage name="service" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Message
                  </label>
                  <Field name="message" as="textarea"  cols="30"
                    rows="12" placeholder="Ecrire votre message" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                  <textarea
                  class="form-control shadow-none rounded-0 text-black"
                    v-model="field.value"
                  ></textarea>
                </Field>
                <ErrorMessage name="message" class="text-danger"/>
                </div>
              </div>
            <div class="col-md-12">
              <div class="d-flex align-items-center ">
                <button
                  class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                  type="submit"
                >
                    Modifier un contact
                </button>
                <router-link to="/liste-contacts" 
                    class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                    <span class="position-relative"></span>Annuler</router-link>
              </div>
            </div>
        </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">

import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '../../services/ApiService';
import { error, success } from '../../utils/utils';
import { useRoute, useRouter } from 'vue-router';
import { Contact } from '../../models/Contact';
//import Multiselect from '@vueform/multiselect'

export default defineComponent({
    name: "EditContact",
    components: {
    Form,
    Field,
    ErrorMessage,
    //Multiselect
  },
  setup: () => {
    const contactSchema = Yup.object().shape({
      email: Yup.string().email('Veuillez entrer un email valide').required('L\'email est obligatoire'),
      telephone : Yup.number().typeError('Veuillez entrer des chiffres').required('Le téléphone est obligatoire'),
      // telephone : Yup.number()
      // .typeError('Le téléphone doit être un nombre')
      // .required('Le téléphone est obligatoire')
      // .test(
      //   'is-six-digits',
      //   'Le téléphone doit avoir exactement 8 chiffres',
      //   value => (value ? /^[0-9]{8}$/.test(value.toString()) : true)
      // ),
      nomComplet: Yup.string().required('Le nom est obligatoire'),
      service: Yup.string().required('Le service est obligatoire'),
      message: Yup.string().required('Le message est obligatoire'),
    });

    const contactForm = ref<Contact>();
    //const contactForm = ref();
    const router = useRouter();
    const route = useRoute();

    function getContact(id:number) {
      ApiService.get("/contacts/"+id.toString())
        .then(({ data }) => {
          console.log(data.data)
          for (const key in data.data) {
            contactForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    }

    const CategorieArticles = ref([]);
    const serviceOptions = ref([]);

    const fetchCategorie = async () => {
        try{
            const response = await ApiService.get('/categorieArticles');

            const categorieArticleIsService = response.data.data.data.filter(category => category.isService == "Oui");
            CategorieArticles.value = categorieArticleIsService;
            serviceOptions.value = categorieArticleIsService.map((categorie) => ({
              value: categorie.id,
              label: `${categorie.nom}`,
            }));
        }
        catch(error) {
            // Gérer l'erreur
        }
    }

    const editContact = async (values, {resetForm}) => {
      ApiService.put("/contacts/"+values.id,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeContactPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    };

    onMounted(() => {
      if(route.params.id) {
        getContact(parseInt(route.params.id as string));
      }
      fetchCategorie();
    });

    return { contactSchema, editContact, contactForm,
      serviceOptions, CategorieArticles 
     };
  },
});
</script>