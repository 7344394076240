<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="aproposForm" @submit="editApropos" :validation-schema="aproposSchema" :initial-values="aproposForm">
        <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Image
                </label>
                <Field name="urlImage" type="file" 
                      accept="image/jpeg, image/png application/pdf"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Choisissez une image"/>
                <ErrorMessage name="urlImage" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Valeur
                  </label>
                  <div class="mb-0">
                <QuillEditor
                  theme="snow"
                  ref="valeurEditor"
                  placeholder="Écrivez la valeur"
                  toolbar="full"
                  name="valeur"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="valeur" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Vision
                  </label>
                  <div class="mb-0">
                <QuillEditor
                  theme="snow"
                  ref="visionEditor"
                  placeholder="Écrivez la vision"
                  toolbar="full"
                  name="vision"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="vision" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Mission
                  </label>
                  <div class="mb-0">
                <QuillEditor
                  theme="snow"
                  ref="missionEditor"
                  placeholder="Écrivez la mission"
                  toolbar="full"
                  name="mission"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="mission" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Historique
                  </label>
                  <div class="mb-0">
                <QuillEditor
                  theme="snow"
                  placeholder="Écrivez l'historique"
                  toolbar="full"
                  name="historique"
                  ref="historiqueEditor"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="mission" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Annonce de la directrice
                  </label>
                  <div class="mb-0">
                <QuillEditor
                  theme="snow"
                  placeholder="Écrivez l'annonce de la directrice"
                  toolbar="full"
                  name="annoncedg"
                  ref="annoncedgEditor"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="annoncedg" class="text-danger"/>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Mentions Légales
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="mentionLegaleEditor"
                  theme="snow"
                  placeholder="Écrivez l'annonce de la directrice"
                  toolbar="full"
                  name="mentionLegale"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="mentionLegale" class="text-danger"/>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Données personnelles
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="donnePersonnelleEditor"
                  theme="snow"
                  placeholder="Écrivez l'annonce de la directrice"
                  toolbar="full"
                  name="donnePersonnelle"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="donnePersonnelle" class="text-danger"/>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Cookies
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="cookieEditor"
                  theme="snow"
                  placeholder="Écrivez les cookies"
                  toolbar="full"
                  name="cookie"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="cookie" class="text-danger"/>
                </div>
              </div>

              <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Organigramme
                </label>
                <Field name="image" type="file" 
                      accept="image/jpeg, image/png application/pdf"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Choisissez une image"/>
                <ErrorMessage name="image" class="text-danger"/>
              </div>
            </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit" @click="onSubmit()"
            >
                Modifier
            </button>
            <router-link to="/liste-apropos" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { error, success } from "../../utils/utils";
import { useRoute, useRouter } from "vue-router";
import ApiService from "../../services/ApiService";
import { Apropos } from "../../models/Apropos";
import * as Yup from "yup";
import axios from "axios";

export default defineComponent({
  name: "EditApropos",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup: () => {
    const aproposSchema = Yup.object().shape({
      image: Yup.string().notRequired(),
      urlImage: Yup.mixed().notRequired(),
      vision: Yup.string().notRequired(),
      valeur: Yup.string().notRequired(),
      mission: Yup.string().notRequired(),
      historique: Yup.string().notRequired(),
      organigramme: Yup.string().notRequired(),
    });

    const aproposForm = ref<Apropos | null>(null);
    const router = useRouter();
    const route = useRoute();

    const annoncedgEditor = ref();
    const visionEditor = ref();
    const valeurEditor = ref();
    const missionEditor = ref();
    const historiqueEditor = ref();
    const donnePersonnelleEditor = ref();
    const mentionLegaleEditor = ref();
    const cookieEditor = ref();
    const empty = ref("<p><br></p>")

    const showA = ref(false)
    const show = ref("0")
    const onSubmit = () => {
      if(
        annoncedgEditor.value.getHTML() !== empty.value || visionEditor.value.getHTML() !== empty.value || 
        historiqueEditor.value.getHTML() !== empty.value || missionEditor.value.getHTML() !== empty.value ||
        valeurEditor.value.getHTML() !== empty.value || cookieEditor.value.getHTML() !== empty.value ||
        donnePersonnelleEditor.value.getHTML() !== empty.value || mentionLegaleEditor.value.getHTML() !== empty.value
      ) {
        showA.value = false;
        console.log('hijgrgrkjgmerjm')
      }
      else if(annoncedgEditor.value.getHTML() === empty.value ||
       visionEditor.value.getHTML() === empty.value ||
        historiqueEditor.value.getHTML() === empty.value ||
         missionEditor.value.getHTML() === empty.value ||
          valeurEditor.value.getHTML() === empty.value ||
          cookieEditor.value.getHTML() === empty.value ||
          donnePersonnelleEditor.value.getHTML() === empty.value ||
          mentionLegaleEditor.value.getHTML() === empty.value) {
        showA.value = true;
        show.value = "1"
        console.log('aaaaaaaaaaa')
      }
    }

    function getApropos(id:number) {
      ApiService.get("/apropos/"+id.toString())
        .then(({ data }) => {
          annoncedgEditor.value.setHTML(data.data.annoncedg);
          visionEditor.value.setHTML(data.data.vision);
          missionEditor.value.setHTML(data.data.mission);
          valeurEditor.value.setHTML(data.data.valeur);
          historiqueEditor.value.setHTML(data.data.historique);
          cookieEditor.value.setHTML(data.data.cookie);
          donnePersonnelleEditor.value.setHTML(data.data.donnePersonnelle);
          mentionLegaleEditor.value.setHTML(data.data.mentionLegale);
          for (const key in data.data) {
            aproposForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    } 

    const editApropos = async (values, {resetForm}) => {
      if(values['mission'] !== empty.value &&
       values['vision'] !== empty.value &&
        values['historique'] !== empty.value &&
         values['valeur'] !== empty.value &&
          values['annoncedg'] !== empty.value &&
           values['cookie'] !== empty.value &&
            values['donnePersonnelle'] !== empty.value &&
             values['mentionLegale'] !== empty.value) {
        showA.value = false;
      }else if(values['mission'] === empty.value &&
       values['vision'] === empty.value &&
        values['historique'] === empty.value &&
         values['annoncedg'] === empty.value &&
          values['valeur'] === empty.value &&
           values['cookie'] === empty.value &&
            values['donnePersonnelle'] === empty.value &&
             values['mentionLegale'] === empty.value) {
        showA.value = true;
      }
      
      values['annoncedg'] = annoncedgEditor.value.getHTML();
      values['vision'] = visionEditor.value.getHTML();
      values['mission'] = missionEditor.value.getHTML();
      values['valeur'] = valeurEditor.value.getHTML();
      values['historique'] = historiqueEditor.value.getHTML();
      values['cookie'] = cookieEditor.value.getHTML();
      values['donnePersonnelle'] = donnePersonnelleEditor.value.getHTML();
      values['mentionLegale'] = mentionLegaleEditor.value.getHTML();
      console.log('Donnéeess ', values)
      if(showA.value === false) {
      axios.put("/apropos/"+values.id,values,{ headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeAproposPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    }
    };

    onMounted(() => {
      if(route.params.id) {
        getApropos(parseInt(route.params.id as string));
      }
    });

    return { aproposSchema, aproposForm, editApropos,
      missionEditor,
    visionEditor, historiqueEditor, annoncedgEditor,
    cookieEditor,donnePersonnelleEditor,mentionLegaleEditor, showA, empty,
    onSubmit, show, valeurEditor };
  },
});
</script>
