<template>
  <div class="row">
      <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <router-link to="/liste-faqs" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium" >
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
              
            </div>
            <table class="table">
              <tbody>
                <tr v-if="faq?.question">
                  <td class="bl">Question:</td>
                  <td v-html="faq.question"></td>
                </tr>
                <tr v-if="faq?.reponse">
                  <td class="bl">Réponse:</td>
                  <td v-html="faq.reponse"></td>
                </tr>
                <tr v-if="faq?.categorieFaq">
                  <td class="bl">Catégorie faq:</td>
                  <td>{{ faq.categorieFaq.nom }}</td>
                </tr>
                <tr v-if="faq && faq.faqtags && faq.faqtags.length > 0">
                    <td class="bl">Tag:</td>
                    <td>
                        <!-- <span v-for="faqtag in faq.faqtags" :key="faqtag.id">
                            <span class="fw-bold text-success text-badge d-inline-block">{{ faqtag.tag.nom }}</span>
                        </span> -->


                        <div >
                          <span v-for="faqtag in faq.faqtags" :key="faqtag.id" class="badge-container">
                            <span class="badge badge-outline-danger text-outline-primary">
                              {{ faqtag.tag.nom }}
                              <a href="javascript:void(0);"
                                class="tag-delete-link text-outline-danger"
                                @click="suppression(faqtag.id, faq?.faqtags, 'faqtag', `le tag ${faqtag.tag.nom}`)">
                                <i class="flaticon-delete icon-position"></i>
                              </a>
                            </span>
                          </span>
                        </div>

                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { error, format_date,separateur,format_Date, suppression } from "@/utils/utils";
import { useRoute } from "vue-router";
import { Faq } from "@/models/Faq";
import ApiService from "@/services/ApiService";

export default defineComponent({
  name: "ViewFaq",
  setup: () => {
    const route = useRoute();
    const faq = ref<Faq | null>(null);
    const faqs = ref<Array<Faq>>([]);

    function getFaq(id: string) {
      return ApiService.get("/faqOnlys/"+id)
        .then(({ data }) => {
          faq.value = data.data; 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    onMounted(() => {
      const id = route.params.id as string;
      getFaq(id);
    });

    return { faq, getFaq, format_date,format_Date, faqs,separateur,
      suppression, 
     };
  },
});
</script>

<style>
  .bl{
    font-weight: bold;
  }
</style>