<template>
    <BreadCrumb PageTitle="Liste des categories" />
    <ListeCategorie/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import ListeCategorie from "../../../components/Ecommerce/Categorie/ListeCategorie.vue";

    export default defineComponent({
      name: "ListeCategoriePage",
      components: {
        BreadCrumb,
        ListeCategorie,
      },
    });
  </script>