<template>
    <BreadCrumb PageTitle="Liste des faqs" />
    <ListeFaq/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeFaq from "../../components/Faq/ListeFaq.vue";

    export default defineComponent({
      name: "ListeFaqPage",
      components: {
        BreadCrumb,
        ListeFaq,
      },
    });
  </script>