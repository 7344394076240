<template>
  <BreadCrumb PageTitle="Détails du marchand" />
  <ViewCollection />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ViewCollection from "../../components/Collection/ViewCollection.vue";

  export default defineComponent({
    name: "ViewCollectionPage",
    components: {
      BreadCrumb,
      ViewCollection,
    },
  });
</script>