<template>
  <div class="row">
      <div class="col-md-12 col-xxl-12 col-sm-6 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <router-link to="/ajouter-apropos" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium">
              <i class="flaticon-plus  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Ajouter</router-link>

              <h4 class="position-relative text-black fw-bold mb-10">Informations</h4>
              
              <router-link :to="{ name: 'EditAproposPage', params: { id: 1 } }" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium" >
              <i class="flaticon-edit  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Modifier</router-link>
            </div>
            <table class="table">
              <tbody>
                <tr>
                  <td>Image</td>
                  <td>{{ apropos?.urlImage }}</td>
                </tr>
                <tr>
                  <td>Contenu</td>
                  <td>{{ apropos?.contenu }}</td>
                </tr>
                <tr>
                  <td>Vision</td>
                  <td>{{ apropos?.vision }}</td>
                </tr>
                <tr>
                  <td>Mission</td>
                  <td>{{ apropos?.mission }}</td>
                </tr>
                <tr>
                  <td>Historique</td>
                  <td>{{ apropos?.historique }}</td>
                </tr>
                <tr>
                  <td>Organigramme</td>
                  <td>{{ apropos?.organigramme }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "../../services/ApiService";
import { Apropos } from "../../models/Apropos";
import { error, format_date,separateur,format_Date } from "../../utils/utils";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ViewApropos",
  setup: () => {
    const route = useRoute();
    const apropos = ref<Apropos>();
    const aproposs = ref<Array<Apropos>>([]);

    function getApropos(id: string) {
      return ApiService.get('/apropos')
      .then(({ data }) => {
        apropos.value = data.data;
        console.log('erfgrfg', data)
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    } 

    onMounted(() => {
      const id = route.params.id as string;
      getApropos(id);
    });

    return { apropos, getApropos, format_date,format_Date, aproposs,separateur };
  },
});
</script>
