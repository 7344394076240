<template>
    <BreadCrumb PageTitle="Liste des montants" />
    <ListeMontant/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeMontant from "../../components/Montant/ListeMontant.vue";

    export default defineComponent({
      name: "ListeMontantPage",
      components: {
        BreadCrumb,
        ListeMontant,
      },
    });
  </script>