<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="clientForm" @submit="editClient" :validation-schema="clientSchema">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Nom <span class="text-danger">*</span>
                </label>
                <Field name="nomComplet" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nom"/>
                <ErrorMessage name="nomComplet" class="text-danger"/>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Prénom <span class="text-danger">*</span>
                </label>
                <Field name="prenom" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le prénom"/>
                <ErrorMessage name="prenom" class="text-danger"/>
              </div>
            </div> -->
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Téléphone <span class="text-danger">*</span>
                </label>
                <Field name="telephone" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le telephone"/>
                <ErrorMessage name="telephone" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Email <span class="text-danger">*</span>
                </label>
                <Field name="email" type="email" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le mail"/>
                <ErrorMessage name="mail" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Sexe <span class="text-danger">*</span>
                </label>
                <Field  name="sexe"  v-slot="{ field }">
                  <Multiselect
                    :options="sexeOpt"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionnez le type de marchand"
                  />
                </Field>  
                <ErrorMessage name="sexe" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type de carte <span class="text-danger">*</span>
                </label>
                <Field  name="typeCarte"  v-slot="{ field }">
                  <Multiselect
                    :options="typeCarteOpt"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionnez le type de carte"
                  />
                </Field>  
                <ErrorMessage name="typeCarte" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Numéro de compte <span class="text-danger">*</span>
                </label>
                <Field name="numCompte" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le numéro"/>
                <ErrorMessage name="numCompte" class="text-danger"/>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Date d'expiration <span class="text-danger">*</span>
                </label>
                <Field name="dateExpiration" type="date" 
                  class="form-control shadow-none fs-md-15 text-black"/>
                <ErrorMessage name="dateExpiration" class="text-danger"/>
              </div>
            </div> -->
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Modifier un client
            </button>
            <router-link to="/liste-clients" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">

import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '../../../services/ApiService';
import { error, success } from '../../../utils/utils';
import { useRoute, useRouter } from 'vue-router';
import { Client } from '../../../models/Client';
import Multiselect from '@vueform/multiselect'

export default defineComponent({
    name: "EditClient",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const clientSchema = Yup.object().shape({
      nomComplet: Yup.string().required('Le nom est obligatoire'),
      //prenom: Yup.string().required('Le prénom est obligatoire'),
      telephone : Yup.number().typeError('Veuillez entrer des chiffres').required('Le téléphone est obligatoire'),
      // telephone : Yup.number()
      // .typeError('Le téléphone doit être un nombre')
      // .required('Le téléphone est obligatoire')
      // .test(
      //   'is-six-digits',
      //   'Le téléphone doit avoir exactement 8 chiffres',
      //   value => (value ? /^[0-9]{8}$/.test(value.toString()) : true)
      // ),
      email: Yup.string().email("Entrer une adresse email valide").required('L\'email est obligatoire'),
      sexe: Yup.string().required('Le sexe est obligatoire'),
      numCompte: Yup.number().typeError('Veuillez entrer des chiffres').required("Le numéro de compte est obligatoire"),
      //dateExpiration: Yup.date().notRequired(),
      typeCarte: Yup.string().required("Le numéro de compte est obligatoire"),
    });

    const clientForm = ref<Client>();
    const router = useRouter();
    const route = useRoute();

    const sexeOpt = 
      [
      {
          value: "Masculin" ,
          label: "Masculin"
      },
      {
          value: "Féminin" ,
          label: "Féminin"
      }
    ];

    const typeCarteOpt = 
      [
      {
          value: "MasterCard" ,
          label: "MasterCard"
      },
      {
          value: "Visa" ,
          label: "Visa"
      }
    ];

    function getClient(id:number) {
      ApiService.get("/clients/"+id.toString())
        .then(({ data }) => {
          for (const key in data.data) {
            clientForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    } 

    const editClient = async (values, {resetForm}) => {
      console.log('ffefe', values)
      ApiService.put("/clients/"+values.id,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeClientPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    };

    onMounted(() => {
      if(route.params.id) {
        getClient(parseInt(route.params.id as string));
      }
    });

    return { clientSchema, editClient, clientForm,typeCarteOpt, sexeOpt, };
  },
});
</script>