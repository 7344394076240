<template>
  <div class="mt-50">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <div class="text-center mb-10">
            <img src="../../../assets/images/lapostebj.png" alt="logo-icon" style="height: 100px;"/>
          </div>
          <h4 class="text-black fw-bold mb-0 text-center">Mot de passe oublié?</h4>
          <p class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center">
            Entrez votre adresse e-mail ci-dessous et nous <br/>vous enverrons le mot de passe
          </p>
          <Form ref="forgotPasswordForm" @submit="addForgotPassword" :validation-schema="forgotPasswordSchema">
            <div class="form-group mb-15 mb-sm-20">
              <label class="d-block text-black fw-semibold mb-10">
                Adresse Email
              </label>
              <Field name="email" type="email" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'email"/>
                <ErrorMessage name="email" class="text-danger"/>
            </div>
            <button class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100" type="submit">Envoyer</button>
            <div class="text-center mt-15 mt-sm-20 mt-md-25">
              <router-link to="/login" class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative">
                <i class="ph-bold ph-arrow-left"></i>Revenir à la page de connexion
              </router-link>
            </div>
          </Form>
        </div>
      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref} from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';

export default defineComponent({
    name: "ForgotPassword",
    components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup: () => {
    const forgotPasswordSchema = Yup.object().shape({
      email: Yup.string().email().required('L\'email est obligatoire'),
    });

    const forgotPassordForm = ref(null);

    const addForgotPassword = async (values) => {
      //
    };

    return { forgotPasswordSchema, addForgotPassword, forgotPassordForm };
  },
});
</script>