<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="aproposForm" @submit="addApropos" :validation-schema="aproposSchema">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Image
                </label>
                <Field name="urlImage" type="file"
                      accept="image/jpeg, image/png application/pdf"
                      @change="onFileChange"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Choisissez une image"/>
                <ErrorMessage name="urlImage" class="text-danger"/>
              </div>
            </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Valeur
                  </label>
                  <div class="mb-0">
                <QuillEditor
                  ref="valeurEditor"
                  theme="snow"
                  placeholder="Écrivez la valeur"
                  toolbar="full"
                  name="valeur"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="valeur" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Vision
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="visionEditor"
                  theme="snow"
                  placeholder="Écrivez la vision"
                  toolbar="full"
                  name="vision"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="vision" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Mission
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="missionEditor"
                  theme="snow"
                  placeholder="Écrivez la mission"
                  toolbar="full"
                  name="mission"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="mission" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Historique
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="historiqueEditor"
                  theme="snow"
                  placeholder="Écrivez l'historique"
                  toolbar="full"
                  name="historique"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="historique" class="text-danger"/>
                </div>
              </div>
              
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Annonce de la directrice
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="annoncedgEditor"
                  theme="snow"
                  placeholder="Écrivez l'annonce de la directrice"
                  toolbar="full"
                  name="annoncedg"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="annoncedg" class="text-danger"/>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Mentions Légales
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="mentionLegaleEditor"
                  theme="snow"
                  placeholder="Écrivez l'annonce de la directrice"
                  toolbar="full"
                  name="mentionLegale"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="mentionLegale" class="text-danger"/>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Données personnelles
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="donnePersonnelleEditor"
                  theme="snow"
                  placeholder="Écrivez l'annonce de la directrice"
                  toolbar="full"
                  name="donnePersonnelle"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="donnePersonnelle" class="text-danger"/>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Cookies
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="cookieEditor"
                  theme="snow"
                  placeholder="Écrivez les cookies"
                  toolbar="full"
                  name="cookie"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="cookie" class="text-danger"/>
                </div>
              </div>

              <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Organigramme
                </label>
                <Field name="organigramme" type="file" 
                
                      accept="image/jpeg, image/png application/pdf"
                      @change="onFileChange"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Choisissez une image"/>
                <ErrorMessage name="organigramme" class="text-danger"/>
              </div>
            </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit" @click="onSubmit()"
            >
                Ajouter à propos
            </button>
            <router-link to="/liste-apropos" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { error, success } from '../../utils/utils';
import { QuillEditor } from 'vue-quill-editor';
import { useRouter } from "vue-router";
import ApiService from '../../services/ApiService';
import { Apropos } from '../../models/Apropos';
import * as Yup from 'yup';
import axios from 'axios';

export default defineComponent({
    name: "AddApropos",
    components: {
    Form,
    Field,
    QuillEditor,
    ErrorMessage,
  },
  setup: () => {
    const aproposSchema = Yup.object().shape({
      urlImage: Yup.mixed().notRequired(),
      organigramme: Yup.mixed().notRequired(),//
      vision: Yup.string().notRequired(),//.required('La vision est obligatoire'),
      valeur: Yup.string().notRequired(),//.required('La valeur est obligatoire'),
      mission: Yup.string().notRequired(),//.required('La mission est obligatoire'),
      //organigramme: Yup.string().notRequired(),
      annoncedg:Yup.string().notRequired(),//.required(`L'annonce est obligatoire`),
      historique: Yup.string().notRequired(),//.required('L\'historique est obligatoire'),
    });

    const aproposForm = ref<Apropos | null>(null); 
    const selectedFile = ref<File | null>();
    const selectedFile2 = ref<File | null>();
    const router = useRouter();
    const annoncedgEditor = ref();
    const visionEditor = ref();
    const valeurEditor = ref();
    const missionEditor = ref();
    const historiqueEditor = ref();
    const donnePersonnelleEditor = ref();
    const mentionLegaleEditor = ref();
    const cookieEditor = ref();
    const empty = ref("<p><br></p>")
    const files = ref(new Array(2).fill([]));

    const onFileChange = (e) => {
      if(e.target.files.length > 0){
        selectedFile.value = e.target.files[0];
        if(e.target.files.length > 1 ){
          selectedFile2.value = e.target.files[1];
        }
      }
      
    };
    

    const handleFileUpload = (index) => (event) => {
      const fileList = event.target.files;
      files.value[index] = Array.from(fileList);
    };

    const showA = ref(false)
    const show = ref("0")
    const onSubmit = () => {
      if(annoncedgEditor.value.getHTML() !== empty.value ||
       visionEditor.value.getHTML() !== empty.value ||
        historiqueEditor.value.getHTML() !== empty.value ||
         missionEditor.value.getHTML() !== empty.value ||
          valeurEditor.value.getHTML() !== empty.value ||
          cookieEditor.value.getHTML() !== empty.value ||
          mentionLegaleEditor.value.getHTML() !== empty.value ||
          donnePersonnelleEditor.value.getHTML() !== empty.value) {
        showA.value = false;
        console.log('hijgrgrkjgmerjm')
      }
      else if(annoncedgEditor.value.getHTML() === empty.value ||
       visionEditor.value.getHTML() === empty.value ||
        historiqueEditor.value.getHTML() === empty.value ||
         missionEditor.value.getHTML() === empty.value ||
          valeurEditor.value.getHTML() === empty.value ||
          cookieEditor.value.getHTML() === empty.value ||
          mentionLegaleEditor.value.getHTML() === empty.value ||
          donnePersonnelleEditor.value.getHTML() === empty.value) {
        showA.value = true;
        show.value = "1"
        console.log('aaaaaaaaaaa')
      }
    }

    const addApropos = async (values, {resetForm}) => {
      values['annoncedg'] = annoncedgEditor.value.getHTML();
      values['vision'] = visionEditor.value.getHTML();
      values['valeur'] = valeurEditor.value.getHTML();
      values['mission'] = missionEditor.value.getHTML();
      values['historique'] = historiqueEditor.value.getHTML();
      values['cookie'] = cookieEditor.value.getHTML();
      values['mentionLegale'] = mentionLegaleEditor.value.getHTML();
      values['donnePersonnelle'] = donnePersonnelleEditor.value.getHTML();
      //const formDataa = new FormData();
      var formData = new FormData();
      // Ajouter chaque fichier au FormData
      // files.value.forEach((file, index) => {
      //   formData.append(`file${index}`, file);
      // });
      
      for(let i in values){
        console.log("Value ["+i+"] ==> ",values[i])
       // if(i != 'urlImage' && (values[i] !== undefined ) && i != 'organigramme'){
          formData.append(i, values[i]);
       // }  
      }
      // formData.append('folderName', 'fichiers');
      console.log("Fichiers ", selectedFile.value);

      // if (selectedFile.value) {
      //   formData.append('urlImage', selectedFile.value);
      // }
      // if(selectedFile2.value){
      //   formData.append('organigramme', selectedFile2.value);
      // }

      if(values['mission'] !== empty.value &&
       values['vision'] !== empty.value &&
        values['valeur'] !== empty.value &&
         values['historique'] !== empty.value &&
          values['annoncedg'] !== empty.value &&
           values['cookie'] !== empty.value &&
            values['mentionLegale'] !== empty.value &&
             values['donnePersonnelle'] !== empty.value) {
        showA.value = false;
      }else if(values['mission'] === empty.value &&
       values['vision'] === empty.value &&
        values['historique'] === empty.value &&
         values['valeur'] === empty.value &&
          values['annoncedg'] === empty.value &&
           values['cookie'] === empty.value &&
            values['mentionLegale'] === empty.value &&
             values['donnePersonnelle'] === empty.value) {
        showA.value = true;
      }
      console.log("Forme data   ",formData);
      if(showA.value === false) {
        axios.post("/apropos",values, { headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
          .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              resetForm();
              router.push({ name: "ListeAproposPage" });
            }
          }).catch(({ response }) => {
            error(response.data.message);
          });
      }
    };

    return { 
      aproposSchema, 
      addApropos, 
      aproposForm, 
      missionEditor, 
      onFileChange, 
      selectedFile, 
      visionEditor, 
      historiqueEditor, 
      valeurEditor,
      cookieEditor,
      mentionLegaleEditor,
      donnePersonnelleEditor,
      handleFileUpload,
      files,
      showA, 
      empty, 
      annoncedgEditor,
      show, onSubmit,

  };
  },
});
</script>