<template>
  <div class="row">
      <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <h4 class="position-relative text-black fw-bold mb-10">Autres informations</h4>
              <router-link to="/liste-collections" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium">
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
            </div>
            <table class="table">
              <tbody>
                <tr >
                  <td class="bl">Nom:</td>
                  <td>{{ collection?.name }}</td>
                </tr>
                <tr >
                  <td class="bl">Slug:</td>
                  <td>{{ collection?.slug }}</td>
                </tr>
                <tr >
                  <td class="bl">Collection:</td>
                  <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  <div class="col-12">
                      <span v-for="produit in collection?.products" :key="produit.id" class="badge-container">
                        <div class="col-6">
                        <span class="badge badge-outline-danger text-outline-primary">
                          {{ produit.title }}
                          <a href="javascript:void(0);"
                            class="tag-delete-link text-outline-danger"
                            @click="suppCat(produit.id,collection.id)">
                            <i class="flaticon-delete icon-position"></i>
                          </a>
                        </span>
                      </div>
                      </span>
                    </div>
                </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

  </div>

</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "../../services/ApiService";
import { Collection } from "../../models/Collection";
import { error, format_Date, showModal, suppression, getUrlApiForProductFiles } from "../../utils/utils";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default defineComponent({
  name: "ViewCollection",
  setup: () => {
    const route = useRoute();
    const collection = ref<Collection | null>(null);

    function getCollection(id: string) {
      return ApiService.get("/collections", id)
        .then(({ data }) => {
          collection.value = data.data; 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    const collections = ref<Array<Collection>>([]);   

    onMounted(() => {
      const id = route.params.id as string;
      getCollection(id);
    });

    const suppCat = (produitId, collectionId) => {
    //   Swal.fire({
    //   text: "Vous êtes sur le point de faire une suppression",
    //   icon: "warning",
    //   buttonsStyling: true,
    //   showCancelButton: true,
    //   confirmButtonText: "Supprimer",
    //   cancelButtonText: `Annuler`,
    //   heightAuto: false,
    //   customClass: {
    //     confirmButton: "btn btn-danger",
    //   },
    // }).then((result) => {
    //   ApiService.delete(`/collectionproduit/${collectionId}/${produitId}`)
    //     .then(({ data }) => {
    //         Swal.fire({
    //           title: 'Succès',
    //           text: data.message,
    //           icon: "success",
    //           toast: true,
    //           timer: 5000,
    //           position: 'top-right',
    //           showConfirmButton: false,
    //         });
    //         getAllCollections();
    //     }).catch(({ response }) => {
    //       Swal.fire({
    //         text: response.data.message, 
    //         icon: "error",
    //         buttonsStyling: false,
    //         confirmButtonText: "Réssayer à nouveau!",
    //         heightAuto: false,
    //         customClass: {
    //           confirmButton: "btn fw-semobold btn-light-danger",
    //         },
    //       });
    //   });
    //   });

    Swal.fire({
        text: "Vous êtes sur le point de faire une suppression",
        icon: "warning",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Supprimer",
        cancelButtonText: `Annuler`,
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('Suppression annulée');
        } else if (result.isConfirmed) {
          ApiService.delete(`/collectionproduit/${collectionId}/${produitId}`)
            .then(({ data }) => {
              Swal.fire({
                title: 'Succès',
                text: data.message,
                icon: "success",
                toast: true,
                timer: 5000,
                position: 'top-right',
                showConfirmButton: false,
              });
              getCollection(collectionId);
            }).catch(({ response }) => {
              Swal.fire({
                text: response.data.message, 
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Réessayer à nouveau!",
                heightAuto: false,
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            });
        }
      }).catch((error) => {
        console.error('Erreur lors de l\'affichage de la boîte de dialogue', error);
      });
    }


    return { 
      collection,
      getCollection,
      format_Date,
      suppression,
      getUrlApiForProductFiles,
      suppCat,collections
     };
  },
});
</script>

<style>
  .bl{
    font-weight: bold;
  }
</style>