<template>
  <BreadCrumb PageTitle="Détails" />
  <ViewAlaUne />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ViewAlaUne from "../../components/AlaUne/ViewAlaUne.vue";

  export default defineComponent({
    name: "ViewAlaUnePage",
    components: {
      BreadCrumb,
      ViewAlaUne,
    },
  });
</script>