<template>
    <BreadCrumb PageTitle="Modifier une catégorie d'article" />
    <EditCategorieArticle />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EditCategorieArticle from "../../components/CategorieArticle/EditCategorieArticle.vue";
  
    export default defineComponent({
      name: "EditCategorieArticlePage",
      components: {
        BreadCrumb,
        EditCategorieArticle,
      },
    });
  </script>