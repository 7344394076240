<template>
    <BreadCrumb PageTitle="Liste des modeles" />
    <ListeModele/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import ListeModele from "../../../components/Ecommerce/Modele/ListeModele.vue";

    export default defineComponent({
      name: "ListeModelePage",
      components: {
        BreadCrumb,
        ListeModele,
      },
    });
  </script>