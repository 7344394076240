<template>
    <BreadCrumb PageTitle="Modifier un client" />
    <EditClient />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import EditClient from "../../../components/Ecommerce/Client/EditClient.vue";
  
    export default defineComponent({
      name: "EditClientPage",
      components: {
        BreadCrumb,
        EditClient,
      },
    });
  </script>