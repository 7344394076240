<template>
    <BreadCrumb PageTitle="Liste des moyens de paiements" />
    <ListeMoyenPaiement/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import ListeMoyenPaiement from "../../../components/Ecommerce/MoyenPaiement/ListeMoyenPaiement.vue";

    export default defineComponent({
      name: "ListeMoyenPaiementPage",
      components: {
        BreadCrumb,
        ListeMoyenPaiement,
      },
    });
  </script>