<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-infoFooter"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Ajouter une information
        </router-link>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
       <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher un information"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr >
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Politique générales
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Expédition et livraison
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Politique de retour
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Contact
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Qui sommes nous ?
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Statut
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text pe-0"
              >ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(infoFooter, index) in infoFooters" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <div v-html="infoFooter.politiqueCondition.slice(0, 20) + '...'" ></div>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <div v-html="infoFooter.expeLivraison.slice(0, 20) + '...'" ></div>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <div v-html="infoFooter.politiqueRR.slice(0, 20) + '...'" ></div>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <div v-html="infoFooter.quiSommeNous.slice(0, 20) + '...'" ></div>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <div v-html="infoFooter.contact.slice(0, 20) + '...'" ></div>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <span class="fw-bold text-success text-badge d-inline-block">{{ infoFooter.statut ? "Actif" : "Inactif" }}</span>
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text pe-0"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li v-if="!infoFooter.statut">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#ValidInfoFooterModal"
                        @click="openValidInfoFooterModal(infoFooter)"
                      >
                      <i class="flaticon-check lh-1 me-8 position-relative top-1"></i>
                        Activer
                      </a>
                    </li>
                    <li >
                      <router-link :to="{ name: 'EditInfoFooterPage', params: { id: infoFooter.id } }" 
                          class="dropdown-item d-flex align-items-center"><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>Modifier</router-link>
                    </li>
                    <li >
                        <router-link :to="{ name: 'ViewInfoFooterPage', params: { id: infoFooter.id } }" class="dropdown-item d-flex align-items-center">
                            <i class="flaticon-eye lh-1 me-8 position-relative top-1"></i>Détails
                        </router-link>
                    </li>
                    <li >
                      <a
                        class="dropdown-item d-flex align-items-center" href="javascript:void(0);" @click="suppression(infoFooter.id,infoFooters,'infoFooter',`l\'infoFooter ${infoFooter.id}`)">
                        <i class="flaticon-delete lh-1 me-8 position-relative top-1" ></i>
                         Supprimer
                      </a>
                    </li>
                    <!-- <li v-if="!infoFooter.statut && checkPerpolitiqueCondition('ActiveInfoFooter')"> -->
                    
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>


  <div
    class="modal fade createNewModal"
    id="ValidInfoFooterModal"
    tabindex="-1"
    ref="addValidInfoFooterModalRef"
    aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-15 p-md-40" style="width:550px;">
          <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
            <h5 class="modal-title fw-bold text-black">
          </h5>
          </div>

          <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
            <div class="text-center">
            <h5>Vous êtes sur le point d'activer cet infoFooter, ce qui désactivera celui actif.</h5>
            <!-- <h5>{{ selectedInfoFooter?.contenu }}</h5> -->
            <br>
              <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-primary"
              type="submit"
              @click="validInfoFooter()"
              >
                  Activer
              </button>
              <router-link to="#" 
              data-bs-dismiss="modal"
                  class="btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                  <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref} from "vue";
import Swal from "sweetalert2";
import { InfoFooter } from "../../models/InfoFooter";
import ApiService from "../../services/ApiService";
import { format_date,suppression,format_Date, hideModal, showModal,separateur, error,success } from "../../utils/utils";
import PaginationComponent from '../../components/Utilities/Pagination.vue';
import JwtService from "../../services/JwtService";

export default defineComponent({
  name: "ListeInfoFooter",
  components: {
    PaginationComponent
  },
  setup(){
    
    onMounted(() => {
      getAllInfoFooter();
    });

    const infoFooters = ref<Array<InfoFooter>>([]);   
    const infoFooter = ref<InfoFooter>();

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllInfoFooter(page_, limit_);
      } catch (error) {
        //
      }
    };

     function rechercher(){
      getAllInfoFooter(page.value, limit.value, searchTerm.value );
    }

    // END PAGINATE

    function getAllInfoFooter(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/infoFooter?page=${page}&limit=${limi}&mot=${searchTerm}&`)
        .then(({ data }) => {
          infoFooters.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
          return data.data;
        })
        .catch(({ response }) => {
          error(response.data.message)
      });
    }
    
    function moddifier(EditinfoFooter:InfoFooter) {
      infoFooter.value = EditinfoFooter;
    }

    const deleteinfoFooter = (id: number) => {
      ApiService.delete(`/infoFooter/${id}`)
      .then(({ data }) => {
        Swal.fire({
          text: data.message,
          toast: true,
          icon: 'success',
          title: 'General Title',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          heightAuto: false
        });
      })
      .catch(({ response }) => {
        Swal.fire({
          text: response.data.message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Réssayer à nouveau!",
          heightAuto: false,
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
      });

      for(let i = 0; i < infoFooters.value.length; i++) {
        if (infoFooters.value[i].id === id) {
          infoFooters.value.splice(i, 1);
        }
      }
    };

    const addValidInfoFooterModalRef = ref<null | HTMLElement>(null);

    const validInfoFooter = () => {
    const dataToSend = {
      id : selectedInfoFooter?.value?.id.toString()      };
      ApiService.put("/active/infoFooter/"+ selectedInfoFooter?.value?.id.toString(), dataToSend)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            hideModal(addValidInfoFooterModalRef.value);
            getAllInfoFooter();
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

    const selectedInfoFooter = ref<InfoFooter | undefined>(undefined);

    const openValidInfoFooterModal = (infoFooter: InfoFooter) => {
      selectedInfoFooter.value = { ...infoFooter };
    };

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPerpolitiqueCondition = (name) => {
      return privileges.value.includes(name);
    }

    return { infoFooters,
      checkPerpolitiqueCondition,
     getAllInfoFooter,
     deleteinfoFooter,
     moddifier ,
     suppression,
     page, 
    totalPages,
    limit,
    totalElements,
    handlePaginate,
    validInfoFooter,
    rechercher,
    searchTerm,
    selectedInfoFooter,
    openValidInfoFooterModal,
    addValidInfoFooterModalRef
  };
  },
});
</script>