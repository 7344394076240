<template>
    <BreadCrumb PageTitle="Liste des distances" />
    <ListeDistance/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeDistance from "../../components/Distance/ListeDistance.vue";

    export default defineComponent({
      name: "ListeDistancePage",
      components: {
        BreadCrumb,
        ListeDistance,
      },
    });
  </script>