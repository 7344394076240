<template>
    <BreadCrumb PageTitle="Liste des médias" />
    <ListeMedia/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeMedia from "../../components/Media/ListeMedia.vue";

    export default defineComponent({
      name: "ListeMediaPage",
      components: {
        BreadCrumb,
        ListeMedia,
      },
    });
  </script>