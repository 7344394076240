<template>
  <BreadCrumb PageTitle="Détails des faqs" />
  <ViewFaq />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ViewFaq from "../../components/Faq/ViewFaq.vue";

  export default defineComponent({
    name: "ViewFaqPage",
    components: {
      BreadCrumb,
      ViewFaq,
    },
  });
</script>