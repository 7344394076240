<template>
    <BreadCrumb PageTitle="Modifier un secteur d'activité" />
    <EditSecteurActivite />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import EditSecteurActivite from "../../../components/Ecommerce/SecteurActivite/EditSecteurActivite.vue";
  
    export default defineComponent({
      name: "EditSecteurActivitePage",
      components: {
        BreadCrumb,
        EditSecteurActivite,
      },
    });
  </script>