<template>
  <div class="row">
      <div class="col-md-12 col-xxl-12 col-sm-6 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <!-- <router-link to="/ajouter-infoFooter" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium">
              <i class="flaticon-plus  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Ajouter</router-link> -->

              <h4 class="position-relative text-black fw-bold mb-10">Informations</h4>
              
              <!-- <router-link :to="{ name: 'EditInfoFooterPage', params: { id: 1 } }" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium" >
              <i class="flaticon-edit  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Modifier</router-link> -->
            </div>
            <table class="table">
              <tbody>
                <!-- <tr>
                  <td class="bl">Image:</td>
                  <td><img :src="getUrlApiForFiles(infoFooter?.urlImage)" alt="Image"></td>
                </tr> -->
                <tr>
                  <td class="bl">Expédition et livraison:</td>
                  <td v-html="infoFooter?.expeLivraison"></td>
                </tr>
                <tr>
                  <td class="bl">Politique et condition:</td>
                  <td v-html="infoFooter?.politiqueCondition"></td>
                </tr>
                <tr>
                  <td class="bl">Politique de retouret de remboursement:</td>
                  <td v-html="infoFooter?.politiqueRREditor"></td>
                </tr>
                <tr>
                  <td class="bl">Qui sommes nous ?:</td>
                  <td>{{ infoFooter?.quiSommeNous }}</td>
                </tr>
                <tr>
                  <td class="bl">Contact:</td>
                  <td v-html="infoFooter?.contact"></td>
                </tr>
                <!-- <tr>
                  <td class="bl">Mention legale:</td>
                  <td v-html="infoFooter?.mentionLegale"></td>
                </tr>
                <tr>
                  <td class="bl">Donnée personnelle:</td>
                  <td v-html="infoFooter?.donnePersonnelle"></td>
                </tr>
                <tr>
                  <td class="bl">Cookie:</td>
                  <td v-html="infoFooter?.cookie"></td>
                </tr> -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "../../services/ApiService";
import { InfoFooter } from "../../models/InfoFooter";
import { error, format_date,separateur,format_Date, getUrlApiForFiles } from "../../utils/utils";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ViewInfoFooter",
  setup: () => {
    const route = useRoute();
    const infoFooter = ref<InfoFooter>();
    const infoFooters = ref<Array<InfoFooter>>([]);

    function getInfoFooter(id: string) {
      return ApiService.get("/infoFooter/"+id)
      .then(({ data }) => {
        infoFooter.value = data.data;
        console.log('erfgrfg', data.data)  
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    } 

    onMounted(() => {
      const id = route.params.id as string;
      getInfoFooter(id);
    });

    return { infoFooter, getInfoFooter, format_date,
      format_Date, infoFooters,separateur,
      getUrlApiForFiles, };
  },
});
</script>

<style>
  .bl{
    font-weight: bold;
  }
</style>