<template>
    <BreadCrumb PageTitle="Ajouter un article" />
    <AddArticle />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddArticle from "../../components/Article/AddArticle.vue";
  
    export default defineComponent({
      name: "AddArticlePage",
      components: {
        BreadCrumb,
        AddArticle,
      },
    });
  </script>