<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div
        class="d-sm-flex align-items-center"
        v-if="checkPermission('AddMarque')"
      >
        <a
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#AddMarqueModal"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Ajouter une marque
        </a>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
        <form
          class="search-box position-relative me-15"
          @submit.prevent="rechercher"
        >
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher une marque"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Nom
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Slug
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Description
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Categorie
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(marque, index) in marques" :key="index">
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ marque.name }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ marque.slug }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ marque.description }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <!-- <div >
                    <span v-for="categorie in marque.categories" :key="categorie.id" class="badge-container">
                      <span class="badge badge-outline-danger text-outline-success">
                        {{ categorie?.nomCategorie }}
                        <a href="javascript:void(0);"
                          class="tag-delete-link text-outline-danger"
                          @click="suppression(categorie.id, marque.categories, 'marquecategorie', `la catégorie ${categorie?.nomCategorie}`)">
                          <i class="flaticon-delete icon-position"></i>
                        </a>
                      </span>
                    </span>
                  </div> -->

                <div class="col-12">
                  <span
                    v-for="marquecategorie in marque.marquecategories"
                    :key="marquecategorie.id"
                    class="badge-container"
                  >
                    <div class="col-6">
                      <span
                        class="badge badge-outline-danger text-outline-primary"
                      >
                        {{ marquecategorie.categorie.name }}
                        <a
                          href="javascript:void(0);"
                          class="tag-delete-link text-outline-danger"
                          @click="
                            suppression(
                              marquecategorie.id,
                              marque?.marquecategories,
                              'marquecategorie',
                              `la catégorie ${marquecategorie.categorie.name}`
                            )
                          "
                        >
                          <i class="flaticon-delete icon-position"></i>
                        </a>
                      </span>
                    </div>
                  </span>
                </div>
                <!-- <span v-for="categorie in marque.categories" :key="categorie">
                <span class="fw-bold text-success text-badge d-inline-block">{{ categorie?.nomCategorie }} </span>
                </span> -->
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
                <div class="dropdown">
                  <span
                    class="badge text-white bg-primary fs-15 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Actions
                    <i
                      class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"
                    ></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li v-if="checkPermission('EditMarque')">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#AddMarqueModal"
                        @click="moddifier(marque)"
                      >
                        <i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        Modifier
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#AddNewCategorieModal"
                        @click="openAddNewCategorieModal(marque)"
                      >
                        <i
                          class="flaticon-plus lh-1 me-8 position-relative top-1"
                        ></i>
                        Ajouter de (s) catégorie (s)
                      </a>
                    </li>
                    <li v-if="checkPermission('DeleteMarque')">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="
                          suppression(marque.id, marques, 'marques', 'marque')
                        "
                      >
                        <i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        Supprimer
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent
          :page="page"
          :totalPages="totalPages"
          :totalElements="totalElements"
          :limit="limit"
          @paginate="handlePaginate"
        />
      </div>
    </div>
  </div>
  <AddMarqueModal :item="selectedItem" @close="recharger" />
  <AddNewCategorieModal
    :selectedMarque="selectedMarque"
    :selectedMarqueId="selectedMarqueId"
  />
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch, computed } from "vue";
import AddMarqueModal from "./AddMarqueModal.vue";
import ApiService from "../../../services/ApiService";
import { format_date, suppression, error } from "../../../utils/utils";
import { Marque } from "../../../models/Marque";
import axios from "axios";
import Swal from "sweetalert2";
import Paginate from "vuejs-paginate";
import PaginationComponent from "../../../components/Utilities/Pagination.vue";
import JwtService from "../../../services/JwtService";
import AddNewCategorieModal from "./AddNewCategorieModal.vue";

export default defineComponent({
  name: "ListeMarque",
  components: {
    AddMarqueModal,
    PaginationComponent,
    AddNewCategorieModal,
  },
  setup: () => {
    const modules = {};

    const marques = ref<Array<Marque>>([]);
    const marque = ref<Marque>();
    const addMarqueModalRef = ref<null | HTMLElement>(null);
    const selectedItem = ref(0);

    // BEGIN PAGINATE
    const searchTerm = ref("");
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllMarque(page_, limit_);
      } catch (error) {
        //
      }
    };

    function rechercher() {
      getAllMarque(page.value, limit.value, searchTerm.value);
    }

    // END PAGINATE

    function getAllMarque(page = 1, limi = 10, searchTerm = "") {
      return ApiService.get(
        `marques?page=${page}&limit=${limi}&mot=${searchTerm}&`
      )
        .then(({ data }) => {
          marques.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    }

    onMounted(() => {
      getAllMarque();
    });

    const selectedMarque = ref<Marque | undefined>(undefined);
    const selectedMarqueId = ref<number>();

    const openAddNewCategorieModal = (marque: Marque) => {
      selectedMarque.value = { ...marque };
      selectedMarqueId.value = marque.id;
    };

    function moddifier(EditMarque: Marque) {
      marque.value = EditMarque;
      selectedItem.value = EditMarque.id;
    }
    const recharger = () => {
      getAllMarque();
    };

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    };

    return {
      modules,
      checkPermission,
      selectedItem,
      marque,
      marques,
      recharger,
      suppression,
      moddifier,
      page,
      totalPages,
      limit,
      totalElements,
      getAllMarque,
      handlePaginate,
      searchTerm,
      rechercher,
      addMarqueModalRef,
      openAddNewCategorieModal,
      selectedMarque,
      selectedMarqueId,
    };
  },
});
</script>
@/models/Marque