import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-4" }
const _hoisted_3 = { class: "col-xxl-8" }
const _hoisted_4 = { class: "col-xxl-4" }
const _hoisted_5 = { class: "col-xxl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhatHappening = _resolveComponent("WhatHappening")!
  const _component_FundamentalCourses = _resolveComponent("FundamentalCourses")!
  const _component_TodaysEvent = _resolveComponent("TodaysEvent")!
  const _component_CategoriesCourses = _resolveComponent("CategoriesCourses")!
  const _component_CoursesClass = _resolveComponent("CoursesClass")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_WhatHappening),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FundamentalCourses)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_TodaysEvent)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_CategoriesCourses)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_CoursesClass)
      ])
    ])
  ], 64))
}