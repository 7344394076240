<template>
  <div
    class="modal fade createNewModal"
    id="AffectationLivreurModal"
    tabindex="-1"
    ref="addAffectationLivreurModalRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black"></h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <h5>
            Vous voulez associez un livreur pour la livraison de la commande:
            {{ selectedCommande?.refCommande }} à l'adresse :
            {{ selectedCommande?.adresseLivraison?.adresse }}
          </h5>
          <Form
            ref="livreurForm"
            @submit="addLivreur"
            :validation-schema="livreurSchema"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Zone d'intervention <span class="text-danger">*</span>
                  </label>
                  <Field name="communes" v-slot="{ field }">
                    <Multiselect
                      :options="communesOptions"
                      :searchable="true"
                      track-by="label"
                      label="label"
                      v-model="field.value"
                      v-bind="field"
                      placeholder="Sélectionnez la zone"
                      @change="zoneChange($event)"
                    />
                  </Field>
                  <ErrorMessage name="communes" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Livreur <span class="text-danger">*</span>
                  </label>
                  <Field name="livreur" v-slot="{ field }">
                    <Multiselect
                      :options="livreursOptions"
                      :searchable="true"
                      track-by="label"
                      label="label"
                      v-model="selectedLivreur"
                      v-bind="field"
                      noOptionsText="Sélectionner d'abord une zone"
                      placeholder="Sélectionner le livreur"
                    />
                  </Field>
                </div>
                <ErrorMessage name="livreur" class="text-danger" />
              </div>
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="submit"
              >
                Envoyer
              </button>
            </div>
          </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, watch } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/services/ApiService";
import { error, hideModal, success } from "@/utils/utils";
import { useRouter, useRoute } from "vue-router";
import Multiselect from "@vueform/multiselect";
import { Commande } from "@/models/Commande";
import axios from "axios";
import JwtService from "@/services/JwtService";
import { User } from "@/models/users";

export default {
  name: "AffectationLivreurModal",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    selectedCommande: Object as () => Commande | undefined,
  },
  setup: (props, { emit }) => {
    const livreurSchema = Yup.object().shape({
      livreur: Yup.number().required("La livreur est obligatoire"),
    });

    const livreurForm = ref<Commande | null>(null);
    const addAffectationLivreurModalRef = ref<null | HTMLElement>(null);
    const route = useRoute();
    const poids = ref();
    const livreursOptions = ref([]);
    const userId = JwtService.getUser()!;
    // const estLivreur = JwtService.getIfMarchand(false);
    const estLivreur = 1;
    const user = ref<User>();
    const communesOptions = ref([]);
    const selectedLivreur = ref([]);

    const selectedCommande = ref<Commande | undefined>(undefined);
    const selectedCommandeId = ref<number>();

    const addLivreur = async (values, { resetForm }) => {
      const livreurData = {
        commande: selectedCommande.value?.id,
        livreur: values.livreur,
      };
      console.log("livreurData ===> ", livreurData);
      console.log("rfjfl", livreurData);
      ApiService.post("/commande/livreur/", livreurData)
        .then(({ data }) => {
          if (data.code == 200) {
            success(data.message);
            resetForm();
            hideModal(addAffectationLivreurModalRef.value);
            emit("close");
            emit("getAllCommandes", data.data);
          }
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    };

    watch(
      () => props.selectedCommande,
      (newValue) => {
        selectedCommande.value = newValue;
        if (newValue) {
          selectedCommandeId.value = newValue.id;
          // fetchLivreurs();
        }
      }
    );

    const fetchCommune = async () => {
      ApiService.get("/communes")
        .then(({ data }) => {
          const donneee = data.data.data;
          communesOptions.value = donneee.map((commune: any) => {
            return {
              label: commune.libelle,
              value: commune.id,
            };
          });
        })
        .catch(({ response }) => {
          //error(response.data.message);
        });
    };

    function zoneChange(value) {
      if (value) {
        livreursOptions.value = [];
        selectedLivreur.value = [];
        ApiService.get("/zone/livreurs/" + value)
          .then(({ data }) => {
            const donnee = data.data;
            console.log("kefkj", donnee);
            if (donnee.length > 0) {
              livreursOptions.value = donnee.map((user: any) => {
                return {
                  label: user.nomComplet,
                  value: user.id,
                };
              });
            }
          })
          .catch(({ response }) => {
            //error(response.data.message);
          });
      } else {
        livreursOptions.value = [];
        selectedLivreur.value = [];
      }
    }

    onMounted(() => {
      fetchCommune();
      // if(route.params.id) {
      //   getCommande(parseInt(route.params.id as string));
      // }
    });

    return {
      livreurSchema,
      addLivreur,
      livreurForm,
      addAffectationLivreurModalRef,
      livreursOptions,
      selectedCommandeId,
      userId,
      estLivreur,
      user,
      communesOptions,
      zoneChange,
      selectedLivreur,
    };
  },
};
</script>