<template>
    <BreadCrumb PageTitle="Ajouter un contrat" />
    <AddContrat />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import AddContrat from "../../../components/Ecommerce/Contrat/AddContrat.vue";
  
    export default defineComponent({
      name: "AddContratPage",
      components: {
        BreadCrumb,
        AddContrat,
      },
    });
  </script>