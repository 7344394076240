<template>
    <BreadCrumb PageTitle="Modifier un faq" />
    <EditFaq />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EditFaq from "../../components/Faq/EditFaq.vue";
  
    export default defineComponent({
      name: "EditFaqPage",
      components: {
        BreadCrumb,
        EditFaq,
      },
    });
  </script>