<template>
  <BreadCrumb PageTitle="Détails de l'article" />
  <ViewArticle />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ViewArticle from "../../components/Article/ViewArticle.vue";

  export default defineComponent({
    name: "ViewArticlePage",
    components: {
      BreadCrumb,
      ViewArticle,
    },
  });
</script>