import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "title position-relative mb-8 mb-md-10" }
const _hoisted_4 = { class: "mb-0 text-black fw-bold" }
const _hoisted_5 = { class: "fs-md-15 fs-lg-16 text-black-emphasis mb-15 fw-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-lg-12",
        key: course.id
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([
          'card mb-25 border-0 rounded-0 letter-spacing courses-list-box',
          course.bgClass,
        ])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", {
                class: _normalizeClass(['badge bg-success position-relative rounded-circle', course.bgClassImage, ])
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass([course.image,'fs-2'])
                }, null, 2)
              ], 2),
              _createElementVNode("h6", _hoisted_4, _toDisplayString(course.title), 1)
            ]),
            _createElementVNode("p", _hoisted_5, _toDisplayString(course.shortDesc), 1),
            _createVNode(_component_router_link, {
              to: "",
              class: "default-btn position-relative transition border-0 fw-medium text-white pt-8 pb-8 ps-20 pe-20 pt-md-8 pb-md-8 ps-md-25 pe-md-25 rounded-0 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(course.lessons), 1)
              ]),
              _: 2
            }, 1024)
          ])
        ], 2)
      ]))
    }), 128))
  ]))
}