<template>
    <BreadCrumb PageTitle="Liste des commandes" />
    <ListeCommande />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import ListeCommande from "../../../components/Ecommerce/Commande/ListeCommande.vue";
  
    export default defineComponent({
      name: "ListeCommandePage",
      components: {
        BreadCrumb,
        ListeCommande,
      },
    });
  </script>