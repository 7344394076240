<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <a 
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#AddCollectionModal"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Ajouter une collection
        </a>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
        
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher une collection"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Nom 
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Slug 
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Produit (s) 
              </th> -->
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 pe-0"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(collection, index) in collections" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ collection.name }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ collection.slug }}
              </td>
              <!-- <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  <div class="col-12">
                      <span v-for="produit in collection.products" :key="produit.id" class="badge-container">
                        <div class="col-6">
                        <span class="badge badge-outline-danger text-outline-primary">
                          {{ produit.title }}
                          <a href="javascript:void(0);"
                            class="tag-delete-link text-outline-danger"
                            @click="suppCat(produit.id,collection.id)">
                            <i class="flaticon-delete icon-position"></i>
                          </a>
                        </span>
                      </div>
                      </span>
                    </div>
                </td> -->
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary pe-0"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#AddCollectionModal"
                        @click="moddifier(collection)"
                      >
                        <i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        Modifier
                      </a>
                    </li>
                    <li >
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#AddNewProduitModal"
                        @click="openAddNewProduitModal(collection)"
                      >
                      <i class="flaticon-plus lh-1 me-8 position-relative top-1"></i>
                        Ajouter de (s) produit (s)
                      </a>
                    </li>
                    <li >
                      <router-link :to="{ name: 'ViewCollectionPage', params: { id: collection.id } }" 
                          class="dropdown-item d-flex align-items-center"><i
                          class="flaticon-eye lh-1 me-8 position-relative top-1"
                        ></i>Détails</router-link>
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);" @click="suppression(collection.id,collections,'collections',`la collection ${collection.name}`)"
                      >
                        <i
                          class="flaticon-delete lh-1 me-8 position-relative top-1" 
                        ></i>
                        Supprimer
                      </a>
                    </li>
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
      
      <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
        
      </div>
    </div>
  </div>
  <AddCollectionModal :item="selectedItem"   @close="recharger"/>
<AddNewProduitModal :selectedCollection="selectedCollection" :selectedCollectionId="selectedCollectionId" @close="recharger"/>

</template>
<script lang="ts">

import { defineComponent, onMounted, ref, watch, computed } from "vue";
import AddCollectionModal from "./AddCollectionModal.vue";
import ApiService from "../../services/ApiService";
import { format_date, suppression, error } from "../../utils/utils";
import {Collection} from '../../models/Collection';
import axios from 'axios';
import  Swal  from "sweetalert2";
import Paginate from 'vuejs-paginate'
import PaginationComponent from '../../components/Utilities/Pagination.vue';
import JwtService from "../../services/JwtService";
import AddNewProduitModal from "./AddNewProduitModal.vue";

export default defineComponent({
    name: "ListeCollection",
    components: {
      AddCollectionModal,
      PaginationComponent,
      AddNewProduitModal     
  },
  setup: () => {
    const modules = {
  
    };
    
    const collections = ref<Array<Collection>>([]);
    const collection = ref<Collection>();
    const AddCollectionModalRef = ref<null | HTMLElement>(null);
    const selectedItem = ref(0);

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllCollections(page_, limit_);
      } catch (error) {
        //
      }
    };

    function rechercher(){
      getAllCollections(page.value, limit.value, searchTerm.value );
    }

    // END PAGINATE

    function getAllCollections(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`collections?page=${page}&limit=${limi}&mot=${searchTerm}&`)
      .then(({ data }) => {
        collections.value = data.data.data;
        totalPages.value = data.data.totalPages;
        limit.value = data.data.limit;
        totalElements.value = data.data.totalElements;
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    } 

    onMounted(() => {
      getAllCollections();
    });

    function moddifier(EditCategorie:Collection) {
      collection.value = EditCategorie;
      selectedItem.value = EditCategorie.id;
    }
    const recharger = () => {
      getAllCollections();
    };

    const selectedCollection = ref<Collection | undefined>(undefined);
    const selectedCollectionId = ref<number>();

    const openAddNewProduitModal = (collection: Collection) => {
    selectedCollection.value = { ...collection };
    selectedCollectionId.value = collection.id;
  };

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    }


    const suppCat = (produitId, collectionId) => {
    Swal.fire({
        text: "Vous êtes sur le point de faire une suppression",
        icon: "warning",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Supprimer",
        cancelButtonText: `Annuler`,
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('Suppression annulée');
        } else if (result.isConfirmed) {
          ApiService.delete(`/collectionproduit/${collectionId}/${produitId}`)
            .then(({ data }) => {
              Swal.fire({
                title: 'Succès',
                text: data.message,
                icon: "success",
                toast: true,
                timer: 5000,
                position: 'top-right',
                showConfirmButton: false,
              });
              getAllCollections();
            }).catch(({ response }) => {
              Swal.fire({
                text: response.data.message, 
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Réessayer à nouveau!",
                heightAuto: false,
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            });
        }
      }).catch((error) => {
        console.error('Erreur lors de l\'affichage de la boîte de dialogue', error);
      });
    }

    return { modules,
      checkPermission,
            selectedItem,
            collections,
            recharger,
            suppression, 
            moddifier, 
            page, 
            totalPages,
            limit,
            totalElements,
            getAllCollections,
            handlePaginate,
            searchTerm,
            rechercher,
            AddCollectionModalRef,
            selectedCollection,
            selectedCollectionId,
            openAddNewProduitModal,
            suppCat,
    };
  },
});
</script>
@/models/CategorieInfo