<template>
    <BreadCrumb PageTitle="Liste des couleurs" />
    <ListeCouleur/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import ListeCouleur from "../../../components/Ecommerce/Couleur/ListeCouleur.vue";

    export default defineComponent({
      name: "ListeCouleurPage",
      components: {
        BreadCrumb,
        ListeCouleur,
      },
    });
  </script>