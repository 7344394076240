<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <!-- <router-link
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-commande"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Ajouter une commande
        </router-link> -->
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
        <form
          class="search-box position-relative me-15"
          @submit.prevent="rechercher"
        >
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher une commande"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Commande
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Montant total
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Coupon
              </th> -->
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Client
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Paiement
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Livraison
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Livreur
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Statut
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text pe-0"
              >
                Etape suivante
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text pe-0"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(commande, index) in commandes" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ commande.refCommande }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ commande.montantTotal }}
              </td>
              <!-- <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ commande.coupon }}
              </td> -->
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ commande?.client.nomComplet }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ commande?.moyenPaiement?.libelle }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ commande?.modeLivraison?.libelle }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <span v-for="(user, index) in commande?.users" :key="user.id">
                  <p
                    :class="{
                      'text-green': index === commande.users.length - 1,
                    }"
                  >
                    <span class="">
                      {{ user.nomComplet }}
                      <a
                        href="javascript:void(0);"
                        class="tag-delete-link text-outline-danger"
                        @click="suppressionZone(user.id, commande.id)"
                      >
                        <i class="flaticon-delete icon-position"></i>
                      </a>
                    </span>
                  </p>
                </span>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <div class="col-12">
                  <span
                    v-if="
                      commande.suivicommandes &&
                      commande.suivicommandes.length > 0
                    "
                    class="badge-container"
                  >
                    <div class="col-6">
                      <span
                        class="badge badge-outline-danger text-outline-primary"
                      >
                        {{ getLastStatus(commande).statutcommande.libelle }}
                      </span>
                    </div>
                  </span>
                  <span v-else class="badge text-bg-danger rounded-pill fs-13">
                    Aucun suivi
                  </span>
                </div>
              </td>
              <td class="shadow-none lh-1 fw-medium">
                <span
                  class="badge text-bg-success rounded-pill fs-13"
                  v-if="isLastStatus[commande.id]"
                >
                  Fin du suivi
                </span>
                <a
                  v-else
                  class="badge text-white bg-primary fs-15"
                  href="#"
                  @click.prevent="
                    openEditModal(
                      commande.id,
                      ordreL[commande.id],
                      libelleL[commande.id]
                    )
                  "
                >
                  {{ libelleL[commande.id] }}
                </a>
                <!-- <button v-else type="button" @click="nextStep(commande.id, ordreL[commande.id], libelleL[commande.id])" class="badge text-white bg-primary fs-15">
                  {{ libelleL[commande.id] }}
                </button> -->
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text pe-0"
              >
                <div class="dropdown">
                  <span
                    class="badge text-white bg-primary fs-15 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Actions
                    <i
                      class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"
                    ></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link
                        :to="{
                          name: 'ViewCommandePage',
                          params: { id: commande.id },
                        }"
                        class="dropdown-item d-flex align-items-center"
                        ><i
                          class="flaticon-eye lh-1 me-8 position-relative top-1"
                        ></i
                        >Détails</router-link
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#AffectationLivreurModal"
                        @click="openAffectationLivreurModal(commande)"
                      >
                        <i
                          class="flaticon-fast-delivery lh-1 me-8 position-relative top-1"
                        ></i>
                        <span v-if="commande?.users?.length == 0"
                          >Affecter un livreur</span
                        >
                        <span v-if="commande?.users?.length != 0"
                          >Réaffecter un autre livreur</span
                        >
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent
          :page="page"
          :totalPages="totalPages"
          :totalElements="totalElements"
          :limit="limit"
          @paginate="handlePaginate"
        />
      </div>
    </div>
  </div>

  <div
    class="modal fade createNewModal"
    id="ObservationSuiviModal"
    tabindex="-1"
    ref="addObservationSuiviModalRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">Validation de l'étape</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <form @submit.prevent="nextStep">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Informations supplémentaires
                    <span class="text-danger">*</span>
                  </label>
                  <textarea
                    v-model="observation"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="Ecrire le message"
                  ></textarea>
                </div>
              </div>
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="submit"
              >
                Envoyer
              </button>
            </div>
          </form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>

  <AffectationLivreurModal
    :selectedCommande="selectedCommande"
    :selectedCommandeId="selectedCommandeId"
    @close="recharger"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2";
import { Commande } from "../../../models/Commande";
import ApiService from "../../../services/ApiService";
import {
  suppression,
  error,
  success,
  showModal,
  hideModal,
} from "../../../utils/utils";
import PaginationComponent from "../../../components/Utilities/Pagination.vue";
import JwtService from "../../../services/JwtService";
import axios from "axios";
import { Form, Field, ErrorMessage } from "vee-validate";
import AffectationLivreurModal from "./AffectationLivreurModal.vue";

export default defineComponent({
  name: "ListeCommande",
  components: {
    PaginationComponent,
    AffectationLivreurModal,
    // Field
  },
  setup() {
    onMounted(() => {
      getAllCommandes();
      // getSuiviCommandeSuivivant(commandeId.value);
      console.log("commandeId", commandeId.value);
    });

    const commandes = ref<Array<Commande>>([]);
    const commande = ref<Commande>();
    const commandeId = ref(1);
    const ordreL = ref({});
    const libelleL = ref({});
    const isLastStatus = ref({});
    const estMarchand = JwtService.getIfMarchand(false);
    const userId = JwtService.getUser()!;
    const addObservationSuiviModalRef = ref<null | HTMLElement>(null);
    const observation = ref("");
    const x = ref();
    const y = ref();
    const z = ref();

    // BEGIN PAGINATE
    const searchTerm = ref("");
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllCommandes(page_, limit_);
      } catch (error) {
        //
      }
    };

    function rechercher() {
      getAllCommandes(page.value, limit.value, searchTerm.value);
    }

    // END PAGINATE

    const recharger = () => {
      getAllCommandes();
    };

    const getAllCommandes = async (page = 1, limi = 10, searchTerm = "") => {
      try {
        const response = await axios.get(
          `/commandes?page=${page}&limit=${limi}&mot=${searchTerm}`,
          {
            headers: {
              userId: userId,
            },
          }
        );
        commandes.value = response.data.data.data;
        console.log("Commande", response);
        commandes.value.forEach((commande) => {
          getSuiviCommandeSuivivant(commande.id);
          checkLastStatus(commande.id);
        });

        console.log("libelleL.value ===> ",libelleL.value)
        console.log("ordreL.value ===> ",ordreL.value)
        console.log("checkLastStatus ===> ",isLastStatus.value)
        totalPages.value = response.data.data.totalPages;
        limit.value = response.data.data.limit;
        totalElements.value = response.data.data.totalElements;
      } catch (error) {
        //error(response.data.message);
      }
    };

    const getSuiviCommandeSuivivant = async (commandeId) => {
      try {
        const response = await ApiService.get(
          `/suivicommandes/suivant/${commandeId}`
        );
        console.log("ERETTTTTTTT ===> ",response)
        libelleL.value[commandeId] = response.data.libelle;
        ordreL.value[commandeId] = response.data.ordre;
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du dernier statut :",
          error
        );
      }
    };

    const checkLastStatus = async (commandeId) => {
      try {
        const response = await ApiService.get(
          `/commandes/${commandeId}/isLastStatus`
        );
        console.log("responseTYUIEYYUEG ===> ",response)
        isLastStatus.value[commandeId] = response.data.isLastStatus;
      } catch (error) {
        console.error(
          "Erreur lors de la vérification du dernier statut :",
          error
        );
      }
    };

    const getLastStatus = (commande) => {
      if (commande.suivicommandes && commande.suivicommandes.length > 0) {
        return commande.suivicommandes.reduce((latest, current) => {
          return new Date(latest.dateSuivi) > new Date(current.dateSuivi)
            ? latest
            : current;
        });
      }
      return null;
    };

    const nextStep = async () => {
      try {
        const response = await ApiService.post("/commandes/suivi", {
          commandeId: x.value,
          ordreSuivant: y.value,
          libelleSuivant: z.value,
          observation: observation.value,
        });

        if (response.status === 200) {
          success(response.data.message);
          getSuiviCommandeSuivivant(x.value);
          checkLastStatus(x.value);
          getAllCommandes();
          hideModal(addObservationSuiviModalRef.value!);
        } else {
          console.error(
            "Erreur lors de la création du nouveau suivi",
            response.data.error
          );
        }
      } catch (error) {
        console.error("Erreur lors de la requête API", error);
      }
    };

    const openEditModal = (a, b, c) => {
      x.value = a;
      y.value = b;
      z.value = c;
      showModal(addObservationSuiviModalRef.value!);
    };

    function moddifier(EditCommande: Commande) {
      commande.value = EditCommande;
    }

    const selectedCommande = ref<Commande | undefined>(undefined);
    const selectedCommandeId = ref<number>();

    const openAffectationLivreurModal = (modele: Commande) => {
      selectedCommande.value = { ...modele };
      selectedCommandeId.value = modele.id;
    };

    const deleteCommande = (id: number) => {
      ApiService.delete(`/commandes/${id}`)
        .then(({ data }) => {
          Swal.fire({
            text: data.message,
            toast: true,
            icon: "success",
            title: "General Title",
            animation: false,
            position: "top-right",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            heightAuto: false,
          });
        })
        .catch(({ response }) => {
          Swal.fire({
            text: response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Réssayer à nouveau!",
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });

      for (let i = 0; i < commandes.value.length; i++) {
        if (commandes.value[i].id === id) {
          commandes.value.splice(i, 1);
        }
      }
    };

    const suppressionZone = (a: any, b: any) => {
      // console.log('dataSend', dataSend)
      axios
        .delete(`/livreur/commande/${a}/${b}`)
        .then(({ data }) => {
          Swal.fire({
            text: data.message,
            toast: true,
            icon: "success",
            title: "General Title",
            animation: false,
            position: "top-right",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            heightAuto: false,
          });
          getAllCommandes();
        })
        .catch(({ response }) => {
          Swal.fire({
            text: response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Réssayer à nouveau!",
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };

    const privileges = ref<Array<string>>(JwtService.getPrivilege());
    const checkPermission = (name) => {
      return privileges.value.includes(name);
    };

    return {
      commandes,
      checkPermission,
      getAllCommandes,
      deleteCommande,
      moddifier,
      suppression,
      page,
      totalPages,
      limit,
      totalElements,
      handlePaginate,
      rechercher,
      searchTerm,
      nextStep,
      ordreL,
      libelleL,
      commandeId,
      isLastStatus,
      getLastStatus,
      openEditModal,
      observation,
      addObservationSuiviModalRef,
      x,
      y,
      z,
      selectedCommande,
      selectedCommandeId,
      openAffectationLivreurModal,
      suppressionZone,

      recharger,
    };
  },
});
</script>

<style>
.text-green {
  color: blue;
  background-color: b5b5c3;
}
</style>