<template>
  <div
    class="modal fade createNewModal"
    id="AddModeleModal"
    tabindex="-1"
    ref="addModeleModalRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="modeleForm" @submit="addModele" :validation-schema="modeleSchema">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Nom <span class="text-danger">*</span>
                  </label>
                  <Field name="nom" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nom"/>
                  <ErrorMessage name="nom" class="text-danger"/>
                </div>
              </div>
              
              <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Marque <span class="text-danger">*</span>
                </label>
                <Field  name="marque"  v-slot="{ field }">
                  <Multiselect
                    :options="marqueOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionnez la marque"
                  />
                </Field>  
                <ErrorMessage name="marque" class="text-danger"/>
              </div>
            </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Description <span class="text-danger">*</span>
                  </label>
                  <Field name="description" as="textarea"  cols="30"
                    rows="12" placeholder="Entrer la description" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                  <textarea
                  class="form-control shadow-none rounded-0 text-black"
                    v-model="field.value"
                  ></textarea>
                </Field>
                  <ErrorMessage name="description" class="text-danger"/>
                </div>
              </div>
            <div class="col-md-12" v-if="btntext != 'Modifier'">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Categorie 
                </label>
                <Field  name="categories"  v-slot="{ field }" v-model="defaultSelectedCategories">
                  <Multiselect
                    :options="categoriesOptions"
                    :searchable="true"
                    track-by="label"
                    multiple="true"
                    mode="tags"
                    label="label"
                    v-bind = "field"
                    placeholder="Sélectionnez la catégorie"
                  />
                </Field>   
                <ErrorMessage name="categories" class="text-danger"/>
                <span class="text-danger" v-if="produitsError">La catégorie est obligatoire</span>
              </div>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
            {{ btntext }}
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import ApiService from '../../../services/ApiService';
import * as Yup from 'yup';
import { Modele } from '../../../models/Modele';
import { error , success, hideModal } from '../../../utils/utils';
import Multiselect from '@vueform/multiselect'

export default defineComponent({
    name: "AddModeleModal",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  props: {
    item: {
      type: Number,
      default: 0,
    }
  },
  setup(props, { emit }){
    const modeleSchema = Yup.object().shape({
      nom: Yup.string().required('Le nom est obligatoire'),
      description: Yup.string().required('La description est obligatoire'),
      // categories: Yup.array().required('La catégorie est obligatoire'),
      marque: Yup.string().required('La marque est obligatoire'),
    });

    const marqueOptions = ref([]);
    const modeleForm = ref<Modele | null>(null);
    const addModeleModalRef = ref<null | HTMLElement>(null);
    // const item = ref({ ...props.item });
    const localItem = ref(props.item);
    const isUPDATE = ref(false);
    const title = ref("Ajouter un modele");
    const btntext = ref('Ajouter');
    const categoriesOptions = ref([]);

    watch(() => props.item, (newValue) => {
      getModele(newValue);
      isUPDATE.value = true;
      btnTitle();
    });

    const btnTitle = async () => {
      if (isUPDATE.value) {
         title.value = "Modifier le modele";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter un modele";
         btntext.value = "Ajouter";
      }
    }

    const defaultSelectedCategories = ref<any[]>([]);
    const produitsError = ref(false); 

    watch(defaultSelectedCategories, () => {
      console.log('name', defaultSelectedCategories.value)
      if(defaultSelectedCategories.value.length > 0){
        produitsError.value = false
      }
      else {
        produitsError.value = true
      }
    })
    const getModele = async (id: number) => {
      return ApiService.get("/models/"+id)
      .then(({ data }) => {
        // map data in form
        const donnees = data.data;
        // const categories = donnees.categories;

        // let ids: number[] = [];
        // for (const categorie of categories) {
        //   ids.push(categorie.id); 
        // }
        // categories.value= ids;

        // defaultSelectedCategories.value = categories.value
        for (const key in donnees) {
          modeleForm.value?.setFieldValue(key, 
          (typeof donnees[key] === 'object' && donnees[key] !== null)? donnees[key].id :donnees[key]
          );
        }
        emit('openmodal', addModeleModalRef.value);

      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const addModele = async (values: any, modeleForm) => {
      values = values as Modele;
      if(isUPDATE.value){
        ApiService.put("/models/"+values.id,values)
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              modeleForm.resetForm();
              hideModal(addModeleModalRef.value);
              isUPDATE.value=false;
              btnTitle();
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }else{
        if(defaultSelectedCategories.value.length > 0){
        ApiService.post("/models",values)
        .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              modeleForm.resetForm();
              hideModal(addModeleModalRef.value);
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });}else{
          produitsError.value = true
        }
      }
    };

    const fetchMarque = async () => {
      try {
        const response = await ApiService.get('/marques?limit=0');
        const categorieData = response.data.data.data;
        marqueOptions.value = categorieData.map((marque) => ({
          value: marque.id,
          label: `${marque.name}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchCatgories = async () => {
      try {
        const response = await ApiService.get('/categories/nopage');
        const categoriesData = response.data.data;
        categoriesOptions.value = categoriesData.map((categories) => ({
          value: categories.id,
          label: `${categories.name}`,
        }));
      } catch (error) {
        //
      }
    };

    onMounted(() => {
      fetchMarque();
      fetchCatgories();
    });

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isUPDATE.value=false;
      formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    return { modeleSchema,
      addModeleModalRef,
      addModele,
      modeleForm,
      title,btntext,resetValue,
      marqueOptions, categoriesOptions,
      defaultSelectedCategories,produitsError,
    };
  },
});
</script>@/models/Modele