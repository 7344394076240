<template>
    <BreadCrumb PageTitle="Ajouter une distance" />
    <AddDistance />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddDistance from "../../components/Distance/AddDistance.vue";
  
    export default defineComponent({
      name: "AddDistancePage",
      components: {
        BreadCrumb,
        AddDistance,
      },
    });
  </script>