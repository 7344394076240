<template>
  <div class="row">
      <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <h4 class="position-relative text-black fw-bold mb-10">Informations générales</h4>
              <router-link to="/liste-marchands" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium">
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
            </div>
            <table class="table">
              <tbody>
                <tr v-if="marchand?.typeMarchand">
                  <td>Type marchand</td>
                  <td v-if="marchand?.typeMarchand == 1">Personne Physique</td>
                  <td v-if="marchand?.typeMarchand == 2">Personne Morale</td>
                </tr>
                <tr v-if="marchand?.nom">
                  <td>Nom</td>
                  <td>{{ marchand?.nom }}</td>
                </tr>
                <tr v-if="marchand?.prenom">
                  <td>Prénom</td>
                  <td>{{ marchand?.prenom }}</td>
                </tr>
                <tr v-if="marchand?.raisonSocial">
                  <td>Raison sociale</td>
                  <td>{{ marchand?.raisonSocial }}</td>
                </tr>
                <tr v-if="marchand?.adresseResidence">
                  <td>Adresse résidence</td>
                  <td>{{ marchand?.adresseResidence }}</td>
                </tr>
                <tr v-if="marchand?.adresseBoutique">
                  <td>Adresse boutique</td>
                  <td>{{ marchand?.adresseBoutique }}</td>
                </tr>
                <tr v-if="marchand?.email">
                  <td>Email</td>
                  <td>{{ marchand?.email }}</td>
                </tr>
                <tr v-if="marchand?.telephone">
                  <td>Téléphone</td>
                  <td>{{ marchand?.telephone }}</td>
                </tr>
                <tr v-if="marchand?.secteurActivite">
                  <td>Secteur d'activité</td>
                  <td>{{ marchand?.secteurActivite.libelle }}</td>
                </tr>
                <tr v-if="marchand?.ifu">
                  <td>Ifu</td>
                  <td>{{ marchand?.ifu }}</td>
                </tr>
                <tr v-if="marchand?.rccm">
                  <td>Rccm</td>
                  <td>{{ marchand?.rccm }}</td>
                </tr>
                <tr v-if="marchand?.statut">
                  <td>Statut</td>
                  <td>{{ marchand?.statut }}</td>
                </tr>
                <tr v-if="marchand?.codeMarchand">
                  <td>Code marchand</td>
                  <td>{{ marchand?.codeMarchand }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "../../../services/ApiService";
import { Marchand } from "../../../models/Marchand";
import { error, format_Date, showModal } from "../../../utils/utils";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ViewMarchand",
  setup: () => {
    const route = useRoute();
    const marchand = ref<Marchand | null>(null);

    function getMarchand(id: string) {
      return ApiService.get("/marchands", id)
        .then(({ data }) => {
          marchand.value = data.data; 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    onMounted(() => {
      const id = route.params.id as string;
      getMarchand(id);
    });

    return { 
      marchand,
      getMarchand,
      format_Date,
     };
  },
});
</script>
