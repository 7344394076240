<template>
    <BreadCrumb PageTitle="Liste des catégories d'infos" />
    <ListeCategorieInfo/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeCategorieInfo from "../../components/CategorieInfo/ListeCategorieInfo.vue";

    export default defineComponent({
      name: "ListeCategorieInfoPage",
      components: {
        BreadCrumb,
        ListeCategorieInfo,
      },
    });
  </script>