<template>
    <BreadCrumb PageTitle="Modifier une distance" />
    <EditDistance />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EditDistance from "../../components/Distance/EditDistance.vue";
  
    export default defineComponent({
      name: "EditDistancePage",
      components: {
        BreadCrumb,
        EditDistance,
      },
    });
  </script>