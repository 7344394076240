<template>
    <BreadCrumb PageTitle="Modifier a InfoFooter" />
    <EditInfoFooter />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EditInfoFooter from "../../components/InfoFooter/EditInfoFooter.vue";
  
    export default defineComponent({
      name: "EditInfoFooterPage",
      components: {
        BreadCrumb,
        EditInfoFooter,
      },
    });
  </script>