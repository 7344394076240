<template>
    <BreadCrumb PageTitle="Modifier un coupon" />
    <EditCoupon />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import EditCoupon from "../../../components/Ecommerce/Coupon/EditCoupon.vue";
  
    export default defineComponent({
      name: "EditCouponPage",
      components: {
        BreadCrumb,
        EditCoupon,
      },
    });
  </script>