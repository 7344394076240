<template>
    <BreadCrumb PageTitle="Liste des livraisons" />
    <ListeLivraison />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeLivraison from "../../components/Livraison/ListeLivraison.vue";

  
    export default defineComponent({
      name: "ListeLivraisonPage",
      components: {
        BreadCrumb,
        ListeLivraison,
      },
    });
  </script>