<template>
    <div
      class="modal fade createNewModal"
      id="AddNewProduitModal"
      tabindex="-1"
      ref="addAddNewProduitModalRef"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-15 p-md-40">
          <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
            <h5 class="modal-title fw-bold text-black">
          </h5>
          </div>
          <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
            <h5>Vous voulez ajouter de(s) nouveau(x) produit(s) à cette collection: {{ selectedCollection?.name }}</h5>
          <Form ref="produitForm" @submit="addProduit" :validation-schema="produitSchema">
            <div class="row">
                <div class="col-md-12">
                  <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                      Produits <span class="text-danger">*</span>
                    </label>
                    <Field name="produit" v-slot="{ field }">
                      <Multiselect
                        :options="produitsOptions"
                        :searchable="true"
                        multiple="true"
                        mode="tags"
                        track-by="label"
                        label="label"
                        v-model = "field.value"
                        v-bind = "field"
                        placeholder="Sélectionnez le produit"
                      />
                    </Field>  
                  </div>
                  <ErrorMessage name="produit" class="text-danger"/>
                </div>
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="submit"
              >
                Envoyer
              </button>
            </div>
          </Form>
          </div>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { ref, onMounted, watch} from 'vue';
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import * as Yup from 'yup';
  import ApiService from '@/services/ApiService';
  import { error, hideModal, success } from '@/utils/utils';
  import { useRouter, useRoute } from 'vue-router';
  import Multiselect from '@vueform/multiselect'
  import { Collection } from '@/models/Collection';
import axios from 'axios';
  
  export default {
    name: "AddNewProduitModal",
    components: {
      Form,
      Field,
      ErrorMessage,
      Multiselect,
    },
    props: {
      selectedCollection: Object as () => Collection | undefined,
    },
    setup: (props, { emit }) => {
  
      const produitSchema = Yup.object().shape({
        produit: Yup.array().required("Le produit est obligatoire"),
      });
  
      const produitForm =  ref<Collection | null>(null);
      const addAddNewProduitModalRef = ref<null | HTMLElement>(null);
      //const router = useRouter();
      const userForm = ref<Collection>();
      const route = useRoute();
      const  collection = ref();
      const produitsOptions = ref([]);
  
      const fetchProduits = async (collectionId) => {
      try {
        if (selectedCollectionId.value) {
          const response = await axios.get(`/resteante/produitcollections/${collectionId}`);
          console.log('response', response)
          const produitsData = response.data.data;
          console.log('produitsData', produitsData)
          produitsOptions.value = produitsData.map((produit) => ({
            value: produit.id,
            label: produit.title,
          }));
        }
      } catch (error) {
        console.error("Une erreur s'est produite lors de la récupération des rôles :", error);
      }
    };
  
    function getCollection(id:number) {
      ApiService.get("/collections/"+id.toString())
        .then(({ data }) => {
          for (const key in data.data) {
            userForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.message);
      });
    }
    
      const selectedCollection = ref<Collection | undefined>(undefined);
      const selectedCollectionId = ref<number>();
  
      const addProduit = async (values, {resetForm}) => {
        const produitData = {
          collectionId: selectedCollection.value?.id,
          produits: values.produit,
        };
        console.log('rfjfl', produitData)
      ApiService.post("/collections/produits/"+produitData.collectionId, produitData)
        .then(({ data }) => {
          if (data.code == 200) {
          success(data.message);
          resetForm();
          hideModal(addAddNewProduitModalRef.value);
          emit('close');
          emit('getAllCollection',data.data);
        }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

      watch(() => props.selectedCollection, (newValue) => {
        selectedCollection.value = newValue;
      if(newValue){
        selectedCollectionId.value = newValue.id;
        fetchProduits(selectedCollectionId.value);
      }
    });
  
      onMounted(() => {
      if(route.params.id) {
        getCollection(parseInt(route.params.id as string));
      }
    });
  
      return {produitSchema,
        addProduit,
         produitForm,
         addAddNewProduitModalRef,
          getCollection,
          produitsOptions,
          selectedCollectionId
         };
    },
  };
  </script>