<template>
  <BreadCrumb PageTitle="Détails du client" />
  <ViewClient />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
  import ViewClient from "../../../components/Ecommerce/Client/ViewClient.vue";

  export default defineComponent({
    name: "ViewClientPage",
    components: {
      BreadCrumb,
      ViewClient,
    },
  });
</script>