<template>
    <div class="mt-50">
      <div class="card border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <div class="text-center mb-10">
            <img src="../../../assets/images/lapostebj.png" alt="logo-icon" style="height: 100px;"/>
          </div>
          <h4 class="text-black fw-bold mb-0 text-center">
            Connectez-vous à votre compte !
          </h4>
          <Form ref="loginForm" @submit="onSubmitLogin" :validation-schema="loginSchema">
            <div class="form-group mb-10 mb-sm-15 mb-md-17">
              <label class="d-block text-black fw-semibold mb-10">
                Email
              </label>
              <Field name="email" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer votre email"/>
                <ErrorMessage name="email" class="text-danger"/>
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <div class="d-flex align-items-center justify-content-between mb-10" >
              <label class="d-block text-black fw-semibold ">
                Mot de passe 
              </label>
              <!-- <router-link
                to="/forgot-password"
                class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary"
              >
              Mot de passe oublié ?
              </router-link> -->
            </div>
            <Field name="password" type="password"  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer votre mot de passe"/>
                <ErrorMessage name="password" class="text-danger"/>
            </div>
            <button ref="submitButton"
              class="text-decoration-none text-center default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success  w-100"
              type="submit"  >
              Connectez-vous maintenant
            </button>
          </Form>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/services/auth';
import Swal from 'sweetalert2';

export default defineComponent({
    name: "LoginForm",
    components: {
    Form,
    Field,
    ErrorMessage,
},
  setup: () => {
    const loginSchema = Yup.object().shape({
      email: Yup.string().email("L'email est invalide").required('L\'email   est obligatoire'),
      password: Yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Le mot de passe est obligatoire'),
    });

    const router  = useRouter();
    const store = useAuthStore();
    const submitButton = ref<HTMLButtonElement | null>(null);


    const loginForm = ref(null);
    const onSubmitLogin = async (values: any) => {
     // values = values as User;
      // Clear existing errors
      store.logout();
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value.disabled = true;
      }
      // Send login request
      await store.login(values);
      const error = Object.values(store.errors);
      if (error.length === 0) {
          // Go to page after successfully login
          router.push({ name: "tableauBordPage" });
      } else {
        Swal.fire({
          text: error[1] as string,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "OK",
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-sm fw-semobold btn-danger",
          },
        }).then(() => {
          store.errors = {};
        });
      }
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };
   
    return { loginSchema, onSubmitLogin, loginForm,submitButton};
  },
});
</script>