import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-7 col-xxxl-6" }
const _hoisted_3 = { class: "col-xxl-5 col-xxxl-6" }
const _hoisted_4 = { class: "col-xxl-6" }
const _hoisted_5 = { class: "col-xxl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhatHappening = _resolveComponent("WhatHappening")!
  const _component_ExpectedEarnings = _resolveComponent("ExpectedEarnings")!
  const _component_StatsBoxes = _resolveComponent("StatsBoxes")!
  const _component_WebsiteVisitors = _resolveComponent("WebsiteVisitors")!
  const _component_ProductsOrders = _resolveComponent("ProductsOrders")!
  const _component_StockReport = _resolveComponent("StockReport")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_WhatHappening),
      _createVNode(_component_ExpectedEarnings)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_StatsBoxes),
      _createVNode(_component_WebsiteVisitors)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ProductsOrders)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_StockReport)
    ])
  ]))
}