<template>
    <BreadCrumb PageTitle="Liste des collections" />
    <ListeCollection/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeCollection from "../../components/Collection/ListeCollection.vue";

    export default defineComponent({
      name: "ListeCollectionPage",
      components: {
        BreadCrumb,
        ListeCollection,
      },
    });
  </script>