<template>
    <BreadCrumb PageTitle="Liste des types de media" />
    <ListeTypeMedia/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeTypeMedia from "../../components/TypeMedia/ListeTypeMedia.vue";

    export default defineComponent({
      name: "ListeTypMediaPage",
      components: {
        BreadCrumb,
        ListeTypeMedia,
      },
    });
  </script>