<template>
    <BreadCrumb PageTitle="Ajouter un client" />
    <AddClient />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import AddClient from "../../../components/Ecommerce/Client/AddClient.vue";
  
    export default defineComponent({
      name: "AddClientPage",
      components: {
        BreadCrumb,
        AddClient,
      },
    });
  </script>