<template>
    <BreadCrumb PageTitle="Modifier A la Une" />
    <EditAlaUne />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EditAlaUne from "../../components/AlaUne/EditAlaUne.vue";
  
    export default defineComponent({
      name: "EditAlaUnePage",
      components: {
        BreadCrumb,
        EditAlaUne,
      },
    });
  </script>