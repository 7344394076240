<template>
  <div class="row justify-content-center">
    <div class="col-md-8 col-lg-6 col-xl-7 col-xxl-6 col-xxxl-5">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <div class="text-center mb-10">
            <img src="../../../assets/images/lapostebj.png" alt="logo-icon" style="height: 100px;"/>
          </div>
          <h4 class="text-black fw-bold mb-0 text-center">Réinitialiser le mot de passe?</h4>
          <p class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center sub-text">
            Entrez votre nouveau mot de passe et confirmez-le une autre fois dans le champ en dessous de
          </p>
          <Form ref="resetForm" @submit="addReset" :validation-schema="resetSchema">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Mot de passe 
              </label>
              <Field name="password" type="password" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le mot de passe"/>
                <ErrorMessage name="password" class="text-danger"/>
            </div>
            <div class="form-group mb-15 mb-sm-20">
              <label class="d-block text-black fw-semibold mb-10">
                Confirmer le mot de passe
              </label>
              <Field name="password_confirm" type="password" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Confirmer le mot de passe"/>
                <ErrorMessage name="password_confirm" class="text-danger"/>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 d-block w-100"
              type="submit"
            >
             Réinitialiser le mot de passe
            </button>
            <div class="text-center mt-15 mt-sm-20 mt-md-25">
              <router-link
                to="/login"
                class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative"
              >
              <i class="ph-bold ph-arrow-left"></i>
                  Revenir à la page de connexion
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';

export default defineComponent({
    name: "ResetPassword",
    components: {
    Form,
    Field,
    ErrorMessage,
},
  setup: () => {
    const resetSchema = Yup.object().shape({
      password: Yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]/,
        'Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial'
      ).required('Le mot de passe est obligatoire'),
      password_confirm: Yup.string().required('La confirmation est obligatoire'),
    });

    const resetForm = ref(null);
    const addReset = async (values) => {
      //
    };
    return { resetSchema, addReset, resetForm};
  },
});
</script>