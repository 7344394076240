<template>
    <BreadCrumb PageTitle="Liste des modes de livraisons" />
    <ListeModeLivraison/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import ListeModeLivraison from "../../../components/Ecommerce/ModeLivraison/ListeModeLivraison.vue";

    export default defineComponent({
      name: "ListeModeLivraisonPage",
      components: {
        BreadCrumb,
        ListeModeLivraison,
      },
    });
  </script>