<template>
    <BreadCrumb PageTitle="Ajouter un coupon" />
    <AddCoupon />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import AddCoupon from "../../../components/Ecommerce/Coupon/AddCoupon.vue";
  
    export default defineComponent({
      name: "AddCouponPage",
      components: {
        BreadCrumb,
        AddCoupon,
      },
    });
  </script>