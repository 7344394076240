<template>
    <BreadCrumb PageTitle="Modifier un contrat" />
    <EditContrat />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import EditContrat from "../../../components/Ecommerce/Contrat/EditContrat.vue";
  
    export default defineComponent({
      name: "EditContratPage",
      components: {
        BreadCrumb,
        EditContrat,
      },
    });
  </script>