<template>
    <BreadCrumb PageTitle="Liste A la Une" />
    <ListeAlaUne/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeAlaUne from "../../components/AlaUne/ListeAlaUne.vue";

    export default defineComponent({
      name: "ListeAlaUnePage",
      components: {
        BreadCrumb,
        ListeAlaUne,
      },
    });
  </script>