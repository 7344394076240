<template>
  <BreadCrumb PageTitle="Détails des catégories d'articles" />
  <ViewCategorieArticle />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ViewCategorieArticle from "../../components/CategorieArticle/ViewCategorieArticle.vue";

  export default defineComponent({
    name: "ViewCategorieArticlePage",
    components: {
      BreadCrumb,
      ViewCategorieArticle,
    },
  });
</script>