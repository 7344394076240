<template>
    <BreadCrumb PageTitle="Détails des informations" />
    <ViewInfoFooter/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ViewInfoFooter from "../../components/InfoFooter/ViewInfoFooter.vue";

    export default defineComponent({
      name: "ViewInfoFooterPage",
      components: {
        BreadCrumb,
        ViewInfoFooter,
      },
    });
  </script>