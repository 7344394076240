<template>
  <div class="row">
      <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <h4 class="position-relative text-black fw-bold mb-10">Informations générales</h4>
              <router-link to="/liste-commandes" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium">
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
            </div>
            <table class="table">
              <tbody>
                
                <tr v-if="commande?.refCommande">
                  <td class="bl">Référence</td>
                  <td>{{ commande?.refCommande }}</td>
                </tr>
                <tr v-if="commande?.montantTotal">
                  <td class="bl">Montant total</td>
                  <td>{{ commande?.montantTotal }}</td>
                </tr>
                <tr v-if="commande?.remise">
                  <td class="bl">Remise</td>
                  <td>{{ commande?.remise }}</td>
                </tr>
                <tr v-if="commande?.montantTotalApresRemise">
                  <td class="bl">Montant total après remise</td>
                  <td>{{ commande?.montantTotalApresRemise }}</td>
                </tr>
                <tr v-if="commande?.dateCommande">
                  <td class="bl">Date Commande</td>
                  <td>{{ format_date(commande?.dateCommande) }}</td>
                </tr>
                <tr v-if="commande?.coupon">
                  <td class="bl">Code coupon</td>
                  <td>{{ commande.coupon.code }}</td>
                </tr>
                <tr v-if="commande?.client">
                  <td class="bl">Client</td>
                  <td>{{ commande.client?.nomComplet }}</td>
                </tr>
                <tr v-if="commande?.moyenPaiement">
                  <td class="bl">Mode de paiement</td>
                  <td>{{ commande?.moyenPaiement.libelle }}</td>
                </tr>
                <tr v-if="commande?.modeLivraison">
                  <td class="bl">Mode de livraison</td>
                  <td>{{ commande?.modeLivraison.libelle }}</td>
                </tr>
                <tr>
                  <td class="bl">Livreur</td>
                  <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                    <span v-for="(user, index) in commande?.users" :key="user.id">
                        <p :class="{'text-green': index === commande.users.length - 1}">
                            {{ user.nomComplet }}
                        </p>
                    </span>
                </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-xl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <h4 class="text-black fw-bold mb-10">Produits dans la commande : {{totalCommande}} au total</h4>
            <table class="table">
              <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                Produit
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                 Prix
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                Montant
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                Quantité
              </th>
            </tr>
          </thead>
              <tbody>
                <tr v-for="produitCommande in commande?.produitCommandes" :key="produitCommande.id">
                  <td>{{ produitCommande.produit.title }}</td>
                  <td>{{ produitCommande.produit.price }}</td>
                  <td>{{ produitCommande.montant }}</td>
                  <td>{{ produitCommande.quantite }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- INFORMATIONS DE LIVRAISON -->
      <div class="col-md-12 col-xl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <h4 class="text-black fw-bold mb-10">Information de livraison :</h4>
            <table class="table">
              <tbody>
                <tr v-if="commande?.adresseLivraison">
                  <td class="bl">Adresse</td>
                  <td>{{ commande?.adresseLivraison?.adresse }}</td>
                </tr>
                <tr v-if="commande?.adresseLivraison">
                  <td class="bl">Email</td>
                  <td>{{ commande?.adresseLivraison?.email }}</td>
                </tr>
                <tr v-if="commande?.adresseLivraison">
                  <td class="bl">Telephone</td>
                  <td>{{ commande?.adresseLivraison?.telephone }}</td>
                </tr>
                <tr v-if="commande?.adresseLivraison">
                  <td class="bl">Pays</td>
                  <td>{{ commande?.adresseLivraison?.pays?.nom_fr_fr }}</td>
                </tr>
                <tr v-if="commande?.adresseLivraison">
                  <td class="bl">Commune</td>
                  <td>{{ commande?.adresseLivraison?.commune?.libelle }}</td>
                </tr>
                <tr v-if="commande?.adresseLivraison">
                  <td class="bl">Ville</td>
                  <td>{{ commande?.adresseLivraison?.ville }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- fin -->

      <div class="col-md-12 col-xl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <h4 class="text-black fw-bold mb-10">Suivi de la commande</h4>
            <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Commande
              </th> -->
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Ordre
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Libellé
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Observation
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="suivicommande in commande?.suivicommandes" :key="suivicommande.id">
              <!-- <td class="shadow-none lh-1 fw-medium text-primary">
                {{ suivicommande?.refCommande }}
              </td> -->
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ suivicommande?.statutcommande?.ordre }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                <span class="badge badge-outline-danger text-outline-primary">
                  {{ suivicommande?.statutcommande?.libelle }}
                      </span>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ suivicommande?.observation }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_Date( suivicommande.dateSuivi ) }}
              </td>
            </tr>
          </tbody>
        </table>
          </div>
        </div>
      </div>

  </div>

</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "../../../services/ApiService";
import { Commande } from "../../../models/Commande";
import { error, format_Date,format_date, showModal, suppression, getUrlApiForProductFiles } from "../../../utils/utils";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";
import axios from "axios";
import JwtService from "../../../services/JwtService";
import {SuiviCommande} from '../../../models/SuiviCommande';

export default defineComponent({
  name: "ViewCommande",
  setup: () => {
    const route = useRoute();
    const commande = ref<Commande | null>(null);
    const totalCommande = ref();
    const userId = JwtService.getUser()!;

    function getCommande(id: string) {
      return axios.get(`/commandes/${id}`,{
            headers: {
                'userId': userId
            }
        })
        .then(({ data }) => {
          commande.value = data.data;
          console.log('résultat', data.data)
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    function getProduitCommande(id: string) {
      return axios.get(`/produitcommandes/${id}`,{
            headers: {
                'userId': userId
            }
        })
        .then(({ data }) => {
          totalCommande.value = data.data.totalProduits 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    const commandes = ref<Array<Commande>>([]);   

    onMounted(() => {
      const id = route.params.id as string;
      getCommande(id);
      getProduitCommande(id);
      // getAllSuiviCommande()
    });

    //**Suivi commande */
    // const suiviCommandes = ref<Array<SuiviCommande>>([]);
    // const suiviCommande = ref<SuiviCommande>();


    // function getAllSuiviCommande() {
    //   return ApiService.get('/suiviCommandes')
    //   .then(({ data }) => {
    //     suiviCommandes.value = data.data.data;
    //   })
    //   .catch(({ response }) => {
    //     error(response.data.message)
    //   });
    // } 
    //**Fin suivi */

    return { 
      commande,
      getCommande,
      format_Date,
      suppression,
      getUrlApiForProductFiles,
      commandes,
      format_date,
      totalCommande,
      getProduitCommande,
      // suiviCommande,
      // suiviCommandes,
     };
  },
});
</script>

<style>
  .bl{
    font-weight: bold;
  }
</style>