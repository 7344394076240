<template>
    <BreadCrumb PageTitle="Ajouter un media" />
    <AddMedia />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddMedia from "../../components/Media/AddMedia.vue";
  
    export default defineComponent({
      name: "AddMediaPage",
      components: {
        BreadCrumb,
        AddMedia,
      },
    });
  </script>