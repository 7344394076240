<template>
  <div
    class="modal fade createNewModal"
    id="AddParametreModal"
    tabindex="-1"
    ref="addParametreModalRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="parametreForm" @submit="addParametre" :validation-schema="parametreSchema">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Libellé <span class="text-danger">*</span>
                  </label>
                  <Field name="libelle" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le libellé"/>
                  <ErrorMessage name="libelle" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Categorie <span class="text-danger">*</span>
                </label>
                <Field  name="categorieInfo"  v-slot="{ field }">
                  <Multiselect
                    :options="categorieOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionnez la categorie"
                  />
                </Field>  
                <ErrorMessage name="categorieInfo" class="text-danger"/>
              </div>
            </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Description <span class="text-danger">*</span>
                  </label>
                  <Field name="description" as="textarea"  cols="30"
                    rows="12" placeholder="Entrer la description" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                  <textarea
                  class="form-control shadow-none rounded-0 text-black"
                    v-model="field.value"
                  ></textarea>
                </Field>
                  <ErrorMessage name="description" class="text-danger"/>
                </div>
              </div>
              
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
            {{ btntext }}
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import ApiService from '../../services/ApiService';
import * as Yup from 'yup';
import { hideModal } from '../../utils/utils';
import { Parametre } from '../../models/Parametre';
import { error , success } from '../../utils/utils';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
    name: "AddParametreModal",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  props: {
    item: {
      type: Number,
      default: 0,
    }
  },
  setup(props, { emit }){
    const parametreSchema = Yup.object().shape({
      categorieInfo: Yup.string().required('La catégorie est obligatoire'),
      description: Yup.string().required('La description est obligatoire'),
      libelle: Yup.string().required('Le libellé est obligatoire'),
    });

    const parametreForm = ref<Parametre | null>(null);
    const addParametreModalRef = ref<null | HTMLElement>(null);
    const localItem = ref(props.item);
    const isUPDATE = ref(false);
    const title = ref("Ajouter le paramètre");
    const btntext = ref('Ajouter');
    const categorieOptions = ref([]);

    watch(() => props.item, (newValue) => {
      getParametre(newValue);
      isUPDATE.value = true;
      btnTitle();
    });

    const btnTitle = async () => {
      if (isUPDATE.value) {
         title.value = "Modifier le paramètre";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter le paramètre";
         btntext.value = "Ajouter";
      }
    }

    const getParametre = async (id: number) => {
      return ApiService.get("/parametres/"+id)
      .then(({ data }) => {
        // map data in form
        const donnees = data.data;
        console.log('rfgrgr', data)
        for (const key in donnees) {
          parametreForm.value?.setFieldValue(key, 
          (typeof donnees[key] === 'object' && donnees[key] !== null)? donnees[key].id :donnees[key]
          );
        }
        emit('openmodal', addParametreModalRef.value);

      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const fetchCategorie = async () => {
      try {
        const response = await ApiService.get('/categorieInfos');
        const categorieData = response.data.data.data;
        console.log(categorieData);
        categorieOptions.value = categorieData.map((categorie) => ({
          value: categorie.id,
          label: `${categorie.libelle}`,
        }));
      } catch (error) {
        //
      }
    };

    const addParametre = async (values: any, parametreForm) => {
      values = values as Parametre
      if(isUPDATE.value){
        ApiService.put("/parametres/"+values.id,values)
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              parametreForm.resetForm();
              hideModal(addParametreModalRef.value);
              isUPDATE.value=false;
              btnTitle();
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }else{
        ApiService.post("/parametres",values)
        .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              parametreForm.resetForm();
              hideModal(addParametreModalRef.value);
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }
    };

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isUPDATE.value=false;
      formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    onMounted(() => {
      fetchCategorie();
    });

    return { parametreSchema,
      addParametreModalRef,
      addParametre,
      parametreForm,
      title,btntext,resetValue,categorieOptions
    };
  },
});
</script>