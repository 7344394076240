<template>
    <BreadCrumb PageTitle="Liste des produits" />
    <ListeProduit/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import ListeProduit from "../../../components/Ecommerce/Produit/ListeProduit.vue";

    export default defineComponent({
      name: "ListeProduitPage",
      components: {
        BreadCrumb,
        ListeProduit,
      },
    });
  </script>