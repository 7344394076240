<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="distanceForm" @submit="editDistance" :validation-schema="distanceSchema">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type <span class="text-danger">*</span>
                </label>
                <Field  name="type"  v-slot="{ field }">
                  <Multiselect
                    :options="typeValue"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionnez le type"
                  />
                </Field>  
              </div>
              <ErrorMessage name="type" class="text-danger"/>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Libellé <span class="text-danger">*</span>
                </label>
                <Field name="libelle" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le libellé"/>
                <ErrorMessage name="libelle" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Distance <span class="text-danger">*</span>
                </label>
                <Field name="distance" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la distance"/>
                <ErrorMessage name="distance" class="text-danger"/>
              </div>
            </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success" type="submit">
                Modifier une distance
            </button>
            <router-link to="/liste-distances" class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">

import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '../../services/ApiService';
import { error, success } from '../../utils/utils';
import { useRoute, useRouter } from 'vue-router';
import { Distance } from '../../models/Distance';
import Multiselect from '@vueform/multiselect'

export default defineComponent({
    name: "EditDistance",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const distanceSchema = Yup.object().shape({
      type: Yup.number().typeError('Veuillez entrer des chiffres').required("Le type est obligatoire"),
      distance: Yup.string().required('La distance est obligatoire'),
      libelle: Yup.string().required('Le libelle est obligatoire'),
    });

    const distanceForm = ref<Distance>();
    const router = useRouter();
    const route = useRoute();

    const typeValue = 
      [
      {
          value: 1 ,
          label: "Colis postaux"
      },
      {
          value: 2 ,
          label: "Envois express"
      },
      {
          value: 3 ,
          label: "Poste aux lettres"
      },
      {
          value: 4 ,
          label: "Transport de colis/marchandises et Location de bus/camions"
      }
    ];

    function getDistance(id:number) {
      ApiService.get("/distances/"+id.toString())
        .then(({ data }) => {
          for (const key in data.data) {
            distanceForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    } 

    const editDistance = async (values, {resetForm}) => {
      ApiService.put("/distances/"+values.id,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeDistancePage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    };

    onMounted(() => {
      if(route.params.id) {
        getDistance(parseInt(route.params.id as string));
      }
    });

    return { distanceSchema, editDistance, distanceForm,
      typeValue, };
  },
});
</script>