<template>
  <div class="row">
      <div class="col-md-6 col-xxl-6 col-sm-6 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <h4 class="position-relative text-black fw-bold mb-10">Informations générales</h4>
              <router-link to="/liste-produits" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium">
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
            </div>
            <table class="table">
              <tbody>
                
                <tr v-if="produit?.title">
                  <td class="bl">Nom</td>
                  <td>{{ produit?.title }}</td>
                </tr>
                <tr v-if="produit?.slug">
                  <td class="bl">Slug</td>
                  <td>{{ produit?.slug }}</td>
                </tr>
                <tr v-if="produit?.slot">
                  <td class="bl">Slot</td>
                  <td>{{ produit?.slot }}</td>
                </tr>
                <tr v-if="produit?.numeroSerie">
                  <td class="bl">Numéro série</td>
                  <td>{{ produit?.numeroSerie }}</td>
                </tr>
                <tr v-if="produit?.description">
                  <td class="bl">Description</td>
                  <td>
                    <p v-html="produit?.description"></p>
                    </td>
                </tr>
                <tr v-if="produit?.prixAchat">
                  <td class="bl">prixAchat</td>
                  <td>{{ produit?.prixAchat }}</td>
                </tr>
                <tr v-if="produit?.prixVente">
                  <td class="bl">Prix de vente</td>
                  <td>{{ produit.prixVente }}</td>
                </tr>
                <tr v-if="produit?.prixPromo">
                  <td class="bl">Prix promo</td>
                  <td>{{ produit.prixPromo }}</td>
                </tr>
                <tr >
                  <td class="bl">Est en promo</td>
                  <td v-if="produit?.is_sale">Oui</td>
                  <td v-if="!produit?.is_sale">Non</td>
                </tr>
                <tr v-if="produit?.depot">
                  <td class="bl">Quantité</td>
                  <td>{{ produit?.depot }}</td>
                </tr>
                <tr v-if="produit?.quantiteRupture">
                  <td class="bl">Quantité rupture</td>
                  <td>{{ produit?.quantiteRupture }}</td>
                </tr>
                <tr v-if="produit?.seuilAlerte">
                  <td class="bl">Seuil alerte</td>
                  <td>{{ produit?.seuilAlerte }}</td>
                </tr>
                <tr v-if="produit?.garantie">
                  <td class="bl">Garantie</td>
                  <td>{{ produit?.garantie }}</td>
                </tr>
                <tr v-if="produit?.dateAjout">
                  <td class="bl">Date d'ajout</td>
                  <td>{{ produit?.dateAjout }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xxl-6 col-sm-6 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <h4 class="position-relative text-black fw-bold mb-10">Autres informations</h4>
              <!-- <router-link to="/liste-produits" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium">
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link> -->
            </div>
            <table class="table">
              <tbody>
                <tr v-if="produit?.marchand">
                  <td class="bl">Marchand:</td>
                  <td>{{ produit?.marchand?.nom }} {{ produit?.marchand.prenom }}   {{ produit?.marchand.raisonSocial }}</td>
                </tr>
                <tr >
                  <td class="bl">Catégorie:</td>
                  <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  <div class="col-12">
                      <span v-for="categorie in produit?.categories" :key="categorie.id" class="badge-container">
                        <div class="col-6">
                        <span class="badge badge-outline-danger text-outline-primary">
                          {{ categorie.name }}
                          <a href="javascript:void(0);"
                            class="tag-delete-link text-outline-danger"
                            @click="suppCat(categorie.id,produit?.id)">
                            <i class="flaticon-delete icon-position"></i>
                          </a>
                        </span>
                      </div>
                      </span>
                    </div>
                </td>
                </tr>
                <tr >
                  <td class="bl">Collection:</td>
                  <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                    <div class="col-12">
                      <span v-for="collection in produit?.collections" :key="collection.id" class="badge-container">
                        <div class="col-6">
                        <span class="badge badge-outline-danger text-outline-primary">
                          {{ collection.name }}
                          <a href="javascript:void(0);"
                            class="tag-delete-link text-outline-danger"
                            @click="suppColl(collection.id,produit?.id)">
                            <i class="flaticon-delete icon-position"></i>
                          </a>
                        </span>
                      </div>
                      </span>
                    </div>
                </td>
                </tr>
                <tr v-if="produit?.model">
                  <td class="bl">Model:</td>
                  <td>{{ produit?.model?.nom }}</td>
                </tr>
                <tr >
                  <td class="bl">Marque:</td>
                  <td>
                    <div class="col-12">
                      <span v-for="brand in produit?.brands" :key="brand.id" class="badge-container">
                        <div class="col-6">
                        <span class="badge badge-outline-danger text-outline-primary">
                          {{ brand.name }}
                        </span>
                      </div>
                      </span>
                    </div>
                    
                  </td>
                </tr>
                <tr v-if="produit?.couleur">
                  <td class="bl">Couleur:</td>
                  <td>{{ produit?.couleur?.nom }}</td>
                </tr>
                <tr v-if="produit?.poids">
                  <td class="bl">Poids:</td>
                  <td>{{ produit?.poids }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <table class="table">
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Mail</th>
                  <th>Contenu</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="avis in produit?.avis" :key="avis.id">
                  <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ avis.nom }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ avis.email }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ avis.contenu }}
                </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <!-- <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <h4 class="position-relative text-black fw-bold mb-10">Images</h4>
              <router-link to="/liste-produits" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium">
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
            </div> -->
            <table class="table">
              <tbody>
                <tr>
                    <td>
                        <div class="row ">
                          <tr v-if="produit?.urlImage">
                            <!-- <td class="bl">Image:</td> -->
                            <td><img :src="getUrlApiForProductFiles(produit.urlImage)" alt="Image" ></td>
                          </tr>
                            <div class="col-xl-4 col-lg-6 col-md-6" v-for="(produit, index) in produits" :key="index">
                                <div class="blog-card style1">
                                    <a :href="getUrlApiForProductFiles(produit.description)" target="_blank" data-lightbox="gallery">
                                        <div class="blog-img" >
                                            <img :src="getUrlApiForProductFiles(produit.description)" alt="Image" >
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> 
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "../../../services/ApiService";
import { Produit } from "../../../models/Produit";
import { error, format_Date, showModal, suppression, getUrlApiForProductFiles } from "../../../utils/utils";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default defineComponent({
  name: "ViewProduit",
  setup: () => {
    const route = useRoute();
    const produit = ref<Produit | null>(null);

    function getProduit(id: string) {
      return ApiService.get("/produits", id)
        .then(({ data }) => {
          produit.value = data.data; 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    const produits = ref<Array<Produit>>([]);   

    function getAllMedia(id:any) {
      return ApiService.get(`/produits/medias/`+id)
        .then(({ data }) => {
          produits.value = data.data;
          console.log(produits.value);
          return data;
        })
        .catch(({ response }) => {
          error(response.message)
      });
    }

    onMounted(() => {
      const id = route.params.id as string;
      getProduit(id);
      const idd= route.params.id
      getAllMedia(idd);
    });

    const suppCat = (produitId, categorieId) => {
      // Swal.fire({
      //   text: "Vous êtes sur le point de faire une suppression",
      //   icon: "warning",
      //   buttonsStyling: true,
      //   showCancelButton: true,
      //   confirmButtonText: "Supprimer",
      //   cancelButtonText: `Annuler`,
      //   heightAuto: false,
      //   customClass: {
      //     confirmButton: "btn btn-danger",
      //   },
      // }).then((result) => {
      //   if (result.dismiss === Swal.DismissReason.cancel) {
      //     console.log('Suppression annulée');
      //   } else if (result.isConfirmed) {
      //     ApiService.delete(`/produitcategorie/${categorieId}/${produitId}`)
      //       .then(({ data }) => {
      //         Swal.fire({
      //           title: 'Succès',
      //           text: data.message,
      //           icon: "success",
      //           toast: true,
      //           timer: 5000,
      //           position: 'top-right',
      //           showConfirmButton: false,
      //         });
      //         getProduit(produitId);
      //       }).catch(({ response }) => {
      //         Swal.fire({
      //           text: response.data.message, 
      //           icon: "error",
      //           buttonsStyling: false,
      //           confirmButtonText: "Réessayer à nouveau!",
      //           heightAuto: false,
      //           customClass: {
      //             confirmButton: "btn fw-semobold btn-light-danger",
      //           },
      //         });
      //       });
      //   }
      // }).catch((error) => {
      //   console.error('Erreur lors de l\'affichage de la boîte de dialogue', error);
      // });
      Swal.fire({
      text: "Vous êtes sur le point de faire une suppression",
      icon: "warning",
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      cancelButtonText: `Annuler`,
      heightAuto: false,
      customClass: {
        confirmButton: "btn btn-danger",
      },
    }).then((result) => {
      ApiService.delete(`/produitcategorie/${categorieId}/${produitId}`)
        .then(({ data }) => {
            Swal.fire({
              title: 'Succès',
              text: data.message,
              icon: "success",
              toast: true,
              timer: 5000,
              position: 'top-right',
              showConfirmButton: false,
            });
            getProduit(produitId);
        }).catch(({ response }) => {
          Swal.fire({
            text: response.data.message, 
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Réssayer à nouveau!",
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
      });
      });
    }

    const suppColl = (collectionId, produitId) => {
      Swal.fire({
        text: "Vous êtes sur le point de faire une suppression",
        icon: "warning",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Supprimer",
        cancelButtonText: `Annuler`,
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('Suppression annulée');
        } else if (result.isConfirmed) {
          ApiService.delete(`/collectionproduit/${collectionId}/${produitId}`)
            .then(({ data }) => {
              Swal.fire({
                title: 'Succès',
                text: data.message,
                icon: "success",
                toast: true,
                timer: 5000,
                position: 'top-right',
                showConfirmButton: false,
              });
              getProduit(produitId);
            }).catch(({ response }) => {
              Swal.fire({
                text: response.data.message, 
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Réessayer à nouveau!",
                heightAuto: false,
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            });
        }
      }).catch((error) => {
        console.error('Erreur lors de l\'affichage de la boîte de dialogue', error);
      });
    }


    return { 
      produit,
      getProduit,
      format_Date,
      suppression,
      getUrlApiForProductFiles,
      suppCat,produits,suppColl
     };
  },
});
</script>

<style>
  .bl{
    font-weight: bold;
  }
</style>