<template>
    <BreadCrumb PageTitle="Détails à propos" />
    <ViewContact/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ViewContact from "../../components/ContactSMS/ViewContact.vue";

    export default defineComponent({
      name: "ViewContactPage",
      components: {
        BreadCrumb,
        ViewContact,
      },
    });
  </script>