<template>
    <BreadCrumb PageTitle="Ajouter un secteur d'activité" />
    <AddSecteurActivite />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import AddSecteurActivite from "../../../components/Ecommerce/SecteurActivite/AddSecteurActivite.vue";
  
    export default defineComponent({
      name: "AddSecteurActivitePage",
      components: {
        BreadCrumb,
        AddSecteurActivite,
      },
    });
  </script>