<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form
        ref="produitForm"
        @submit="editProduit"
        :validation-schema="produitSchema"
        :initial-values="produitForm"
      >
        <div class="row">
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Nom <span class="text-danger">*</span>
              </label>
              <Field
                name="title"
                type="text"
                v-model="title"
                class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer le nom"
              />
              <ErrorMessage name="title" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Slug <span class="text-danger">*</span>
              </label>
              <Field
                name="slug"
                type="text"
                readonly="true"
                v-model="slug"
                class="form-control shadow-none fs-md-15 text-black"
                placeholder=""
              />
              <ErrorMessage name="slug" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4" v-if="estMarchand">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Marchand <span class="text-danger">*</span>
              </label>
              <Field
                name="marchand"
                v-model="userName"
                class="form-control shadow-none fs-md-15 text-black input-readonly"
                readonly="true"
              />
              <ErrorMessage name="marchand" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4" v-else>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Marchand <span class="text-danger">*</span>
              </label>
              <Field v-model="march" name="marchand" v-slot="{ field }">
                <Multiselect
                  :options="marchandOptions"
                  :searchable="true"
                  track-by="label"
                  label="label"
                  v-model="field.value"
                  v-bind="field"
                  @select="selectMarchand(field.value)"
                  placeholder="Sélectionnez le marchand"
                />
              </Field>
              <ErrorMessage name="marchand" class="text-danger" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Description du produit <span class="text-danger">*</span>
              </label>
              <div class="mb-0">
                <QuillEditor
                  ref="descriptionEditor"
                  theme="snow"
                  placeholder="Écrivez la description"
                  toolbar="full"
                  name="description"
                />
              </div>
              <ErrorMessage name="description" class="text-danger" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div
                class="col-2 shadow-none lh-1 fw-medium text-black-emphasis"
                v-for="(fiche, index) in fichiers"
                :key="index"
              >
                <div
                  class="card mb-25 border-0 rounded-0 bg-white single-product-box"
                >
                  <div class="card-body p-0 letter-spacing">
                    <div class="image position-relative">
                      <a class="d-block">
                        <img
                          :src="getUrlApiForProductFiles(fiche.name)"
                          alt="Image"
                          style="filter: invert(0)"
                        />
                      </a>
                    </div>
                    <div class="content p-20 d-flex justify-content-center">
                      <a
                        class="badge badge-outline-danger text-outline-primary mx-2 justify-content-center"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#AddImageModal"
                        @click="showImage(getUrlApiForProductFiles(fiche.name))"
                      >
                        <i class="flaticon-eye"></i>
                      </a>

                      <a
                        class="badge badge-outline-danger text-danger text-outline-primary mx-2 justify-content-center"
                        type="button"
                        @click="supprimerFichierParId(fiche.id)"
                      >
                        <i class="flaticon-delete"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="product-upload mb-10">
              <p>Images</p>
              <div class="dz-message needsclick">
                <DropZone
                  name="fichiers[]"
                  :maxFilesize="5000"
                  :maxFiles="Number(10000000000)"
                  :uploadOnDrop="true"
                  :multipleUpload="true"
                  :parallelUpload="3"
                  :filesizeBase="5000"
                  @thumbnail="onThumbnail"
                  @complete="onComplete"
                  @addedFile="onFileAdd"
                  ref="dropzoneRef"
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Image principale<span class="text-danger">*</span>
                <a
                  style="float: right"
                  class="badge badge-outline-danger text-outline-primary"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#AddImageModal"
                  @click="showImage(getUrlApiForProductFiles(produit.urlImage))"
                >
                  Voir l'image
                  <i class="flaticon-eye lh-1 me-8 position-relative top-1"></i>
                </a>
              </label>
              <Field
                name="urlImage"
                type="file"
                accept="image/jpeg, image/png application/pdf"
                class="form-control shadow-none fs-md-15 text-black"
                placeholder="Choisissez une image"
              />
              <ErrorMessage name="urlImage" class="text-danger" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Quantite <span class="text-danger">*</span>
              </label>
              <Field
                name="depot"
                type="text"
                class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer la quantité"
              />
              <ErrorMessage name="depot" class="text-danger" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Quantité rupture
              </label>
              <Field
                name="quantiteRupture"
                type="text"
                class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer la quantité de rupture"
              />
              <ErrorMessage name="quantiteRupture" class="text-danger" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Numéro de série
              </label>
              <Field
                name="numeroSerie"
                type="text"
                class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer le numéro de série"
              />
              <ErrorMessage name="numeroSerie" class="text-danger" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Prix de vente<span class="text-danger">*</span>
              </label>
              <Field
                name="price"
                type="text"
                class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrez le prix de vente"
              />
              <ErrorMessage name="price" class="text-danger" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Couleur <span class="text-danger">*</span>
              </label>
              <Field name="couleur" v-slot="{ field }">
                <Multiselect
                  :options="couleurOptions"
                  :searchable="true"
                  track-by="label"
                  label="label"
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Sélectionnez la couleur"
                />
              </Field>
              <ErrorMessage name="couleur" class="text-danger" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Prix d'achat
              </label>
              <Field
                name="prixAchat"
                type="text"
                class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrez le prix d'achat"
              />
              <ErrorMessage name="prixAchat" class="text-danger" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Seuil alerte
              </label>
              <Field
                name="seuilAlerte"
                type="text"
                class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrez le seuil"
              />
              <ErrorMessage name="seuilAlerte" class="text-danger" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Prix promo <span class="text-danger">*</span>
              </label>
              <Field
                name="sale_price"
                type="text"
                class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer le nouveau prix"
              />
              <ErrorMessage name="sale_price" class="text-danger" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Poids (kg)<span class="text-danger">*</span>
              </label>
              <Field
                name="poids"
                type="text"
                class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer le poids"
              />
              <ErrorMessage name="poids" class="text-danger" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Marque <span class="text-danger">*</span>
              </label>
              <Field name="marque" v-slot="{ field }" v-model="marque">
                <Multiselect
                  :options="marqueOptions"
                  :searchable="true"
                  track-by="label"
                  label="label"
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Sélectionnez la marque"
                />
              </Field>
              <ErrorMessage name="marque" class="text-danger" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Modèle <span class="text-danger">*</span>
              </label>
              <Field name="model" v-slot="{ field }">
                <Multiselect
                  :options="modeleOptions"
                  :searchable="true"
                  track-by="label"
                  label="label"
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Sélectionnez le modèle"
                />
              </Field>
              <ErrorMessage name="model" class="text-danger" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Taille <span class="text-danger">*</span>
              </label>
              <Field name="taille" v-slot="{ field }">
                <Multiselect
                  :options="tailleOptions"
                  :searchable="true"
                  track-by="label"
                  label="label"
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Sélectionnez la taille"
                />
              </Field>
              <ErrorMessage name="taille" class="text-danger" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit"
              >
                Modifier un produit
              </button>
              <router-link
                to="/liste-produits"
                class="btn btn-danger transition border-0 lh-1 fw-medium"
                ><i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"
                ></i>
                <span class="position-relative"></span>Annuler</router-link
              >
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>

  <div
    class="modal fade createNewModal"
    id="AddImageModal"
    tabindex="-1"
    ref="addAddImageModalRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">Image actuelle</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25 text-center">
          <img :src="urlAffichage" alt="Image" v-if="urlAffichage" />
          <h3 v-else>Aucune image</h3>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, nextTick } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import ApiService from "../../../services/ApiService";
import {
  error,
  success,
  getUrlApiForProductFiles,
  urlToFile,
  getUrlApiForFiles,
  generateSlug,
  cryptage,
  decryptage,
} from "../../../utils/utils";
import { useRoute, useRouter } from "vue-router";
import { Produit } from "../../../models/Produit";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import DropZone from "dropzone-vue";
import JwtService from "@/services/JwtService";

interface DropzoneComponent {
  dropzone: DropZone; // Utilisez un type approprié pour Dropzone
}

export default defineComponent({
  name: "EditProduit",
  components: {
    Form,
    Field,
    DropZone,
    ErrorMessage,
    Multiselect,
  },
  setup: () => {
    const produitSchema = Yup.object().shape({
      title: Yup.string().required("Le titre est obligatoire"),
      numeroSerie: Yup.string().required("Le numéro de série est obligatoire"),
      description: Yup.string().required(
        "La description du produit est obligatoire"
      ),
      urlImage: Yup.string().notRequired(),
      slug: Yup.string().notRequired(),
      depot: Yup.number()
        .typeError("Veuillez entrer des nombres")
        .required("La quantité est obligatoire"),
      quantiteRupture: Yup.number().notRequired(),
      prixAchat: Yup.number().notRequired(),
      price: Yup.number()
        .typeError("Veuillez entrer des nombres")
        .required("Le prix d'achat est obligatoire"),
      seuilAlerte: Yup.number().notRequired(),
      //categorie: Yup.string().required('La catégorie est obligatoire'),
      marchand: Yup.string().required("Le marchand est obligatoire"),
      // marque: Yup.string().required('La marque est obligatoire'),
      // model: Yup.string().required('Le modèle est obligatoire'),
      // taille: Yup.string().required('La taille est obligatoire'),
      // couleur: Yup.string().required('La couleur est obligatoire'),
      poids: Yup.number()
        .typeError("Veuillez entrer des nombres")
        .required("Le poids est obligatoire"),
    });

    const produitForm = ref<Produit>();
    const router = useRouter();
    const tailleOptions = ref([]);
    const taille = ref();
    const poidsOptions = ref([]);
    const couleurOptions = ref([]);
    const modeleOptions = ref([]);
    const marqueOptions = ref([]);
    const categoriesOptions = ref([]);
    const route = useRoute();
    const descriptionEditor = ref();
    const produit = ref<any | null>(null);
    const categoriesMarchandOptions = ref([]);
    const urlAffichage = ref("");

    const title = ref();
    const slug = ref();
    const vendor = ref();
    const march = ref();
    const marchandOptions = ref();
    const estMarchand = ref();
    const userName = ref("");

    const defaultImage = getUrlApiForFiles("sansIm.jpg");
    const hasFiles = ref(false);
    const dropzoneRef = ref<DropzoneComponent | null>(null);
    const files = ref([]);
    const toutFichier = ref<Array<File>>([]);
    const lesFichiers = ref<Array<File>>([]);
    const fichiers = ref<Array<any>>([]);
    const marque = ref();

    const dropzoneOptions = {
      url: "/upload", // URL de téléchargement des fichiers
      thumbnailWidth: 150,
      maxFilesize: 2,
      headers: { "My-Awesome-Header": "header value" },
    };

    const onSuccess = (file) => {
      hasFiles.value = true; // Mettre à jour l'indicateur lorsqu'un fichier est déposé avec succès
    };

    const onError = (file, message) => {
      console.error("File upload error:", message);
    };

    const selectMarchand = (a) => {
      const [id, codeMarchand] = a.split("|");
      const code = a.split("|");
      vendor.value = code[2];
      march.value = id;
    };

    watch(title, (newValue) => {
      slug.value = generateSlug(newValue);
    });

    function getProduitView(id: string) {
      return ApiService.get("/produits/" + id)
        .then(({ data }) => {
          produit.value = data.data;
          urlAffichage.value = getUrlApiForProductFiles(produit.value.urlImage);
        })
        .catch(({ response }) => {
          // error(response.data.message);
        });
    }

    const fetchMarchands = async () => {
      try {
        const response = await ApiService.get("/marchands?limit=0&");
        const marchandsData = response.data.data.data;
        marchandOptions.value = marchandsData.map((marchands) => ({
          value: `${marchands.id}|${marchands.nom}|${marchands.codeMarchand}`,
          label:
            marchands.raisonSocial != null
              ? `${marchands.codeMarchand} | ${marchands.raisonSocial}`
              : `${marchands.codeMarchand} | ${marchands.nom} ${marchands.prenom}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchPoids = async () => {
      try {
        const response = await ApiService.get("/poids");
        const poidsData = response.data.data.data;
        poidsOptions.value = poidsData.map((poids) => ({
          value: poids.id,
          label: `${poids.poidsMin} - ${poids.poidsMax}`,
        }));
      } catch (error) {
        //
      }
    };

    const getProduit = async (id: number) => {
      try {
        const data = await axios.get("/produits/" + id.toString());
        console.log("(((((((((((<==>))))))))))) ", data);
        descriptionEditor.value.setHTML(data.data.description);
        const donnees = data.data.data;
        for (const key in donnees) {
          if (key != "thumbnail") {
            produitForm.value?.setFieldValue(
              key,
              typeof data.data[key] === "object" && data.data[key] !== null
                ? data.data[key].id
                : data.data[key]
            );
          }
        }
        console.log("(((((((((((<==>))))))))))) ", donnees);
        march.value = donnees.marchand?.id;
        marque.value = donnees.brands[0] ? donnees.brands[0].id : "";
        console.log("TYTYTYTYTYTYTY  ===> ", march.value);
        console.log("POPOPOPOPOPOPO  ===> ", marque.value);
      } catch (response) {
        console.log("TYTYTYTYTYTYTY222  ===> ", response);
        //error(response.data.message);
      }
    };

    const editProduit = async (values, { resetForm }) => {
      values["description"] = descriptionEditor.value.getHTML();
      values["slug"] = slug.value;
      values["userId"] = parseInt(JwtService.getUser()!);
      if (JwtService.getIfMarchand(false)!) {
        values["marchand"] = parseInt(JwtService.getIdMarchand()!);
        values["vendor"] = JwtService.getCodeMarchand();
      } else {
        values["marchand"] = parseInt(march.value);
        values["vendor"] = vendor.value;
      }
      if (toutFichier.value.length > 0) {
        values["autreFichier[]"] = [];
        values["autreFichier[]"] = toutFichier.value.map((fichier) => fichier);
      }
      axios
        .put("/produits/" + values.id, values, {
          headers: { "Content-Type": "multipart/form-data", Accept: "*/*" },
        })
        .then(({ data }) => {
          if (data.code == 200) {
            success(data.message);
            // resetForm();
            router.push({ name: "ListeProduitPage" });
          }
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    };

    const showImage = async (url = "") => {
      urlAffichage.value = url;
    };

    const onFileAdd = (file: any) => {
      console.log("Le fichier est le suivant ===> ", file);
      toutFichier.value.push(file["file"]);
    };
    const onThumbnail = (file, dataUrl) => {
      console.log("Thumbnail created for file:", file);
    };

    const onComplete = (file) => {
      console.log("Upload complete for file:", file);
    };

    const onFileRemove = (file: File) => {
      const index = toutFichier.value.findIndex(
        (fil) =>
          fil.name === file["file"].name &&
          fil.size === file["file"].size &&
          fil.type === file["file"].type
      );
      if (index !== -1) {
        toutFichier.value.splice(index, 1);
      } else {
        console.log(
          `Le fichier ${file["file"].name} n'a pas été trouvé dans le tableau.`
        );
      }
    };

    const fetchTailles = async () => {
      try {
        const response = await ApiService.get("/tailles?limit=0&");
        const taillesData = response.data.data.data;
        tailleOptions.value = taillesData.map((taille) => ({
          value: taille.id,
          label: `${taille.tailleMin} - ${taille.tailleMax}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchCouleurs = async () => {
      try {
        const response = await ApiService.get("/couleurs?limit=0&");
        const couleursData = response.data.data.data;
        couleurOptions.value = couleursData.map((couleur) => ({
          value: couleur.id,
          label: `${couleur.nom}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchModeles = async () => {
      try {
        const response = await ApiService.get("/models?limit=0&");
        const modelesData = response.data.data.data;
        modeleOptions.value = modelesData.map((modele) => ({
          value: modele.id,
          label: `${modele.nom}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchMarques = async () => {
      try {
        const response = await ApiService.get("/marques?limit=0&");
        const marquesData = response.data.data.data;
        marqueOptions.value = marquesData.map((marque) => ({
          value: marque.id,
          label: `${marque.name}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchCatgories = async () => {
      try {
        const response = await ApiService.get("/categories");
        const categoriesData = response.data.data.data;
        categoriesOptions.value = categoriesData.map((categories) => ({
          value: categories.id,
          label: `${categories.name}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchCatgoriesMarchand = async (userId) => {
      try {
        const response = await ApiService.get(
          `/categories/marchands/${parseInt(userId)}`
        );
        const catMarchandsData = response.data.data;
        categoriesMarchandOptions.value = catMarchandsData.map(
          (categories) => ({
            value: categories.id,
            label: `${categories.name}`,
          })
        );
      } catch (error) {
        //
      }
    };
    const supprimerFichierParId = async (id: any) => {
      console.log("GHHHGHGH ===> ", id);
      const motCry = await cryptage(id);
      console.log("YTTTTTTTTTTYTYTYTYTYTYTYTYTY ===> ", motCry);
      Swal.fire({
        text: "Vous êtes sur le point de supprimer cette image ?",
        icon: "warning",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Supprimer",
        cancelButtonText: `Annuler`,
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ApiService.delete(`/delete/${motCry}/media`)
            .then(({ data }) => {
              Swal.fire({
                title: "Succès",
                text: data.message,
                icon: "success",
                toast: true,
                timer: 5000,
                position: "top-right",
                showConfirmButton: false,
              });
              fichiers.value = fichiers.value.filter(
                (fichier) => fichier.id !== id
              );
            })
            .catch(({ response }) => {
              Swal.fire({
                text: response.data.message, //'Oups il y a un problème',//
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Réssayer à nouveau!",
                heightAuto: false,
                customClass: {
                  confirmButton: "btn fw-semobold btn-light-danger",
                },
              });
            });
        } else if (result.isDenied) {
          Swal.fire("La suppression n'est pas passée", "", "info");
        }
      });
    };
    // Fonction pour charger les fichiers existants
    const loadExistingFiles = async (produitId) => {
      try {
        const response = await axios.get(`/all/media/${produitId}/produit`);
        console.log("Les image sont les suivant ==> ", response);
        const existingFiles = response.data;
        for (const medi of existingFiles) {
          console.log(
            "LE CHEMIN EST CELUI LA ===>",
            getUrlApiForProductFiles(medi.name)
          );
          // const file = await urlToFile(getUrlApiForProductFiles(medi.name), medi.name, medi.mime);
          fichiers.value.push(medi);
          // if (file) {
          //   lesFichiers.value.push(file);
          // }
        }
        console.log("Les images  ==> ", lesFichiers.value);

        await nextTick();
        const dz = dropzoneRef.value?.dropzone;
        console.log("Lisons ce qu'il y a dans dz ====> ", dz);
        console.log("Lisons ce qu'il y a dans dz.emit ====> ", dz.emit);
        if (dz) {
          console.log("Dropzone instance methods:", Object.keys(dz));
        }
        if (dz) {
          // existingFiles.forEach(file => {
          //   const imageUrl = getUrlApiForProductFiles(`${file.name}`);
          //   const mockFile = {
          //     name: file.name,
          //     size: file.size,
          //     accepted: true,
          //     serverFile: true
          //   };

          //   //dz.emit("addedfile", mockFile);
          //   dz.addFile(mockFile);
          //   dz.emit("thumbnail", mockFile, imageUrl);
          //   dz.emit("complete", mockFile);
          //   dz.files.push(mockFile);
          // });
          hasFiles.value = true;
        }
        hasFiles.value = true;
      } catch (error) {
        console.error(
          "Erreur lors du chargement des fichiers existants",
          error
        );
      }
    };

    onMounted(async () => {
      if (route.params.id) {
        getProduit(parseInt(route.params.id as string));
      }
      if (route.params.id) {
        getProduitView(route.params.id as string);
        const crypter = await cryptage(route.params.id as string);
        await loadExistingFiles(crypter);
      }
      fetchCatgories();
      fetchMarques();
      fetchModeles();
      fetchCouleurs();
      fetchTailles();
      fetchMarchands();
      fetchPoids();
      if (JwtService.getUser()) {
        userName.value = JwtService.getUserName()!;
        estMarchand.value = JwtService.getIfMarchand(false)!;
        fetchCatgoriesMarchand(JwtService.getUser());
      }
    });

    return {
      produitSchema,
      editProduit,
      produitForm,
      onFileRemove,
      onFileAdd,
      categoriesOptions,
      marqueOptions,
      modeleOptions,
      couleurOptions,
      dropzoneOptions,
      dropzoneRef,
      tailleOptions,
      showImage,
      urlAffichage,
      getUrlApiForProductFiles,
      onThumbnail,
      onComplete,
      produit,
      descriptionEditor,
      selectMarchand,
      title,
      slug,
      hasFiles,
      fichiers,
      defaultImage,
      supprimerFichierParId,
      marchandOptions,
      march,
      marque,
      estMarchand,
      categoriesMarchandOptions,
      userName,
      poidsOptions,
      onSuccess,
      onError,
    };
  },
});
</script>