<template>
    <BreadCrumb PageTitle="Liste des tailles" />
    <ListeTaille/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import ListeTaille from "../../../components/Ecommerce/Taille/ListeTaille.vue";

    export default defineComponent({
      name: "ListeTaillePage",
      components: {
        BreadCrumb,
        ListeTaille,
      },
    });
  </script>