<template>
    <BreadCrumb PageTitle="Ajouter une catégorie d'article" />
    <AddCategorieArticle />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddCategorieArticle from "../../components/CategorieArticle/AddCategorieArticle.vue";
  
    export default defineComponent({
      name: "AddCategorieArticlePage",
      components: {
        BreadCrumb,
        AddCategorieArticle,
      },
    });
  </script>