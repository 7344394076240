<template>
  <div class="row">
      <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <router-link to="/liste-alaUnes" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium" >
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
              
            </div>
            <table class="table">
              <tbody>
                <tr v-if="alaUne?.urlImage">
                  <td class="bl">Image:</td>
                  <td><img :src="getUrlApiForFiles(alaUne.urlImage)" alt="Image" width="200" height="200"></td>
                </tr>
                <tr v-if="alaUne?.titre">
                  <td class="bl">Titre:</td>
                  <td>{{ alaUne?.titre }}</td>
                </tr>
                <tr v-if="alaUne?.subtitle">
                  <td class="bl">Sous titre:</td>
                  <td>{{ alaUne?.subtitle }}</td>
                </tr>
                <tr v-if="alaUne?.description">
                  <td class="bl">Description:</td>
                  <td v-html="alaUne.description"></td>
                </tr>
                <tr v-if="alaUne?.link">
                  <td class="bl">Lien:</td>
                  <td>{{ alaUne?.link }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { error, format_date,separateur,format_Date, getUrlApiForFiles } from "@/utils/utils";
import { useRoute } from "vue-router";
import { AlaUne } from "@/models/AlaUne";
import ApiService from "@/services/ApiService";

export default defineComponent({
  name: "ViewAlaUne",
  setup: () => {
    const route = useRoute();
    const alaUne = ref<AlaUne | null>(null);
    const alaUnes = ref<Array<AlaUne>>([]);
    function getAlaUne(id: string) {
      return ApiService.get("/alaUnes/"+id)
        .then(({ data }) => {
          alaUne.value = data.data; 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    onMounted(() => {
      const id = route.params.id as string;
      getAlaUne(id);
    });

    return { alaUne, getAlaUne, format_date,format_Date, alaUnes,separateur, getUrlApiForFiles };
  },
});
</script>
<style>
  .bl{
    font-weight: bold;
  }
</style>
