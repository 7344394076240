<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
      >
        <div class="d-sm-flex align-items-center">
        </div>
        <div class="d-flex align-items-center">
          <form class="search-box position-relative me-15" @submit.prevent="rechercher">
            <input
              type="text"
              v-model="searchTerm"
              @keyup="rechercher"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Rechercher une livraison"
            />
            <button
              type="submit"
              class="bg-transparent text-primary transition p-0 border-0"
            >
              <i class="flaticon-search-interface-symbol"></i>
            </button>
          </form>
          <!-- <button
            class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
            type="button"
          >
            <i class="flaticon-dots"></i>
          </button> -->
        </div>
      </div>
      <div class="card-body p-15 p-sm-20 p-md-25">
        <div class="table-responsive">
          <table class="table text-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                Commande
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >Livreur
                  
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >DATE
                  
                </th>
                <!-- <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                >Actions</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(commande, index) in commandes" :key="index">
                <td class="shadow-none lh-1 fw-medium text-primary">
                  {{ commande?.refCommande }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  <span v-for="user in commande.users" :key="user.id">{{  user.nomComplet }}</span>
                  <!-- {{ commande?.users.length }} -->
                </td>
                <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                  {{ format_date(commande.createdAt)  }}
                </td>
                <!-- <td
                  class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                >
                <div class="dropdown">
                    <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        Actions
                        <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                    </span>
                    <ul class="dropdown-menu">
                      <li v-if="checkPermission('DeleteUser')">
                        <a
                          class="dropdown-item d-flex align-items-center" href="javascript:void(0);" @click="suppression(commande.id,commandes,'commandes',`la commande ${commande.code}`)">
                          <i class="flaticon-delete lh-1 me-8 position-relative top-1" ></i>
                           Supprimer
                        </a>
                      </li>
                    </ul>
                </div>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center">
          
          <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref  } from "vue";
  import { User } from "@/models/users";
  import ApiService from "@/services/ApiService";
  import { format_date, showModal, suppression, error } from "../../utils/utils";
  import PaginationComponent from '@/components/Utilities/Pagination.vue';
  import JwtService from "@/services/JwtService";
import axios from "axios";
  
  export default defineComponent({
    name: "ListeUser",
    components: {
      PaginationComponent
    },
    setup: () => {
  
      onMounted(() => {
        getAllLivraisons();
      });
  
      const commandes = ref<Array<User>>([]);
      const idUser = ref(0);
      const selectedItem = ref(0);
  
      // BEGIN PAGINATE
      const searchTerm = ref('');
      const page = ref(1);
      const totalPages = ref(0);
      const limit = ref(10);
      const totalElements = ref(0);
      const userId = JwtService.getUser()!;
  
      const handlePaginate = ({ page_, limit_ }) => {
        try {
          page.value = page_;
          getAllLivraisons(page_, limit_);
        } catch (error) {
          //
        }
      };
      function rechercher(){
        getAllLivraisons(page.value, limit.value, searchTerm.value );
      }
      // END PAGINATE
      
  
      const editUser = (id: number) => {
        selectedItem.value = id;
      };
  
      function getAllLivraisons(page = 1, limi = 10, searchTerm = '') {
        return axios.get(`/commandes?page=${page}&limit=${limi}&mot=${searchTerm}&`,{
            headers: {
                userId : userId
            }
        })
          .then(({ data }) => {
            const donnees = data.data.data;
            commandes.value = donnees.filter(commande => commande.users.length != 0);
            totalPages.value = data.data.totalPages;
            limit.value = data.data.limit;
            totalElements.value = data.data.totalElements;
          })
          .catch(({ response }) => {
            error(response.data.message)
        });
      }
      
      function moddifier(EditUser:User) {
        idUser.value = EditUser.id;
      }
  
      function showModalEdite(model){
        showModal(model);
        idUser.value=0;
      }
  
      const recharger = () => {
        getAllLivraisons();
      };
  
      const privileges = ref<Array<string>>(JwtService.getPrivilege());
  
        const checkPermission = (name) => {
          return privileges.value.includes(name);
        }
  
      return {suppression,
        checkPermission,
        commandes,
        format_date,
        getAllLivraisons,
        idUser,
        moddifier,
        showModalEdite,
        editUser,
        selectedItem ,
        page, 
        totalPages,
        limit,
        totalElements,
        handlePaginate,
        searchTerm,
        rechercher,
        recharger,
        userId,
       };
    },
  
  
  });
  </script>