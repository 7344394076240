<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="faqForm" @submit="editFaq" :validation-schema="faqSchema">
      <div class="row">
            <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Question <span class="text-danger">*</span>
                  </label>
                  <div class="mb-0">
                    <QuillEditor
                    theme="snow"
                    placeholder="Écrivez le contenu de votre article"
                    toolbar="full"
                    name="question"
                    ref="questionEditor"
                  /> 
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="question" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Réponse <span class="text-danger">*</span>
                  </label>
                  <div class="mb-0">
                <QuillEditor
                  theme="snow"
                  placeholder="Écrivez le contenu de votre article"
                  toolbar="full"
                  name="reponse"
                  ref="reponseEditor"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="reponse" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Catégorie de faq <span class="text-danger">*</span>
                </label>
                <Field  name="categorieFaq"  v-slot="{ field }">
                  <Multiselect
                    :options="categorieOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionnez la categorie"
                  />
                </Field>  
                <ErrorMessage name="categorieFaq" class="text-danger"/>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Tags <span class="text-danger">*</span>
                </label>
                <Field  name="tags"  v-slot="{ field }" v-model="defaultSelectedTags">
                  <Multiselect
                    :options="tagsOptions"
                    :searchable="true"
                    track-by="label"
                    multiple="true"
                    mode="tags"
                    label="label"
                    v-bind = "field"
                    placeholder="Sélectionnez le tag"
                  />
                </Field>  
                <ErrorMessage name="tags" class="text-danger"/>
              </div>
            </div> -->
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit" @click="onSubmit()"
            >
                Modifier un faq
            </button>
            <router-link to="/liste-faqs" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">

import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '../../services/ApiService';
import { error, success } from '../../utils/utils';
import { useRoute, useRouter } from 'vue-router';
import { Faq } from '../../models/Faq';
import Multiselect from '@vueform/multiselect'

export default defineComponent({
    name: "EditFaq",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const faqSchema = Yup.object().shape({
      // question: Yup.string().required('La question est obligatoire'),
      // reponse: Yup.string().required('La réponse est obligatoire'),
      categorieFaq: Yup.string().required('La catégorie est obligatoire'),
      //tags: Yup.array().required('Le tag est obligatoire'),
    });

    const faqForm = ref<Faq>();
    const router = useRouter();
    const route = useRoute();
    const categorieOptions = ref([]);
    const tagsOptions = ref([]);

    const questionEditor = ref();
    const reponseEditor = ref();
    const defaultSelectedTags = ref();
    const empty = ref("<p><br></p>")

  function getFaq(id) {
    ApiService.get('/faqOnlys/'+ id.toString())
      .then(({ data }) => {
        questionEditor.value.setHTML(data.data.question);
        reponseEditor.value.setHTML(data.data.reponse);
        const donnees = data.data;
        // const faqtags = donnees.faqtags;
        // let ids: number[] = [];
        // for (const faqtag of faqtags) {
        //   ids.push(faqtag.id); 
        // }
        // faqtags.value= ids;
        //defaultSelectedTags.value = faqtags.value;
        console.log(id.toString())
        console.log(data.data)
        console.log(data.data.question)
        for (const key in data.data) {
          faqForm.value?.setFieldValue(
            key,
            typeof data.data[key] === 'object' && data.data[key] !== null
              ? data.data[key].id
              : data.data[key]
          );
        }
      })
      .catch(({ response }) => {
        //error(response.data.message);
      });
  }

    const showA = ref(false)
    const show = ref("0")
    const onSubmit = () => {
      if(questionEditor.value.getHTML() !== empty.value || reponseEditor.value.getHTML() !== empty.value) {
        showA.value = false;
      }
      else if(questionEditor.value.getHTML() === empty.value || reponseEditor.value.getHTML() === empty.value) {
        showA.value = true;
        show.value = "1"
      }
    }

    const editFaq = async (values, {resetForm}) => {
      values['question'] = questionEditor.value.getHTML();
      values['reponse'] = reponseEditor.value.getHTML();
      console.log('rjog',values);
      ApiService.put("/faqs/"+values.id,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeFaqPage" });
          }
        }).catch(({ response }) => {
          //error(response.data.message);
      });
    };

    // const fetchTags = async () => {
    //   try {
    //     const response = await ApiService.get('/tags');
    //     const tagsData = response.data.data.data;
    //     tagsOptions.value = tagsData.map((tags) => ({
    //       value: tags.id,
    //       label: `${tags.nom}`,
    //     }));
    //   } catch (error) {
    //     //
    //   }
    // };

    onMounted(() => {
      if(route.params.id) {
        getFaq(parseInt(route.params.id as string));
      }
      fetchCategorie();
      //fetchTags();
    });

    const fetchCategorie = async () => {
      try {
        const response = await ApiService.get('/categorieFaqs');
        const categorieData = response.data.data.data;
        categorieOptions.value = categorieData.map((categorie) => ({
          value: categorie.id,
          label: `${categorie.code} - ${categorie.nom}`,
        }));
      } catch (error) {
        //
      }
    };

    return { faqSchema, editFaq, faqForm, categorieOptions, tagsOptions, questionEditor, reponseEditor,
      defaultSelectedTags, onSubmit, showA, show
      };
  },
});
</script>