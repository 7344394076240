<template>
  <div class="card mb-25 border-0 rounded-0 bg-white">
    <!-- <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <div
        class="mb-15 mb-md-30 d-sm-flex align-items-center justify-content-between"
      >
        <h6 class="card-title fw-bold mb-0">Les dernières demandes</h6>
       <div
          class="card-select mt-10 mt-sm-0 mb-10 mb-sm-0 d-flex align-items-center ps-10 pe-10 pt-5 pb-5"
        >
          <span class="fw-medium text-muted me-8">Statut</span>
          <select
            class="form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium"
          >
            <option selected class="fw-medium">Tous les types</option>
            <option value="1" class="fw-medium">Abonnement</option>
            <option value="1" class="fw-medium">Attestation</option>
            <option value="1" class="fw-medium">Transfert</option>
            <option value="1" class="fw-medium">Resiliation</option>
            <option value="1" class="fw-medium">Cession</option>
          </select>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Demandeur
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Code
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Type
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Date
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Statut
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(dem, index) in demandes" :key="index">
              <th class="shadow-none lh-1 fw-bold ps-0">
                  {{ dem.abonnement?.abonne?.nomComplet }}
              </th>
              <td class="shadow-none lh-1 fw-semibold text-black-emphasis">
                {{ dem.refDemande }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-body-tertiary">
                {{ dem.typeDemande.libelle }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-body-tertiary">
                {{ format_date(dem.createdAt) }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                <span v-if="dem.status=='En attente'" class="badge text-outline-danger">{{ dem.status }}</span>
                <span  v-else-if="dem.status=='Validée'" class="badge text-outline-success">{{ dem.status }}</span>
                <span  v-else-if="dem.status=='Acceptée'" class="badge text-outline-warning">{{ dem.status }}</span>
                <span v-else class="text-outline-info">{{ dem.status }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent,ref,onMounted } from "vue";
import stockReport from "./stockReport.json";
import axios from 'axios';
import {separateur, format_date} from '../../../../utils/utils'

export default defineComponent({
  name: "StockReport",
  setup: () => {
    const demandes = ref([]);
    const fetchRedevances =async () => {
      try {
        const response = await axios.post(`/etats/abonnements/dix/demande`,{});
        demandes.value = response.data.data;
        return demandes.value;
      } catch (error) {
        //
      }
    }
    onMounted(async () => {
     //await fetchRedevances();
    });
    return {
      demandes,
      format_date,
      stocks: stockReport,
    };
  },
});
</script>