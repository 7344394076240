<template>
  <div class="row">
      <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
              <h4 class="position-relative text-black fw-bold mb-10">Informations générales</h4>
              <router-link to="/liste-clients" 
              class=" btn btn-primary transition border-0 lh-1 fw-medium">
              <i class="flaticon-left-arrow  lh-1 me-1 position-relative top-2"></i>
              <span class="position-relative"></span>Retour</router-link>
            </div>
            <table class="table">
              <tbody>
                <tr v-if="client?.nomComplet">
                  <td>Nom</td>
                  <td>{{ client?.nomComplet }}</td>
                </tr>
                <!--<tr v-if="client?.prenom">
                  <td>Prénom</td>
                  <td>{{ client?.prenom }}</td>
                </tr>-->
                <tr v-if="client?.sexe">
                  <td>Sexe</td>
                  <td>{{ client?.sexe }}</td>
                </tr>
                <tr v-if="client?.email">
                  <td>Email</td>
                  <td>{{ client?.email }}</td>
                </tr>
                <tr v-if="client?.numCompte">
                  <td>Numéro de compte</td>
                  <td>{{ client.numCompte }}</td>
                </tr>
                <tr v-if="client?.telephone">
                  <td>Téléphone</td>
                  <td>{{ client?.telephone }}</td>
                </tr>
                <tr v-if="client?.typeCarte">
                  <td>Type Carte</td>
                  <td>{{ client?.typeCarte }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "../../../services/ApiService";
import { Client } from "../../../models/Client";
import { error, format_Date, showModal } from "../../../utils/utils";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ViewClient",
  setup: () => {
    const route = useRoute();
    const client = ref<Client | null>(null);

    function getClient(id: string) {
      return ApiService.get("/clients", id)
        .then(({ data }) => {
          client.value = data.data; 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    onMounted(() => {
      const id = route.params.id as string;
      getClient(id);
    });

    return { 
      client,
      getClient,
      format_Date,
     };
  },
});
</script>
