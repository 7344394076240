<template>
    <BreadCrumb PageTitle="Liste des promotions" />
    <ListePromotion/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import ListePromotion from "../../../components/Ecommerce/Promotion/ListePromotion.vue";

    export default defineComponent({
      name: "ListePromotionPage",
      components: {
        BreadCrumb,
        ListePromotion,
      },
    });
  </script>