<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="promotionForm" @submit="editPromotion" :validation-schema="promotionSchema">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Nom <span class="text-danger">*</span>
                </label>
                <Field name="nomPromo" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nom"/>
                <ErrorMessage name="nomPromo" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Taux
                </label>
                <Field name="taux" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le taux"/>
                <ErrorMessage name="taux" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Date de début
                  </label>
                  <Field name="dateDebut" type="date" v-model="dateDFormatted"
                  class="form-control shadow-none fs-md-15 text-black"/>
                  <ErrorMessage name="dateDebut" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Date de fin
                  </label>
                  <Field name="dateFin" type="date" v-model="dateFFormatted"
                  class="form-control shadow-none fs-md-15 text-black"/>
                  <ErrorMessage name="dateFin" class="text-danger"/>
                </div>
              </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Modifier une promotion
            </button>
            <router-link to="/liste-promotions" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">

import { defineComponent, ref, onMounted, computed } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '../../../services/ApiService';
import { error, success } from '../../../utils/utils';
import { useRoute, useRouter } from 'vue-router';
import { Promotion } from '../../../models/Promotion';

export default defineComponent({
    name: "EditPromotion",
    components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup: () => {
    const promotionSchema = Yup.object().shape({
      nomPromo: Yup.string().required('Le nom est obligatoire'),
      taux: Yup.number().typeError('Veuillez entrer des chiffres').required('Le taux est obligatoire'),
      // dateDebut: Yup.string().required('La date de début est obligatoire'),
      // dateFin: Yup.string().required('La date de fin est obligatoire'),
      dateFin: Yup.date().typeError("Entrer une date valide")
        .required('La date de fin est obligatoire')
        .min(Yup.ref('dateDebut'), 'La date de fin ne peut pas être antérieure à la date de début'),
        dateDebut: Yup.date().typeError("Entrer une date valide")
        .required('La date de fin est obligatoire')
    });

    const promotionForm = ref<Promotion>();
    const router = useRouter();
    const route = useRoute();
    const dateD = ref();
    const dateF = ref();
    function getPromotion(id:number) {
      ApiService.get("/promotions/"+id.toString())
        .then(({ data }) => {
          for (const key in data.data) {
            
            promotionForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    } 
    
    function format_Date(date: string): string {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = (d.getMonth() + 1).toString().padStart(2, '0');
      const day = d.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    const dateDFormatted = ref('');
    const dateFFormatted = ref('');
    
    function getPromotionOnly(id: string) {
      return ApiService.get("/promotions/"+id)
        .then(({ data }) => {
          // dateD.value = format_Date(data.data.dateDebut);
          // dateF.value = format_Date(data.data.dateFin);
          dateDFormatted.value = format_Date(data.data.dateDebut);
          dateFFormatted.value = format_Date(data.data.dateFin);

            console.log('date',dateDFormatted.value)
            console.log('date',dateFFormatted.value) 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    const editPromotion = async (values, {resetForm}) => {
      ApiService.put("/promotions/"+values.id,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListePromotionPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    };

    onMounted(() => {
      if(route.params.id) {
        getPromotion(parseInt(route.params.id as string));
      }
      getPromotionOnly((route.params.id as string))
    });

    return { promotionSchema, editPromotion, promotionForm, 
      dateD, dateF,format_Date,
      dateDFormatted,dateFFormatted
    };
  },
});
</script>