<template>
    <BreadCrumb PageTitle="Modifier un produit" />
    <EditProduit />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import EditProduit from "../../../components/Ecommerce/Produit/EditProduit.vue";
  
    export default defineComponent({
      name: "EditProduitPage",
      components: {
        BreadCrumb,
        EditProduit,
      },
    });
  </script>