<template>
  <div
    class="modal fade createNewModal"
    id="AddCollectionModal"
    tabindex="-1"
    ref="addCollectionModalRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="collectionForm" @submit="addCollection" :validation-schema="collectionSchema">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Nom <span class="text-danger">*</span>
                  </label>
                  <Field name="name" type="text" v-model="name"
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nom"/>
                  <ErrorMessage name="name" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Slug <span class="text-danger">*</span>
                  </label>
                  <Field name="slug" type="text" v-model="slug" readonly="true"
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le slug"/>
                  <ErrorMessage name="slug" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12" v-if="btntext != 'Modifier'">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Produit (s)
                  </label>
                  <Field  name="produits" v-model="defaultSelectedProduits" v-slot="{ field }" >
                    <Multiselect
                      :options="produitsOptions"
                      :searchable="true"
                      track-by="label"
                      multiple="true"
                      mode="tags"
                      label="label"
                      v-bind = "field"
                      placeholder="Sélectionnez le produit"
                    />
                  </Field>  
                  <ErrorMessage name="produits" class="text-danger"/>
                  <span class="text-danger" v-if="produitsError">Le produit est obligatoire</span>
                </div>
              </div>
              
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
            {{ btntext }}
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import ApiService from '../../services/ApiService';
import * as Yup from 'yup';
import { hideModal, generateSlug } from '../../utils/utils';
import { Collection } from '../../models/Collection';
import { error , success } from '../../utils/utils';
import Multiselect from '@vueform/multiselect';
import axios from 'axios';
import JwtService from "../../services/JwtService";

export default defineComponent({
    name: "AddCollectionModal",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    item: {
      type: Number,
      default: 0,
    }
  },
  setup(props, { emit }){
    const isUPDATE = ref(false);
    const btntext = ref('Ajouter');

    const collectionSchema = Yup.object().shape({
      name: Yup.string().required('Le nom est obligatoire'),
      slug: Yup.string().required('Le slug est obligatoire'),
      produits: Yup.array().notRequired(),
    //   produits: Yup.array().when('$btntext', {
    //   is: (btntext) => btntext !== 'Modifier',
    //   then: (schema) => schema.required("Le produit est obligatoire"),
    //   otherwise: (schema) => schema.notRequired(),
    // }),
    });

    const collectionForm = ref<Collection | null>(null);
    const addCollectionModalRef = ref<null | HTMLElement>(null);
    const localItem = ref(props.item);
    const title = ref("Ajouter la collection");
    const produitsOptions = ref([]);
    const produitsError = ref(false); 
    const defaultSelectedProduits = ref<any[]>([]);
    const name = ref();
    const slug = ref();
    const userId = JwtService.getUser()!;

    watch(() => props.item, (newValue) => {
      getCollection(newValue);
      isUPDATE.value = true;
      btnTitle();
    });

    watch(defaultSelectedProduits, () => {
      console.log('name', defaultSelectedProduits.value)
      console.log('generateSlug', slug.value)
      if(defaultSelectedProduits.value.length > 0){
        produitsError.value = false
      }
      else {
        produitsError.value = true
      }
     console.log('after', slug.value)
    })

    watch(name, () => {
      console.log('name', name.value)
      console.log('generateSlug', slug.value)
      slug.value =  generateSlug(name.value)
     console.log('after', slug.value)
    })

    const btnTitle = async () => {
      if (isUPDATE.value) {
         title.value = "Modifier la collection";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter la collection";
         btntext.value = "Ajouter";
      }
    }

    const getCollection = async (id: number) => {
      return ApiService.get("/collections/"+id)
      .then(({ data }) => {
        // map data in form
        const donnees = data.data;
        console.log('rfgrgr', data)
        for (const key in donnees) {
          collectionForm.value?.setFieldValue(key, 
          (typeof donnees[key] === 'object' && donnees[key] !== null)? donnees[key].id :donnees[key]
          );
        }
        emit('openmodal', addCollectionModalRef.value);

      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const fetchCategorie = async () => {
      try {
        const response = await axios.get('/produits?limit=0',{headers: {
                'userId': userId
            }});
        const produitData = response.data.data.data;
        console.log(produitData);
        produitsOptions.value = produitData.map((produit) => ({
          value: produit.id,
          label: `${produit.title}`,
        }));
      } catch (error) {
        //
      }
    };

    const addCollection = async (values: any, collectionForm) => {
      console.log('values', values)
      values = values as Collection
      if(isUPDATE.value){
          values['slug']=slug.value
        ApiService.put("/collections/"+values.id,values)
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              // collectionForm.resetForm();
              hideModal(addCollectionModalRef.value);
              isUPDATE.value=false;
              btnTitle();
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      
      }else{
        if(defaultSelectedProduits.value.length > 0){
        values['slug']=slug.value
      console.log('values', values)
      console.log('values', defaultSelectedProduits.value)
        ApiService.post("/collections",values)
        .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              // collectionForm.resetForm();
              hideModal(addCollectionModalRef.value);
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }
        else{
          produitsError.value = true
      console.log('valueddds', defaultSelectedProduits.value)
          console.log('Le produit est obligatoire')
        }
      }
        
    };

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isUPDATE.value=false;
      formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    onMounted(() => {
      fetchCategorie();
    });

    return { collectionSchema,
      addCollectionModalRef,
      addCollection,
      collectionForm,
      title,btntext,resetValue,
      produitsOptions,generateSlug,
      name,slug,defaultSelectedProduits,
      produitsError,userId,
    };
  },
});
</script>