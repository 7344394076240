<template>
    <BreadCrumb PageTitle="Liste des communes" />
    <ListeCommune />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeCommune from "../../components/Commune/ListeCommune.vue";

  
    export default defineComponent({
      name: "ListeCommunePage",
      components: {
        BreadCrumb,
        ListeCommune,
      },
    });
  </script>