<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center"></div>
      <div class="d-flex align-items-center">
        <form
          class="search-box position-relative me-15"
          @submit.prevent="rechercher"
        >
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher un livreur"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
            class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
            type="button"
          >
            <i class="flaticon-dots"></i>
          </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table
          v-if="users.length > 0"
          class="table text-nowrap align-middle mb-0"
        >
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Nom et Prénom
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Téléphone
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Email
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Sexe
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Statut
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text pe-0"
              >
                Zone de couverture
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in users" :key="index">
              <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
              >
                {{ user.nomComplet }}
              </th>
              <td class="shadow-none lh-1 fw-medium">{{ user.telephone }}</td>
              <td class="shadow-none lh-1 fw-medium">{{ user.email }}</td>
              <td class="shadow-none lh-1 fw-medium">{{ user.sexe }}</td>
              <td class="shadow-none lh-1 fw-medium">
                <span class="badge text-outline-success" v-if="user.statut"
                  >Actif</span
                >
                <span class="badge text-outline-danger" v-if="!user.statut"
                  >Inactif</span
                >
              </td>
              <td class="shadow-none lh-1 fw-medium">
                <span v-for="commune in user.communes" :key="commune.id">
                  <p>
                    <span
                      class="badge badge-outline-danger text-outline-primary"
                    >
                      {{ commune.libelle }}
                      <a
                        href="javascript:void(0);"
                        class="tag-delete-link text-outline-danger"
                        @click="suppressionZone(commune.id, user.id)"
                      >
                        <i class="flaticon-delete icon-position"></i>
                      </a>
                    </span>
                  </p>
                </span>
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
                <div class="dropdown">
                  <span
                    class="badge text-white bg-primary fs-15 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Actions
                    <i
                      class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"
                    ></i>
                  </span>
                  <ul class="dropdown-menu">
                    <!-- <li v-if="checkPermission('EditPasswordUser')">
                          <a
                            class="dropdown-item d-flex align-items-center"
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#EditUserPassModal"
                            @click="openEditPassModal(user)"
                          >
                          <i class="flaticon-pen lh-1 me-8 position-relative top-1"></i>
                            Modifier le mot de passe
                          </a>
                        </li> -->
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#ZoneLivreurModal"
                        @click="openZoneLivreurModal(user)"
                      >
                        <i
                          class="flaticon-fast-delivery lh-1 me-8 position-relative top-1"
                        ></i>
                        <span>Complèter une zone pour un livreur</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent
          :page="page"
          :totalPages="totalPages"
          :totalElements="totalElements"
          :limit="limit"
          @paginate="handlePaginate"
        />
      </div>
    </div>
  </div>

  <ZoneLivreurModal
    :selectedLivreur="selectedLivreur"
    :selectedLivreurId="selectedLivreurId"
    @close="recharger"
  />
</template>
  
  <script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { User } from "@/models/users";
import ApiService from "@/services/ApiService";
import { format_date, showModal, suppression, error } from "../../utils/utils";
import PaginationComponent from "@/components/Utilities/Pagination.vue";
import JwtService from "@/services/JwtService";
import axios from "axios";
import Swal from "sweetalert2";
import ZoneLivreurModal from "./ZoneLivreurModal.vue";

export default defineComponent({
  name: "ListeUser",
  components: {
    PaginationComponent,
    ZoneLivreurModal,
  },
  setup: () => {
    onMounted(() => {
      getAllUsers();
    });

    const users = ref<Array<User>>([]);
    const user = ref<User>();
    const estMarchand = JwtService.getIfMarchand(false);
    const userId = JwtService.getUser()!;

    // BEGIN PAGINATE
    const searchTerm = ref("");
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllUsers(page_, limit_);
      } catch (error) {
        //
      }
    };

    function rechercher() {
      getAllUsers(page.value, limit.value, searchTerm.value);
    }

    const suppressionZone = (a: any, b: any) => {
      // console.log('dataSend', dataSend)
      axios
        .delete(`/zone/livreur/${a}/${b}`)
        .then(({ data }) => {
          Swal.fire({
            text: data.message,
            toast: true,
            icon: "success",
            title: "General Title",
            animation: false,
            position: "top-right",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            heightAuto: false,
          });
          getAllUsers();
        })
        .catch(({ response }) => {
          Swal.fire({
            text: response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Réssayer à nouveau!",
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };

    // END PAGINATE

    function getAllUsers(page = 1, limi = 10, searchTerm = "") {
      return axios
        .get(`/livreurs?page=${page}&limit=${limi}&mot=${searchTerm}&`)
        .then(({ data }) => {
          users.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
          return data.data;
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    }

    const recharger = () => {
      getAllUsers();
    };

    const selectedLivreur = ref<User | undefined>(undefined);
    const selectedLivreurId = ref<number>();

    const openZoneLivreurModal = (modele: User) => {
      selectedLivreur.value = { ...modele };
      selectedLivreurId.value = modele.id;
    };

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    };

    return {
      users,
      checkPermission,
      format_date,
      suppression,
      user,
      page,
      totalPages,
      limit,
      totalElements,
      handlePaginate,
      searchTerm,
      rechercher,
      userId,
      suppressionZone,
      selectedLivreur,
      selectedLivreurId,
      openZoneLivreurModal,
      recharger,
    };
  },
});
</script>